/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class FS01 extends WsResMsg {
	constructor() {
		super(1);
		this._notifications = new ArrayMessageBase(Notification);
		this._resultCode = new NumberMessageBase();
	}
	
	get notifications() {
		return this._notifications;
	}
	
	get resultCode() {
		return this._resultCode;
	}
}

export class Notification {
	constructor() {
		this._id = new StringMessageBase();
		this._title = new StringMessageBase();
		this._desc = new StringMessageBase();
		this._status = new StringMessageBase();
		this._parentId = new StringMessageBase();
	}
	
	get id() {
		return this._id;
	}
	
	get title() {
		return this._title;
	}
	
	get desc() {
		return this._desc;
	}
	
	get status() {
		return this._status;
	}
	
	get parentId() {
		return this._parentId;
	}
}

export default FS01;
