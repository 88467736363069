/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class XR02 extends WsReqMsg {
	constructor() {
		super(1);
		this._exchangeId = new NumberMessageBase();
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	set exchangeId(exchangeId) {
		this._exchangeId = new NumberMessageBase(exchangeId);
	}
}

export default XR02;
