/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class CS51 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._records = new ArrayMessageBase(Record);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get records() {
		return this._records;
	}
}

export class Record {
	constructor() {
		this._custRec = new StringMessageBase();
		this._custId = new StringMessageBase();
		this._name = new StringMessageBase();
		this._thai = new StringMessageBase();
		this._accTyp = new StringMessageBase();
		this._pc = new StringMessageBase();
		this._crdTyp = new StringMessageBase();
		this._status = new StringMessageBase();
		this._vip = new StringMessageBase();
		this._cnfrm = new StringMessageBase();
		this._custFlagFuture = new StringMessageBase();
		this._custFlagOption = new StringMessageBase();
		this._buyValue = new StringMessageBase();
		this._sellValue = new StringMessageBase();
		this._custGrpId = new StringMessageBase();
		this._mutalFlag = new StringMessageBase();
		this._comm = new StringMessageBase();
		this._netValue = new StringMessageBase();
		this._totalNet = new StringMessageBase();
		this._brkNetValue = new StringMessageBase();
		this._totalTrade = new StringMessageBase();
	}
	
	get custRec() {
		return this._custRec;
	}
	
	get custId() {
		return this._custId;
	}
	
	get name() {
		return this._name;
	}
	
	get thai() {
		return this._thai;
	}
	
	get accTyp() {
		return this._accTyp;
	}
	
	get pc() {
		return this._pc;
	}
	
	get crdTyp() {
		return this._crdTyp;
	}
	
	get status() {
		return this._status;
	}
	
	get vip() {
		return this._vip;
	}
	
	get cnfrm() {
		return this._cnfrm;
	}
	
	get custFlagFuture() {
		return this._custFlagFuture;
	}
	
	get custFlagOption() {
		return this._custFlagOption;
	}
	
	get buyValue() {
		return this._buyValue;
	}
	
	get sellValue() {
		return this._sellValue;
	}
	
	get custGrpId() {
		return this._custGrpId;
	}
	
	get mutalFlag() {
		return this._mutalFlag;
	}
	
	get comm() {
		return this._comm;
	}
	
	get netValue() {
		return this._netValue;
	}
	
	get totalNet() {
		return this._totalNet;
	}
	
	get brkNetValue() {
		return this._brkNetValue;
	}
	
	get totalTrade() {
		return this._totalTrade;
	}
}

export default CS51;
