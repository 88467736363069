/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class RB01 extends WsReqMsg {
	constructor() {
		super(2);
		this._securityList = new ArrayMessageBase(SecurityItem);
		this._favoriteId = new NumberMessageBase();
	}
	
	get securityList() {
		return this._securityList;
	}
	
	set securityList(securityList) {
		this._securityList = new ArrayMessageBase(securityList);
	}
	
	get favoriteId() {
		return this._favoriteId;
	}
	
	set favoriteId(favoriteId) {
		this._favoriteId = new NumberMessageBase(favoriteId);
	}
}

export class SecurityItem {
	constructor() {
		this._securityId = new NumberMessageBase();
	}
	
	get securityId() {
		return this._securityId;
	}
	
	set securityId(securityId) {
		this._securityId = new NumberMessageBase(securityId);
	}
}

export default RB01;
