/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class PR01 extends WsReqMsg {
	constructor() {
		super(1);
		this._lastTradeCount = new NumberMessageBase();
		this._filterListType = new StringMessageBase();
		this._filterListId = new StringMessageBase();
	}
	
	get lastTradeCount() {
		return this._lastTradeCount;
	}
	
	set lastTradeCount(lastTradeCount) {
		this._lastTradeCount = new NumberMessageBase(lastTradeCount);
	}
	
	get filterListType() {
		return this._filterListType;
	}
	
	set filterListType(filterListType) {
		this._filterListType = new StringMessageBase(filterListType);
	}
	
	get filterListId() {
		return this._filterListId;
	}
	
	set filterListId(filterListId) {
		this._filterListId = new StringMessageBase(filterListId);
	}
}

export default PR01;
