/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class AR05 extends WsReqMsg {
	constructor() {
		super(1);
		this._accountCode = new StringMessageBase();
		this._serviceType = new StringMessageBase();
		this._customerType = new StringMessageBase();
		this._commissionType = new StringMessageBase();
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get serviceType() {
		return this._serviceType;
	}
	
	set serviceType(serviceType) {
		this._serviceType = new StringMessageBase(serviceType);
	}
	
	get customerType() {
		return this._customerType;
	}
	
	set customerType(customerType) {
		this._customerType = new StringMessageBase(customerType);
	}
	
	get commissionType() {
		return this._commissionType;
	}
	
	set commissionType(commissionType) {
		this._commissionType = new StringMessageBase(commissionType);
	}
}

export default AR05;
