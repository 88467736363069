/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class PB04 extends WsMsg {
	constructor() {
		super(2);
		this._securityId = new NumberMessageBase();
		this._price = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._type = new StringMessageBase();
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get price() {
		return this._price;
	}
	
	get volume() {
		return this._volume;
	}
	
	get type() {
		return this._type;
	}
}

export default PB04;
