import axios from "axios";
import Swal from "sweetalert2";
import { EventBus } from "@/event-bus.js";

let API_OTP_URL = process.env.VUE_APP_AUTH_OTP_PATH;
let getConfiged = false;

if (localStorage.getItem("token")) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
}

const getConfigs = async () => {
  const API_CONFIG_URL = window.location.origin + "/config.json";
  const response = await axios.get(API_CONFIG_URL);
  const { CONFIG_AUTH_OTP_PATH } = response.data;
  let { CONFIG_EGW_IP } = response.data;

  if (!CONFIG_EGW_IP) {
    CONFIG_EGW_IP = window.location.host;
  }

  if (CONFIG_AUTH_OTP_PATH) {
    API_OTP_URL = window.location.protocol + "//" + CONFIG_EGW_IP + CONFIG_AUTH_OTP_PATH;
  } else {
    API_OTP_URL = window.location.protocol + "//" + CONFIG_EGW_IP + process.env.VUE_APP_AUTH_OTP_PATH;
  }
  getConfiged = true;
};

if (process.env.NODE_ENV === "production") {
  API_OTP_URL = window.location.origin + process.env.VUE_APP_AUTH_OTP_PATH;
  getConfigs();
}

const getSecret = () => {
  return axios
    .get(API_OTP_URL + "/totp")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const getQRCodeArrayBuffer = () => {
  return axios
    .get(API_OTP_URL + "/totp/qrcode", {
      responseType: "arraybuffer",
    })
    .then((response) => {
      const image = window.btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
      return `data:${response.headers["content-type"].toLowerCase()};base64,${image}`;
    })
    .catch((error) => {
      throw error;
    });
};

// deprecate not support without header authorization
const getQRCodePath = () => {
  return API_OTP_URL + "/totp/qrcode";
};

const updateTOTP = (arg) => {
  let data = new URLSearchParams();
  data.append("enable", arg.enable);
  data.append("code", arg.code);

  return axios
    .post(API_OTP_URL + "/totp", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const requestEmail = (arg) => {
  let data = new URLSearchParams();
  data.append("type", arg.type);
  if (arg.username) {
    data.append("username", arg.username);
  }
  if (arg.email) {
    data.append("email", arg.email);
  }

  return axios
    .post(API_OTP_URL + "/email/request", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const updateEmail = (arg) => {
  let data = new URLSearchParams();
  if (arg.enable != undefined) {
    data.append("enable", arg.enable);
  }
  if (arg.code) {
    data.append("code", arg.code);
  }
  if (arg.email) {
    data.append("email", arg.email);
  }
  if (arg.code_old) {
    data.append("code_old", arg.code_old);
  }

  return axios
    .post(API_OTP_URL + "/email", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const requestSMS = (username) => {
  const data = { source_type: "W", username: username };

  return axios
    .get(API_OTP_URL + "/sms/request", { params: data })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export default {
  getSecret,
  getQRCodeArrayBuffer,
  getQRCodePath,
  updateTOTP,
  requestEmail,
  updateEmail,
  requestSMS,
};
