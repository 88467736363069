/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class PB01 extends WsMsg {
	constructor() {
		super(2);
		this._securityId = new NumberMessageBase();
		this._time = new StringMessageBase();
		this._side = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._price = new StringMessageBase();
		this._changeFlag = new StringMessageBase();
		this._totalVolume = new StringMessageBase();
		this._totalValue = new StringMessageBase();
		this._sellVolume = new StringMessageBase();
		this._sellValue = new StringMessageBase();
		this._buyVolume = new StringMessageBase();
		this._buyValue = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._changePrice = new StringMessageBase();
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get time() {
		return this._time;
	}
	
	get side() {
		return this._side;
	}
	
	get volume() {
		return this._volume;
	}
	
	get price() {
		return this._price;
	}
	
	get changeFlag() {
		return this._changeFlag;
	}
	
	get totalVolume() {
		return this._totalVolume;
	}
	
	get totalValue() {
		return this._totalValue;
	}
	
	get sellVolume() {
		return this._sellVolume;
	}
	
	get sellValue() {
		return this._sellValue;
	}
	
	get buyVolume() {
		return this._buyVolume;
	}
	
	get buyValue() {
		return this._buyValue;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get changePrice() {
		return this._changePrice;
	}
}

export default PB01;
