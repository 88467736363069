/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class AS55 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._prevEquityBalance = new StringMessageBase();
		this._prevExcessEquityBalance = new StringMessageBase();
		this._prevPL = new StringMessageBase();
		this._prevMarginBalance = new StringMessageBase();
		this._prevForceFlag = new StringMessageBase();
		this._prevAmount = new StringMessageBase();
		this._eptEquityBalance = new StringMessageBase();
		this._eptExcessEquityBalance = new StringMessageBase();
		this._eptPL = new StringMessageBase();
		this._eptMarginBalance = new StringMessageBase();
		this._eptForceFlag = new StringMessageBase();
		this._eptAmount = new StringMessageBase();
		this._portEquityBalance = new StringMessageBase();
		this._portExcessEquityBalance = new StringMessageBase();
		this._portPL = new StringMessageBase();
		this._portMarginBalance = new StringMessageBase();
		this._portForceFlag = new StringMessageBase();
		this._portAmount = new StringMessageBase();
		this._portDepositWithdrawal = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get prevEquityBalance() {
		return this._prevEquityBalance;
	}
	
	get prevExcessEquityBalance() {
		return this._prevExcessEquityBalance;
	}
	
	get prevPL() {
		return this._prevPL;
	}
	
	get prevMarginBalance() {
		return this._prevMarginBalance;
	}
	
	get prevForceFlag() {
		return this._prevForceFlag;
	}
	
	get prevAmount() {
		return this._prevAmount;
	}
	
	get eptEquityBalance() {
		return this._eptEquityBalance;
	}
	
	get eptExcessEquityBalance() {
		return this._eptExcessEquityBalance;
	}
	
	get eptPL() {
		return this._eptPL;
	}
	
	get eptMarginBalance() {
		return this._eptMarginBalance;
	}
	
	get eptForceFlag() {
		return this._eptForceFlag;
	}
	
	get eptAmount() {
		return this._eptAmount;
	}
	
	get portEquityBalance() {
		return this._portEquityBalance;
	}
	
	get portExcessEquityBalance() {
		return this._portExcessEquityBalance;
	}
	
	get portPL() {
		return this._portPL;
	}
	
	get portMarginBalance() {
		return this._portMarginBalance;
	}
	
	get portForceFlag() {
		return this._portForceFlag;
	}
	
	get portAmount() {
		return this._portAmount;
	}
	
	get portDepositWithdrawal() {
		return this._portDepositWithdrawal;
	}
}

export default AS55;
