/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class OS08 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._orderNo = new StringMessageBase();
		this._specialOrderType = new StringMessageBase();
		this._advType1 = new StringMessageBase();
		this._advType2 = new StringMessageBase();
		this._advType3 = new StringMessageBase();
		this._condition1 = new StringMessageBase();
		this._condition2 = new StringMessageBase();
		this._condition3 = new StringMessageBase();
		this._operation = new StringMessageBase();
		this._advVal1 = new StringMessageBase();
		this._advVal2 = new StringMessageBase();
		this._advVal3 = new StringMessageBase();
		this._tickerSec1 = new StringMessageBase();
		this._tickerSec2 = new StringMessageBase();
		this._tickerSec3 = new StringMessageBase();
		this._refPrice1 = new StringMessageBase();
		this._refPrice2 = new StringMessageBase();
		this._refPrice3 = new StringMessageBase();
		this._advIntervalTime = new StringMessageBase();
		this._advStep = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get orderNo() {
		return this._orderNo;
	}
	
	get specialOrderType() {
		return this._specialOrderType;
	}
	
	get advType1() {
		return this._advType1;
	}
	
	get advType2() {
		return this._advType2;
	}
	
	get advType3() {
		return this._advType3;
	}
	
	get condition1() {
		return this._condition1;
	}
	
	get condition2() {
		return this._condition2;
	}
	
	get condition3() {
		return this._condition3;
	}
	
	get operation() {
		return this._operation;
	}
	
	get advVal1() {
		return this._advVal1;
	}
	
	get advVal2() {
		return this._advVal2;
	}
	
	get advVal3() {
		return this._advVal3;
	}
	
	get tickerSec1() {
		return this._tickerSec1;
	}
	
	get tickerSec2() {
		return this._tickerSec2;
	}
	
	get tickerSec3() {
		return this._tickerSec3;
	}
	
	get refPrice1() {
		return this._refPrice1;
	}
	
	get refPrice2() {
		return this._refPrice2;
	}
	
	get refPrice3() {
		return this._refPrice3;
	}
	
	get advIntervalTime() {
		return this._advIntervalTime;
	}
	
	get advStep() {
		return this._advStep;
	}
}

export default OS08;
