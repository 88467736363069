/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class SS08 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._histories = new ArrayMessageBase(History);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get histories() {
		return this._histories;
	}
}

export class History {
	constructor() {
		this._date = new StringMessageBase();
		this._prior = new StringMessageBase();
		this._open = new StringMessageBase();
		this._close = new StringMessageBase();
		this._change = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._value = new StringMessageBase();
		this._high = new StringMessageBase();
		this._low = new StringMessageBase();
		this._changeFlag = new StringMessageBase();
	}
	
	get date() {
		return this._date;
	}
	
	get prior() {
		return this._prior;
	}
	
	get open() {
		return this._open;
	}
	
	get close() {
		return this._close;
	}
	
	get change() {
		return this._change;
	}
	
	get volume() {
		return this._volume;
	}
	
	get value() {
		return this._value;
	}
	
	get high() {
		return this._high;
	}
	
	get low() {
		return this._low;
	}
	
	get changeFlag() {
		return this._changeFlag;
	}
}

export default SS08;
