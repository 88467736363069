/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class OS07 extends WsResMsg {
	constructor() {
		super(0);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._orderId = new StringMessageBase();
		this._sendDate = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get orderId() {
		return this._orderId;
	}
	
	get sendDate() {
		return this._sendDate;
	}
}

export default OS07;
