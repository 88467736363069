/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class PS01 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._trades = new ArrayMessageBase(Trade);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get trades() {
		return this._trades;
	}
}

export class Trade {
	constructor() {
		this._securityId = new NumberMessageBase();
		this._time = new StringMessageBase();
		this._side = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._price = new StringMessageBase();
		this._changeFlag = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._changePrice = new StringMessageBase();
		this._high = new StringMessageBase();
		this._low = new StringMessageBase();
		this._average = new StringMessageBase();
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get time() {
		return this._time;
	}
	
	get side() {
		return this._side;
	}
	
	get volume() {
		return this._volume;
	}
	
	get price() {
		return this._price;
	}
	
	get changeFlag() {
		return this._changeFlag;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get changePrice() {
		return this._changePrice;
	}
	
	get high() {
		return this._high;
	}
	
	get low() {
		return this._low;
	}
	
	get average() {
		return this._average;
	}
}

export default PS01;
