/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class OR54 extends WsReqMsg {
	constructor() {
		super(1);
		this._accountCode = new StringMessageBase();
		this._securityId = new NumberMessageBase();
		this._recordCount = new NumberMessageBase();
		this._lastRecordId = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._orderId = new StringMessageBase();
		this._side = new StringMessageBase();
		this._status = new StringMessageBase();
		this._lastDbRecordId = new StringMessageBase();
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get securityId() {
		return this._securityId;
	}
	
	set securityId(securityId) {
		this._securityId = new NumberMessageBase(securityId);
	}
	
	get recordCount() {
		return this._recordCount;
	}
	
	set recordCount(recordCount) {
		this._recordCount = new NumberMessageBase(recordCount);
	}
	
	get lastRecordId() {
		return this._lastRecordId;
	}
	
	set lastRecordId(lastRecordId) {
		this._lastRecordId = new StringMessageBase(lastRecordId);
	}
	
	get symbol() {
		return this._symbol;
	}
	
	set symbol(symbol) {
		this._symbol = new StringMessageBase(symbol);
	}
	
	get orderId() {
		return this._orderId;
	}
	
	set orderId(orderId) {
		this._orderId = new StringMessageBase(orderId);
	}
	
	get side() {
		return this._side;
	}
	
	set side(side) {
		this._side = new StringMessageBase(side);
	}
	
	get status() {
		return this._status;
	}
	
	set status(status) {
		this._status = new StringMessageBase(status);
	}
	
	get lastDbRecordId() {
		return this._lastDbRecordId;
	}
	
	set lastDbRecordId(lastDbRecordId) {
		this._lastDbRecordId = new StringMessageBase(lastDbRecordId);
	}
}

export default OR54;
