/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class SS01 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._securityId = new NumberMessageBase();
		this._symbol = new StringMessageBase();
		this._boardLot = new StringMessageBase();
		this._spread = new StringMessageBase();
		this._securityType = new StringMessageBase();
		this._haltFlag = new StringMessageBase();
		this._haltTypeFlag = new StringMessageBase();
		this._suspendFlag = new StringMessageBase();
		this._delistFlag = new StringMessageBase();
		this._securityFlag = new StringMessageBase();
		this._benefitFlag = new StringMessageBase();
		this._turnoverListByBrkFlag = new StringMessageBase();
		this._turnoverListFlag = new StringMessageBase();
		this._desgntFlag = new StringMessageBase();
		this._stabilizedFlag = new StringMessageBase();
		this._exShortTwFlag = new StringMessageBase();
		this._exWarrantsFlag = new StringMessageBase();
		this._exTransRightFlag = new StringMessageBase();
		this._exCapRetFlag = new StringMessageBase();
		this._noticeFlag = new StringMessageBase();
		this._newsFlag = new StringMessageBase();
		this._splitFlag = new StringMessageBase();
		this._otherFlag = new StringMessageBase();
		this._flag = new StringMessageBase();
		this._prior = new StringMessageBase();
		this._ceiling = new StringMessageBase();
		this._floor = new StringMessageBase();
		this._open1 = new StringMessageBase();
		this._open2 = new StringMessageBase();
		this._high = new StringMessageBase();
		this._low = new StringMessageBase();
		this._last = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._value = new StringMessageBase();
		this._average = new StringMessageBase();
		this._projectedPrice = new StringMessageBase();
		this._projectedVolume = new StringMessageBase();
		this._projectedType = new StringMessageBase();
		this._change = new StringMessageBase();
		this._name = new StringMessageBase();
		this._averageBuy = new StringMessageBase();
		this._averageSell = new StringMessageBase();
		this._buyVolume = new StringMessageBase();
		this._sellVolume = new StringMessageBase();
		this._mktStCode = new StringMessageBase();
		this._sectorBuyVol = new StringMessageBase();
		this._sectorSellVol = new StringMessageBase();
		this._sectorVol = new StringMessageBase();
		this._marketBuyVol = new StringMessageBase();
		this._marketSellVol = new StringMessageBase();
		this._marketVol = new StringMessageBase();
		this._marketVal = new StringMessageBase();
		this._bid1 = new StringMessageBase();
		this._bid2 = new StringMessageBase();
		this._bid3 = new StringMessageBase();
		this._bid4 = new StringMessageBase();
		this._bid5 = new StringMessageBase();
		this._bid6 = new StringMessageBase();
		this._bid7 = new StringMessageBase();
		this._bid8 = new StringMessageBase();
		this._bid9 = new StringMessageBase();
		this._bid10 = new StringMessageBase();
		this._offer1 = new StringMessageBase();
		this._offer2 = new StringMessageBase();
		this._offer3 = new StringMessageBase();
		this._offer4 = new StringMessageBase();
		this._offer5 = new StringMessageBase();
		this._offer6 = new StringMessageBase();
		this._offer7 = new StringMessageBase();
		this._offer8 = new StringMessageBase();
		this._offer9 = new StringMessageBase();
		this._offer10 = new StringMessageBase();
		this._bidVol1 = new StringMessageBase();
		this._bidVol2 = new StringMessageBase();
		this._bidVol3 = new StringMessageBase();
		this._bidVol4 = new StringMessageBase();
		this._bidVol5 = new StringMessageBase();
		this._bidVol6 = new StringMessageBase();
		this._bidVol7 = new StringMessageBase();
		this._bidVol8 = new StringMessageBase();
		this._bidVol9 = new StringMessageBase();
		this._bidVol10 = new StringMessageBase();
		this._offerVol1 = new StringMessageBase();
		this._offerVol2 = new StringMessageBase();
		this._offerVol3 = new StringMessageBase();
		this._offerVol4 = new StringMessageBase();
		this._offerVol5 = new StringMessageBase();
		this._offerVol6 = new StringMessageBase();
		this._offerVol7 = new StringMessageBase();
		this._offerVol8 = new StringMessageBase();
		this._offerVol9 = new StringMessageBase();
		this._offerVol10 = new StringMessageBase();
		this._trades = new ArrayMessageBase(Trade);
		this._exchangeId = new NumberMessageBase();
		this._marketId = new NumberMessageBase();
		this._cMktStat = new StringMessageBase();
		this._sectorNo = new NumberMessageBase();
		this._par = new NumberMessageBase();
		this._premium = new StringMessageBase();
		this._grade = new StringMessageBase();
		this._margin = new NumberMessageBase();
		this._tsfcMrgRate = new NumberMessageBase();
		this._changePercent = new StringMessageBase();
		this._biglotDeal = new StringMessageBase();
		this._biglotVolume = new StringMessageBase();
		this._biglotValue = new StringMessageBase();
		this._oddlotVolume = new StringMessageBase();
		this._oddlotValue = new StringMessageBase();
		this._sdcFlag = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get boardLot() {
		return this._boardLot;
	}
	
	get spread() {
		return this._spread;
	}
	
	get securityType() {
		return this._securityType;
	}
	
	get haltFlag() {
		return this._haltFlag;
	}
	
	get haltTypeFlag() {
		return this._haltTypeFlag;
	}
	
	get suspendFlag() {
		return this._suspendFlag;
	}
	
	get delistFlag() {
		return this._delistFlag;
	}
	
	get securityFlag() {
		return this._securityFlag;
	}
	
	get benefitFlag() {
		return this._benefitFlag;
	}
	
	get turnoverListByBrkFlag() {
		return this._turnoverListByBrkFlag;
	}
	
	get turnoverListFlag() {
		return this._turnoverListFlag;
	}
	
	get desgntFlag() {
		return this._desgntFlag;
	}
	
	get stabilizedFlag() {
		return this._stabilizedFlag;
	}
	
	get exShortTwFlag() {
		return this._exShortTwFlag;
	}
	
	get exWarrantsFlag() {
		return this._exWarrantsFlag;
	}
	
	get exTransRightFlag() {
		return this._exTransRightFlag;
	}
	
	get exCapRetFlag() {
		return this._exCapRetFlag;
	}
	
	get noticeFlag() {
		return this._noticeFlag;
	}
	
	get newsFlag() {
		return this._newsFlag;
	}
	
	get splitFlag() {
		return this._splitFlag;
	}
	
	get otherFlag() {
		return this._otherFlag;
	}
	
	get flag() {
		return this._flag;
	}
	
	get prior() {
		return this._prior;
	}
	
	get ceiling() {
		return this._ceiling;
	}
	
	get floor() {
		return this._floor;
	}
	
	get open1() {
		return this._open1;
	}
	
	get open2() {
		return this._open2;
	}
	
	get high() {
		return this._high;
	}
	
	get low() {
		return this._low;
	}
	
	get last() {
		return this._last;
	}
	
	get volume() {
		return this._volume;
	}
	
	get value() {
		return this._value;
	}
	
	get average() {
		return this._average;
	}
	
	get projectedPrice() {
		return this._projectedPrice;
	}
	
	get projectedVolume() {
		return this._projectedVolume;
	}
	
	get projectedType() {
		return this._projectedType;
	}
	
	get change() {
		return this._change;
	}
	
	get name() {
		return this._name;
	}
	
	get averageBuy() {
		return this._averageBuy;
	}
	
	get averageSell() {
		return this._averageSell;
	}
	
	get buyVolume() {
		return this._buyVolume;
	}
	
	get sellVolume() {
		return this._sellVolume;
	}
	
	get mktStCode() {
		return this._mktStCode;
	}
	
	get sectorBuyVol() {
		return this._sectorBuyVol;
	}
	
	get sectorSellVol() {
		return this._sectorSellVol;
	}
	
	get sectorVol() {
		return this._sectorVol;
	}
	
	get marketBuyVol() {
		return this._marketBuyVol;
	}
	
	get marketSellVol() {
		return this._marketSellVol;
	}
	
	get marketVol() {
		return this._marketVol;
	}
	
	get marketVal() {
		return this._marketVal;
	}
	
	get bid1() {
		return this._bid1;
	}
	
	get bid2() {
		return this._bid2;
	}
	
	get bid3() {
		return this._bid3;
	}
	
	get bid4() {
		return this._bid4;
	}
	
	get bid5() {
		return this._bid5;
	}
	
	get bid6() {
		return this._bid6;
	}
	
	get bid7() {
		return this._bid7;
	}
	
	get bid8() {
		return this._bid8;
	}
	
	get bid9() {
		return this._bid9;
	}
	
	get bid10() {
		return this._bid10;
	}
	
	get offer1() {
		return this._offer1;
	}
	
	get offer2() {
		return this._offer2;
	}
	
	get offer3() {
		return this._offer3;
	}
	
	get offer4() {
		return this._offer4;
	}
	
	get offer5() {
		return this._offer5;
	}
	
	get offer6() {
		return this._offer6;
	}
	
	get offer7() {
		return this._offer7;
	}
	
	get offer8() {
		return this._offer8;
	}
	
	get offer9() {
		return this._offer9;
	}
	
	get offer10() {
		return this._offer10;
	}
	
	get bidVol1() {
		return this._bidVol1;
	}
	
	get bidVol2() {
		return this._bidVol2;
	}
	
	get bidVol3() {
		return this._bidVol3;
	}
	
	get bidVol4() {
		return this._bidVol4;
	}
	
	get bidVol5() {
		return this._bidVol5;
	}
	
	get bidVol6() {
		return this._bidVol6;
	}
	
	get bidVol7() {
		return this._bidVol7;
	}
	
	get bidVol8() {
		return this._bidVol8;
	}
	
	get bidVol9() {
		return this._bidVol9;
	}
	
	get bidVol10() {
		return this._bidVol10;
	}
	
	get offerVol1() {
		return this._offerVol1;
	}
	
	get offerVol2() {
		return this._offerVol2;
	}
	
	get offerVol3() {
		return this._offerVol3;
	}
	
	get offerVol4() {
		return this._offerVol4;
	}
	
	get offerVol5() {
		return this._offerVol5;
	}
	
	get offerVol6() {
		return this._offerVol6;
	}
	
	get offerVol7() {
		return this._offerVol7;
	}
	
	get offerVol8() {
		return this._offerVol8;
	}
	
	get offerVol9() {
		return this._offerVol9;
	}
	
	get offerVol10() {
		return this._offerVol10;
	}
	
	get trades() {
		return this._trades;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	get marketId() {
		return this._marketId;
	}
	
	get cMktStat() {
		return this._cMktStat;
	}
	
	get sectorNo() {
		return this._sectorNo;
	}
	
	get par() {
		return this._par;
	}
	
	get premium() {
		return this._premium;
	}
	
	get grade() {
		return this._grade;
	}
	
	get margin() {
		return this._margin;
	}
	
	get tsfcMrgRate() {
		return this._tsfcMrgRate;
	}
	
	get changePercent() {
		return this._changePercent;
	}
	
	get biglotDeal() {
		return this._biglotDeal;
	}
	
	get biglotVolume() {
		return this._biglotVolume;
	}
	
	get biglotValue() {
		return this._biglotValue;
	}
	
	get oddlotVolume() {
		return this._oddlotVolume;
	}
	
	get oddlotValue() {
		return this._oddlotValue;
	}
	
	get sdcFlag() {
		return this._sdcFlag;
	}
}

export class Trade {
	constructor() {
		this._time = new StringMessageBase();
		this._side = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._price = new StringMessageBase();
		this._changeFlag = new StringMessageBase();
	}
	
	get time() {
		return this._time;
	}
	
	get side() {
		return this._side;
	}
	
	get volume() {
		return this._volume;
	}
	
	get price() {
		return this._price;
	}
	
	get changeFlag() {
		return this._changeFlag;
	}
}

export default SS01;
