/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class UR01 extends WsReqMsg {
	constructor() {
		super(1);
		this._favoriteId = new StringMessageBase();
		this._name = new StringMessageBase();
		this._symbols = new StringMessageBase();
		this._updateType = new StringMessageBase();
	}
	
	get favoriteId() {
		return this._favoriteId;
	}
	
	set favoriteId(favoriteId) {
		this._favoriteId = new StringMessageBase(favoriteId);
	}
	
	get name() {
		return this._name;
	}
	
	set name(name) {
		this._name = new StringMessageBase(name);
	}
	
	get symbols() {
		return this._symbols;
	}
	
	set symbols(symbols) {
		this._symbols = new StringMessageBase(symbols);
	}
	
	get updateType() {
		return this._updateType;
	}
	
	set updateType(updateType) {
		this._updateType = new StringMessageBase(updateType);
	}
}

export default UR01;
