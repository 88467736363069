/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class NB01 extends WsMsg {
	constructor() {
		super(2);
		this._newsId = new NumberMessageBase();
		this._datetime = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._headline = new StringMessageBase();
		this._reportFileName = new StringMessageBase();
		this._reportFileType = new StringMessageBase();
	}
	
	get newsId() {
		return this._newsId;
	}
	
	get datetime() {
		return this._datetime;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get headline() {
		return this._headline;
	}
	
	get reportFileName() {
		return this._reportFileName;
	}
	
	get reportFileType() {
		return this._reportFileType;
	}
}

export default NB01;
