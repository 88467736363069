/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class MR01 extends WsReqMsg {
	constructor() {
		super(1);
		this._marketId = new StringMessageBase();
	}
	
	get marketId() {
		return this._marketId;
	}
	
	set marketId(marketId) {
		this._marketId = new StringMessageBase(marketId);
	}
}

export default MR01;
