<template>
  <div class="ht-main-layout">
    <div
      v-if="
        this.socketStatus &&
        this.ctordState === 'OPEN' &&
        this.ctinfState === 'OPEN' &&
        this.bcstState === 'OPEN' &&
        this.ntfState === 'OPEN' &&
        ready
      "
      class="ui grid main-layout-container"
    >
      <div class="row header-section">
        <div class="sixteen wide column nav-bar-container">
          <Navbar />
        </div>
        <div class="sixteen wide column index-bar-container">
          <IndexBar />
        </div>
      </div>

      <div class="row">
        <div class="sixteen wide column content-section">
          <router-view />
        </div>
      </div>

      <div class="two column row order-entry-section">
        <div
          :class="{
            'extend-mode': modeOrder,
            'sixteen wide computer twelve wide large screen column twelve wide widescreen monitor column': !modeOrder,
          }"
          class="order-entry-container"
        >
          <OrderEntry />
        </div>
        <div class="four wide column large screen only" v-if="!modeOrder">
          <MarketMessage />
        </div>
      </div>

      <div class="row status-bar-section">
        <div class="status-bar-container">
          <StatusBar />
        </div>
      </div>
      <DropdownItems />
    </div>
    <div v-else>
      <sui-dimmer active>
        <sui-loader inverted>Loading...</sui-loader>
      </sui-dimmer>
    </div>
  </div>
</template>

<script>
import _api from "@/api/authentication";

import { get, sync } from "vuex-pathify";

import Navbar from "./Navbar";
import IndexBar from "./IndexBar";
import OrderEntry from "./OrderEntry";
import StatusBar from "./StatusBar";
import MarketMessage from "./MarketMessage";
import { convertKeyCodeToChar } from "@/utils/UIUtils.js";
import { getImageName } from "@/utils/SymbolUtils.js";
import { isEnableETS, isEnableDTS, isEnableHitradeX } from "@/utils/LicenseUtils.js";
import { DEFAULT_SHORTKEYS } from "@/shortkeys";
import DropdownItems from "@/components/DropdownItems";

export default {
  name: "Layout",
  components: {
    Navbar,
    IndexBar,
    OrderEntry,
    MarketMessage,
    StatusBar,
    DropdownItems,
  },
  data: () => ({
    stackReady: [false],
    ready: false,
    modeOrderStyles: "height:100%",
    traderInfoRefId: null,
  }),
  created() {
    this.initShortcuts();

    this.$EventBus.$on("ctinf/XS01", this.onDataRefCinf);
    this.$EventBus.$on("ntf/XN01", this.onETSDataRefUpdateNtf);
    this.$EventBus.$on("ntf/XN51", this.onDTSDataRefUpdateNtf);

    this.checkAuth();
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/XS01", this.onDataRefCinf);
    this.$EventBus.$off("ntf/XN01", this.onETSDataRefUpdateNtf);
    this.$EventBus.$off("ntf/XN51", this.onDTSDataRefUpdateNtf);

    this.$ws.stop();
  },

  computed: {
    theme: sync("global/theme"),
    modeOrder: sync("global/modeOrder"),
    shortcuts: sync("model/shortcuts"),
    currentShortcut: sync("model/currentShortcut"),
    isEditingShortcut: sync("model/isEditingShortcut"),
    traderType: sync("global/traderType"),
    socketStatus: get("websocket/socketStatus"),
    ctordState: get("websocket/ctordState"),
    ctinfState: get("websocket/ctinfState"),
    bcstState: get("websocket/bcstState"),
    ntfState: get("websocket/ntfState"),
  },
  watch: {
    stackReady(value) {
      this.ready = value.every((ready) => ready === true);
    },
    modeOrder(value) {
      if (value) {
        return "";
      } else {
        this.modeOrderStyles = "height:100%";
      }
    },
    ctordState(state) {
      if (state === "CLOSED") {
        this.logout();
      }
    },
    ctinfState(state) {
      if (state === "OPEN") {
        this.XR01();
      } else if (state === "CLOSED") {
        this.logout();
      }
    },
    bcstState(state) {
      if (state === "CLOSED") {
        this.logout();
      }
    },
    ntfState(state) {
      if (state === "CLOSED") {
        this.logout();
      }
    },
    currentShortcut(value) {
      window.removeEventListener("keydown", this.onKeyDown);
      window.addEventListener("keydown", this.onKeyDown);
    },
    isEditingShortcut(value) {
      if (value) {
        window.removeEventListener("keydown", this.onKeyDown);
      } else {
        window.addEventListener("keydown", this.onKeyDown);
      }
    },
  },
  methods: {
    checkAuth() {
      _api.checkAuth().then((response) => {
        if (response?.status === 200) {
          this.$ws.start();
        }
      });
    },
    onKeyDown(e) {
      const found = this.$_.find(this.currentShortcut, (k) => {
        return e.ctrlKey === k.ctrl && e.shiftKey === k.shift && e.altKey === k.alt && e.keyCode === k.keyCode;
      });
      if (found) {
        this.$EventBus.$emit("receiveShortcut", found.funcName);
        e.preventDefault();
      }
    },
    logout() {
      if (this.ctordState === "CLOSED" && this.ctinfState === "CLOSED" && this.bcstState === "CLOSED" && this.ntfState === "CLOSED") {
        this.$ws.stop();
        this.$store.set("model/systemRefData", null);
        localStorage.removeItem("token");

        this.$Swal.fire("Error", "Your session has been terminated. Please login again.", "error").then(() => {
          this.forceLogout();
        });
      }
    },
    forceLogout() {
      this.$ws.stop();
      _api
        .logout()
        .then((response) => {
          this.$router.replace("/login");
        })
        .catch((error) => error)
        .finally(() => {
          this.$store.set("model/systemRefData", null);
          localStorage.removeItem("token");
        });
    },
    XR01() {
      const msg = this.$messageFactory.createMessage("XR01");
      this.$ws.send(msg);
    },
    onDataRefCinf(data) {
      if (data.resultCode === 1001) {
        const isETS = isEnableETS(data.licenseFlags);
        const isDTS = isEnableDTS(data.licenseFlags);
        const isHitradeX = isEnableHitradeX(data.licenseFlags);

        if (!isHitradeX) {
          this.$store.set("model/systemRefData", null);
          localStorage.removeItem("token");
          this.$Swal.fire(`Error`, `No license to using HitradeX`, "error").then(() => {
            this.forceLogout();
          });
        } else {
          this.traderType = null;
          if (data.userType === "I" && data.accounts.length > 0) {
            // set first account for investor
            const firstAccount = data.accounts[0];
            this.$store.set("global/currentAccount", firstAccount.exchangeId + "_" + firstAccount.accountCode);
            this.$store.set("global/currentSelectedAccount", {
              exchangeId: firstAccount.exchangeId,
              accountCode: firstAccount.accountCode,
            });
          } else if (data.userType === "T" && data.traders.length > 0) {
            // set first account for investor
            const firstTrader = data.traders[0];
            if (firstTrader.exchangeId == 1) {
              this.traderType = firstTrader.type;
            }
          }
          if (!isETS && !isDTS) {
            this.$Swal.fire(`Error`, `The server does not has any license to using HitradeX`, "error").then(() => {
              this.forceLogout();
            });
          } else {
            const etsSecurities = {};
            let newSecurities = [];
            data.securities.forEach((s) => {
              let newSymbol = s;
              newSymbol.imageName = getImageName(data.securities, newSymbol.symbol, newSymbol.exchangeId);
              newSecurities.push(newSymbol);

              etsSecurities[newSymbol.symbol] = newSymbol;
            });
            data.securities = newSecurities;

            const dtsSecurities = {};
            newSecurities = [];
            data.dtsSecurities.forEach((s) => {
              let newSymbol = s;
              newSymbol.imageName = getImageName(data.securities, newSymbol.symbol, newSymbol.exchangeId);
              newSecurities.push(newSymbol);

              dtsSecurities[newSymbol.symbol] = newSymbol;
            });
            data.dtsSecurities = newSecurities;

            this.$store.set("global/modeOrder", false);
            this.$store.set("model/systemRefData", data);
            this.$store.set("global/etsSecurities", etsSecurities);
            this.$store.set("global/dtsSecurities", dtsSecurities);

            this.$set(this.stackReady, 0, true);
          }
        }
      } else {
        console.error(data.resultCode);
        this.logout();
      }
    },
    onETSDataRefUpdateNtf(data) {
      this.$store.set("model/systemRefData@bussinessDate", data.bussinessDate);
      this.$store.set("model/systemRefData@nightMode", data.isNightMode);
    },
    onDTSDataRefUpdateNtf(data) {
      this.$store.set("model/systemRefData@dtsBussinessDate", data.bussinessDate);
      this.$store.set("model/systemRefData@dtsNightMode", data.isNightMode);
    },
    mergeShortkey(oldData, index) {
      const newData = Object.assign([], DEFAULT_SHORTKEYS[index]);
      DEFAULT_SHORTKEYS[index].forEach((n, nIndex) => {
        oldData[index].forEach((o) => {
          if (o.funcName === n.funcName) {
            newData[nIndex] = o;
            return;
          }
        });
      });
      return newData;
    },
    initShortcuts() {
      let oldData = localStorage.getItem("keyboardShortcuts");
      if (oldData) {
        oldData = JSON.parse(oldData);
        if (oldData[0].length !== DEFAULT_SHORTKEYS[0].length) {
          oldData[0] = this.mergeShortkey(oldData, 0);
        }
        if (oldData[1].length !== DEFAULT_SHORTKEYS[1].length) {
          oldData[1] = this.mergeShortkey(oldData, 1);
        }
        if (oldData[2].length !== DEFAULT_SHORTKEYS[2].length) {
          oldData[2] = this.mergeShortkey(oldData, 2);
        }
        this.shortcuts = oldData;
      } else {
        this.shortcuts = DEFAULT_SHORTKEYS;
        localStorage.setItem("keyboardShortcuts", JSON.stringify(this.shortcuts));
      }

      oldData = localStorage.getItem("keyboardShortcutIndex");
      let index = 0;
      if (oldData) {
        index = parseInt(oldData);
      }

      this.currentShortcut = [...this.shortcuts[index]];
    },
  },
};
</script>

<style lang="scss" scoped>
.ht-main-layout {
  background-color: black !important;
  height: 100%;

  .main-layout-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    height: 100%;
    flex-wrap: nowrap;
    position: relative;

    .header-section {
      height: 6rem;

      .nav-bar-container {
        height: 3rem;
      }

      .index-bar-container {
        height: 3rem;
      }
    }

    .content-section {
      height: 55vh;
      overflow: hidden;
      flex-direction: column;
      display: flex;
    }

    .status-bar-section {
      height: 3rem;

      .status-bar-container {
        height: 100%;
      }
    }
  }
}

.ht-border {
  border-style: solid;
  border-color: black;
  background-color: rgb(38, 45, 51) !important;
}

.ui.active.dimmer {
  background-color: black;
}

.order-entry-section {
  flex: 1;
  display: flex;
  column-gap: 5px;
  flex-wrap: nowrap !important;
  position: static !important;
  min-height: 0;

  .order-entry-container {
    // height: 100%;
    display: flex;
    flex-direction: column;

    &.extend-mode {
      position: absolute;
      top: calc(6rem + 5px) !important;
      height: calc(100vh - 6rem - 3rem - 10px) !important;
      z-index: 2;
      border: 0;
      width: 100% !important;
    }
  }
}
</style>
