<template>
  <div class="ui equal width form" style="padding: 0rem">
    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">
          {{ securityInfoLatest.name || "-" }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Volume</h5>
      </div>
      <div class="field">
        <h5 ref="securityVolume" class="ui yellow header">
          {{ $numeral(securityInfoLatest.volume).format("0,0") }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Value</h5>
      </div>
      <div class="field">
        <h5 ref="securityValue" class="ui yellow header">
          {{ $numeral(securityInfoLatest.value).format("0,0") }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">High</h5>
      </div>
      <div class="field">
        <h5 ref="securityHigh" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.high)]">
          {{ $numeral(securityInfoLatest.high).format("0,0.00") }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Low</h5>
      </div>
      <div class="field">
        <h5 ref="securityLow" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.low)]">
          {{ $numeral(securityInfoLatest.low).format("0,0.00") }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Average</h5>
      </div>
      <div class="field">
        <h5 ref="securityAvg" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.average)]">
          {{ $numeral(securityInfoLatest.average).format("0,0.00000") }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Close</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ $numeral(securityInfoLatest.prior).format("0,0.00") }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Ceilling</h5>
      </div>
      <div class="field">
        <h5 class="ui blue header">
          {{ $numeral(securityInfoLatest.ceiling).format("0,0.00") }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Floor</h5>
      </div>
      <div class="field">
        <h5 class="ui pink header">
          {{ $numeral(securityInfoLatest.floor).format("0,0.00") }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Open 1</h5>
      </div>
      <div class="field">
        <h5 class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.open1)]">
          {{ $numeral(securityInfoLatest.open1).format("0,0.00") }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Open 2</h5>
      </div>
      <div class="field">
        <h5 class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.open2)]">
          {{ $numeral(securityInfoLatest.open2).format("0,0.00") }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Buy Vol</h5>
      </div>
      <div class="field">
        <h5 ref="securityBuyVolume" class="ui yellow header">
          {{ $numeral(securityInfoLatest.buyVolume).format("0,0") }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Sell Vol</h5>
      </div>
      <div class="field">
        <h5 ref="securitySellVolume" class="ui yellow header">
          {{ $numeral(securityInfoLatest.sellVolume).format("0,0") }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Buy Avg</h5>
      </div>
      <div class="field">
        <h5 ref="securityAverageBuy" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.averageBuy)]">
          {{ $numeral(securityInfoLatest.averageBuy).format("0,0.00") }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Sell Avg</h5>
      </div>
      <div class="field">
        <h5 ref="securityAverageSell" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.averageSell)]">
          {{ $numeral(securityInfoLatest.averageSell).format("0,0.00") }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Lot Size</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ $numeral(securityInfoLatest.boardLot).format("0,0") }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Spread</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ $numeral(securityInfoLatest.spread).format("0,0.00") }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Flag</h5>
      </div>
      <div class="field">
        <h5 class="ui blue header">{{ securityInfoLatest.flag || "-" }}</h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Market Status</h5>
      </div>
      <div class="field">
        <h5 class="ui blue header">{{ securityInfoLatest.mktStCode || "-" }}</h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Security Type</h5>
      </div>
      <div class="field">
        <h5 class="ui blue header">{{ getSecurityTypeByKey(securityInfoLatest.securityType) || "-" }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import { getSecurityTypeByKey } from "@/utils/SecurityTypeFormatter.js";
import { getMarketStatusByKey } from "@/utils/MarketStatusFormatter.js";

export default {
  name: "QuoteETSInfo",
  props: ["currentQuoteSelectedSymbol"],
  data: () => ({
    securityInfoLatest: {},
  }),
  watch: {
    currentQuoteSelectedSymbol(currentQuoteSelectedSymbol) {
      if (currentQuoteSelectedSymbol === null) {
        this.securityInfoLatest = {};
        return;
      }

      this.SR01(currentQuoteSelectedSymbol.value);
    },
  },
  created() {
    if (this.currentQuoteSelectedSymbol) {
      this.SR01(this.currentQuoteSelectedSymbol.value);
    } else {
      this.$EventBus.$emit("onQuoteInfoStopRefresh");
    }

    this.$EventBus.$on("ctinf/SS01", this.securityInfoUpdateCinf);
    this.$EventBus.$on("bcst/PB01", this.updateSecurityInfo);
    this.$EventBus.$on("bcst/MB03", this.onRecieveMarketStatusUpdate);
    this.$EventBus.$on("onQuoteInfoStartRefresh", this.onQuoteInfoStartRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS01", this.securityInfoUpdateCinf);
    this.$EventBus.$off("bcst/PB01", this.updateSecurityInfo);
    this.$EventBus.$off("bcst/MB03", this.onRecieveMarketStatusUpdate);
    this.$EventBus.$off("onQuoteInfoStartRefresh", this.onQuoteInfoStartRefresh);
  },
  methods: {
    getSecurityTypeByKey,
    getMarketStatusByKey,
    onQuoteInfoStartRefresh() {
      this.securityInfoLatest = {};
      if (this.currentQuoteSelectedSymbol) {
        this.SR01(this.currentQuoteSelectedSymbol.value);
      } else {
        this.$EventBus.$emit("onQuoteInfoStopRefresh");
      }
    },
    SR01(selectedSymbol) {
      if (!selectedSymbol) {
        this.securityInfoLatest = {};
        this.$EventBus.$emit("onQuoteInfoStopRefresh");
        return;
      }

      const msg = this.$messageFactory.createMessage("SR01");

      msg.securityId.set(selectedSymbol.securityId);
      msg.lastTradeCount.set(5);
      msg.full.set("Y");
      msg.exchangeId.set(selectedSymbol.exchangeId);

      this.$ws.send(msg);
    },
    getClassColorCompare(securityInfoLatest, comparison) {
      const ceiling = this.$numeral(securityInfoLatest.ceiling).value();
      const floor = this.$numeral(securityInfoLatest.floor).value();

      const prior = this.$numeral(securityInfoLatest.prior).value();
      comparison = this.$numeral(comparison).value();

      if (!comparison) {
        return "yellow";
      } else if (comparison >= ceiling) {
        return "blue";
      } else if (comparison > prior) {
        return "green";
      } else if (comparison <= floor) {
        return "pink";
      } else if (comparison < prior) {
        return "red";
      } else {
        return "yellow";
      }
    },
    securityInfoUpdateCinf(securityInfoUpdateCinf) {
      this.securityInfoLatest = Object.assign({}, securityInfoUpdateCinf);
      this.$EventBus.$emit("onQuoteInfoStopRefresh");
    },
    updateSecurityInfo(update) {
      if (update.securityId !== this.securityInfoLatest.securityId) {
        return;
      }

      if (this.securityInfoLatest.volume !== update.totalVolume) {
        this.blinkBackground("securityVolume");
      }
      this.securityInfoLatest.volume = update.totalVolume;

      if (this.securityInfoLatest.value !== update.totalValue) {
        this.blinkBackground("securityValue");
        this.blinkBackground("securityAvg");
      }
      this.securityInfoLatest.value = update.totalValue;

      if (this.$numeral(update.price).value() > this.$numeral(this.securityInfoLatest.high).value()) {
        if (this.securityInfoLatest.high !== update.price) {
          this.blinkBackground("securityHigh");
        }
        this.securityInfoLatest.high = update.price;
      }

      if (this.$numeral(update.price).value() < this.$numeral(this.securityInfoLatest.low).value()) {
        if (this.securityInfoLatest.low !== update.price) {
          this.blinkBackground("securityLow");
        }
        this.securityInfoLatest.low = update.price;
      }

      if (this.$numeral(update.totalValue).value() && this.$numeral(update.totalVolume).value()) {
        this.securityInfoLatest.average = this.$numeral(update.totalValue).value() / this.$numeral(update.totalVolume).value();
      }

      if (this.securityInfoLatest.buyVolume !== update.buyVolume) {
        this.blinkBackground("securityBuyVolume");
      }
      this.securityInfoLatest.buyVolume = update.buyVolume;

      if (this.securityInfoLatest.sellVolume !== update.sellVolume) {
        this.blinkBackground("securitySellVolume");
      }
      this.securityInfoLatest.sellVolume = update.sellVolume;

      if (this.$numeral(update.buyValue).value() && this.$numeral(update.buyVolume).value()) {
        const averageBuy = this.$numeral(update.buyValue).value() / this.$numeral(update.buyVolume).value();
        if (this.securityInfoLatest.averageBuy !== averageBuy) {
          this.blinkBackground("securityAverageBuy");
        }
        this.securityInfoLatest.averageBuy = averageBuy;
      }

      if (this.$numeral(update.sellValue).value() && this.$numeral(update.sellVolume).value()) {
        const averageSell = this.$numeral(update.sellValue).value() / this.$numeral(update.sellVolume).value();
        if (this.securityInfoLatest.averageSell !== averageSell) {
          this.blinkBackground("securityAverageSell");
        }
        this.securityInfoLatest.averageSell = averageSell;
      }
    },
    blinkBackground(ref) {
      this.$refs[ref].classList.add("blink");

      setTimeout(() => {
        this.$refs[ref].classList.remove("blink");
      }, 250);
    },
    blinkText(ref, direction) {
      this.$refs[ref].classList.add(direction);
      setTimeout(() => {
        this.$refs[ref].classList.remove(direction);
      }, 500);
    },
    onRecieveMarketStatusUpdate(update) {
      if (this.securityInfoLatest?.securityId === update.securityId) {
        this.securityInfoLatest.mktStCode = update.mktStCode;
      }
    },
  },
};
</script>

<style scoped>
.fields {
  margin: 0.5rem !important;
}

.ui.header.blink {
  background-color: rgba(22, 160, 133, 0.5) !important;
}
.ui.header.up {
  color: #00aa00 !important;
}
.ui.header.down {
  color: #d91e18 !important;
}
</style>
