<template>
  <div class="order-entry" :class="{ expand: modeOrder }" style="height: 100%">
    <div class="expand-mode-detail" v-show="modeOrder">
      <div class="market-stock-quote-container">
        <div class="column">
          <MarketStockQuote />
        </div>
      </div>
      <div class="market-info-section" ref="marketInfoSection" :style="{ minHeight: '170px' }">
        <div style="border-width: 0px 5px 5px 0px; height: 100%">
          <MarketBBO :current-quote-selected-symbol="currentQuoteSelectedSymbol" @switch-level="onSwitchLevel" />
        </div>
        <div style="border-width: 0px 5px 5px 0px; height: 100%">
          <MarketStockTicker :maximumTickersColumn="maxBBOLevel" />
        </div>
        <div style="border-width: 0px 0px 5px 0px; height: 100%; overflow: hidden">
          <MarketStockGraph :height="marketInfoSectionHeight - 40 - 5" />
        </div>
      </div>
    </div>
    <div v-show="showOrder" class="order-table-section" ref="orderTableS">
      <OrderTable
        v-show="tab === 'Order'"
        ref="gridOrder"
        v-model="ordersSelected"
        :before-nofity-s-e-t="beforeNofitySET"
        :before-nofity-t-f-e-x="beforeNofityTFEX"
      />
      <DealTable v-show="tab === 'Deal'" ref="gridDeal" />
      <PTDealTable v-show="tab === 'PTDeal'" ref="gridPTDeal" v-model="ptdealsSelected" />
    </div>
    <div class="ui grid" :style="`height: 32px;`">
      <div class="column">
        <sui-menu secondary style="margin: 0px">
          <sui-menu-item
            position="left"
            :active="tab == 'Order'"
            link
            class="ht-order-button"
            style="margin-right: 0 !important"
            @click="tab = 'Order'"
          >
            <span>Order</span>
          </sui-menu-item>
          <sui-menu-item position="left" :active="tab == 'Deal'" link class="ht-deal-button" style="margin-right: 0 !important" @click="tab = 'Deal'">
            <span>Deal</span>
          </sui-menu-item>
          <sui-menu-item
            v-if="userType === 'T'"
            position="left"
            :active="tab == 'PTDeal'"
            link
            class="ht-deal-button"
            style="margin-right: 0 !important"
            @click="tab = 'PTDeal'"
          >
            <span>Trade Report</span>
          </sui-menu-item>
          <sui-menu-item position="right">
            <div
              v-if="userType === 'T'"
              v-show="tab === 'PTDeal'"
              class="my-ptdeal-btn"
              data-tooltip="New Trade Report"
              data-position="top center"
              data-inverted="inverted"
              @click="onNewPTDealClicked"
            >
              <i class="add icon"></i>
            </div>
            <div
              v-if="userType === 'T'"
              v-show="tab === 'PTDeal'"
              :class="{ disable: ptdealsSelected.cancellable.length <= 0 }"
              style="margin-left: 0.5rem"
              class="my-delete-btn"
              data-tooltip="Cancel selected Trade Report"
              data-position="top center"
              data-inverted="inverted"
              @click="deleteSelectedPTDealItems"
            >
              <i class="trash alternate icon"></i>
            </div>
            <div
              v-if="userType === 'T'"
              v-show="tab === 'Order'"
              class="my-approve-btn"
              :class="{ disable: ordersSelected.approveable.length <= 0 }"
              data-tooltip="Approve selected orders"
              data-position="top center"
              data-inverted="inverted"
              @click="approveSelectedItems"
            >
              <i class="check icon"></i>
            </div>
            <div
              v-if="userType === 'T'"
              v-show="tab === 'Order'"
              style="margin-left: 0.5rem"
              class="my-disapprove-btn"
              :class="{ disable: ordersSelected.approveable.length <= 0 }"
              data-tooltip="Disapprove selected orders"
              data-position="top center"
              data-inverted="inverted"
              @click="disapproveSelectedItems"
            >
              <i class="delete icon"></i>
            </div>
            <div
              v-show="tab === 'Order'"
              style="margin-left: 0.5rem"
              class="my-delete-btn"
              :class="{ disable: ordersSelected.cancellable.length <= 0 }"
              data-tooltip="Cancel selected orders"
              data-position="top center"
              data-inverted="inverted"
              @click="deleteSelectedItems"
            >
              <i class="trash alternate icon"></i>
            </div>
            <div
              style="margin-left: 0.5rem"
              class="my-refresh-btn"
              data-tooltip="Refresh grid"
              data-position="top center"
              data-inverted="inverted"
              @click="refreshGrid"
            >
              <i class="refresh alternate icon"></i>
            </div>
            <div
              style="margin-left: 0.5rem"
              class="my-filter-btn"
              :data-tooltip="floatingFiltersHeight ? 'Close filter' : 'Open filter'"
              data-position="top center"
              data-inverted="inverted"
              @click="toggleFilterOrder"
            >
              <i class="filter icon"></i>
            </div>
            <div
              style="margin-left: 0.5rem"
              class="my-refresh-btn"
              :data-tooltip="modeOrder ? 'Exit expanded mode' : 'Expanded mode'"
              data-position="top right"
              data-inverted="inverted"
              @click="toggleExpandOrder"
            >
              <i class="alternate icon" :class="{ expand: !modeOrder, compress: modeOrder }"></i>
            </div>
          </sui-menu-item>
        </sui-menu>
      </div>
    </div>
    <div
      style="padding: 0; margin: 0"
      class=""
      :class="`${showOrderAdvance ? 'my-order-entry-container advance-mode' : 'my-order-entry-container'} my-bg-${
        displayState.includes('equity') || marketSelected === 'SET'
          ? 'e'
          : displayState.includes('derivative') || marketSelected === 'TFEX'
          ? 'd'
          : ''
      }${side}`"
    >
      <form id="form" ref="form" :class="{ investor: userType === 'I' }" @submit.prevent>
        <input ref="unused" type="text" tabindex="-1" style="position: absolute; left: -100px; width: 1px; height: 1px" />
        <div class="my-order-entry">
          <div :class="`my-order-entry-button ${marketSelected.toLowerCase()}`" style="position: relative" @click="onMarketSelected">
            <h4 class="header">{{ marketSelected }}</h4>
          </div>
          <div
            v-show="LicenseUtils.isEnableETS() && ((displayState === 'all' && marketSelected === 'SET') || displayState.includes('equity'))"
            class="my-order-entry order-input-container"
          >
            <div
              class="my-order-entry-button ebuy"
              :class="{ active: side === 'buy' || side === 'cover' }"
              @click="
                () => {
                  side = 'buy';
                  validateGoodTill();
                  $refs.esymbol.focus();
                }
              "
            >
              <h4 class="header">Buy</h4>
            </div>
            <div
              class="my-order-entry-button esell"
              :class="{ active: side === 'sell' || side === 'short' }"
              @click="
                () => {
                  side = 'sell';
                  validateGoodTill();
                  $refs.esymbol.focus();
                }
              "
            >
              <h4 class="header">Sell</h4>
            </div>
            <div class="my-order-entry-checkbox">
              <sui-checkbox v-model="shortCheckbox" label="Short" style="padding-left: 1rem" @input="onShortChange" />
            </div>
            <div class="my-input">
              <div style="height: 2rem; line-height: 2rem; width: 100%">
                <SymbolDropdown
                  ref="esymbol"
                  v-model="etsSymbol"
                  market="SET"
                  class="ht-ord-symbol"
                  :is-validate="marketSelected === 'SET'"
                  :disabled="changeOrderId != null && changeOrderId != undefined"
                  required
                  notification
                  order
                  @enter="nextElement($refs.evol)"
                />
              </div>
            </div>
            <div class="my-input">
              <label class="responsive-label">Vol:</label>
              <InputNumber
                ref="evol"
                v-model="volume"
                name="Volume"
                class="ht-ord-volume"
                placeholder="Volume"
                :is-validate="marketSelected === 'SET'"
                format="0,0"
                required
                :disabled="changeOrderId != null && changeOrderId != undefined && !changeCanDelete"
                @enter="nextElement($refs.eprice)"
              />
            </div>
            <div class="my-input">
              <label class="responsive-label">Price:</label>
              <InputPrice
                ref="eprice"
                v-model="price"
                name="Price"
                class="ht-ord-price"
                placeholder="Price"
                :is-validate="marketSelected === 'SET'"
                :format="PriceFormatter"
                :min="0"
                required
                :disabled="changeOrderId != null && changeOrderId != undefined && !changeCanDelete"
                @enter="onEnterPriceInput"
              />
            </div>
            <div v-if="!['M', 'U'].includes(traderType)" class="my-input" style="flex: 1.25">
              <div class="account-container">
                <label class="responsive-label">{{ "Acc.:" }}</label>
                <AccountDropdown
                  ref="eaccount"
                  v-model="setAccount"
                  market="SET"
                  name="Account"
                  class="ht-ord-account"
                  placeholder="Account"
                  :is-validate="marketSelected === 'SET'"
                  :all-account-mode="false"
                  required
                  allow-blank
                  @enter="onSETAccountInputChange"
                />
              </div>
            </div>
            <div v-else class="my-input">
              <label class="responsive-label">{{ "Acc.:" }}</label>
              <InputAccount
                ref="eaccount"
                v-model="setAccount"
                class="ht-ord-account"
                name="Account"
                placeholder="Account"
                market="SET"
                :is-validate="marketSelected === 'SET'"
                required
                @enter="onSETAccountInputChange"
                @blur="onAccountChanged"
              />
            </div>
            <div v-if="userType === 'I'" class="my-input">
              <label class="responsive-label">PIN:</label>
              <InputPIN
                ref="epin"
                v-model="pin"
                style="width: 100%"
                name="PIN"
                placeholder="PIN"
                :is-validate="marketSelected === 'SET'"
                required
                @enter="submitOrder"
              />
            </div>
          </div>
          <div
            v-show="LicenseUtils.isEnableDTS() && ((displayState === 'all' && marketSelected === 'TFEX') || displayState.includes('derivative'))"
            class="my-order-entry order-input-container"
          >
            <div
              class="my-order-entry-button dlong"
              :class="{ active: side === 'long' }"
              @click="
                () => {
                  side = 'long';
                  $refs.dsymbol.focus();
                }
              "
            >
              <h4 class="header">Long</h4>
            </div>
            <div
              class="my-order-entry-button dshort"
              :class="{ active: side === 'short' }"
              @click="
                () => {
                  side = 'short';
                  $refs.dsymbol.focus();
                }
              "
            >
              <h4 class="header">Short</h4>
            </div>
            <div class="my-order-entry-checkbox">
              <sui-checkbox :label="$_.upperFirst(position)" style="padding-left: 1rem" @input="onPositionChange" />
            </div>
            <div class="my-input">
              <div style="height: 2rem; line-height: 2rem; width: 100%">
                <SymbolDropdown
                  ref="dsymbol"
                  v-model="dtsSymbol"
                  market="TFEX"
                  style="width: 100%; min-width: 0"
                  :is-validate="marketSelected === 'TFEX'"
                  :disabled="changeOrderId != null && changeOrderId != undefined"
                  required
                  notification
                  order
                  @enter="nextElement($refs.dvol)"
                />
              </div>
            </div>
            <div class="my-input">
              <label class="responsive-label">Vol:</label>
              <InputNumber
                ref="dvol"
                v-model="volume"
                name="Volume"
                class="ht-ord-volume"
                placeholder="Volume"
                format="0,0"
                :is-validate="marketSelected === 'TFEX'"
                required
                :disabled="changeOrderId != null && changeOrderId != undefined && !changeCanDelete"
                @enter="nextElement($refs.dprice)"
              />
            </div>
            <div class="my-input">
              <label class="responsive-label">Price:</label>
              <InputPrice
                ref="dprice"
                v-model="price"
                name="Price"
                class="ht-ord-price"
                placeholder="Price"
                :is-validate="marketSelected === 'TFEX'"
                :format="PriceFormatter"
                :min="0"
                required
                :disabled="changeOrderId != null && changeOrderId != undefined && !changeCanDelete"
                @enter="nextElement($refs.daccount)"
              />
            </div>
            <div class="my-input" style="flex: 1.25">
              <div class="account-container" style="height: 2rem; line-height: 2rem">
                <label class="responsive-label">Acc.:</label>
                <AccountDropdown
                  v-if="!['M', 'U'].includes(traderType)"
                  ref="daccount"
                  v-model="tfexAccount"
                  name="Account"
                  placeholder="Account"
                  market="TFEX"
                  :is-validate="marketSelected === 'TFEX'"
                  :all-account-mode="false"
                  required
                  @enter="
                    () => {
                      this.onAccountChanged();
                      this.nextElement($refs.dpin);
                    }
                  "
                />
                <InputAccount
                  v-else
                  ref="daccount"
                  v-model="tfexAccount"
                  class="ht-ord-account"
                  name="Account"
                  placeholder="Account"
                  market="TFEX"
                  :is-validate="marketSelected === 'TFEX'"
                  required
                  @enter="
                    () => {
                      this.onAccountChanged();
                      this.nextElement($refs.dpin);
                    }
                  "
                />
              </div>
            </div>
            <div v-if="userType === 'I'" class="my-input">
              <label class="responsive-label">PIN:</label>
              <InputPIN
                ref="dpin"
                v-model="pin"
                name="PIN"
                style="width: 100%"
                placeholder="PIN"
                :is-validate="marketSelected === 'TFEX'"
                required
                @enter="submitOrder"
              />
            </div>
          </div>
          <div style="display: grid; grid-gap: 0.3rem; grid-template-columns: 1fr 1fr 1fr">
            <button
              type="button"
              class="ui mini button"
              :class="`${
                displayState.includes('equity') || marketSelected === 'SET'
                  ? 'e'
                  : displayState.includes('derivative') || marketSelected === 'TFEX'
                  ? 'd'
                  : ''
              }${side} ${processing ? 'loading disabled' : ''}`"
              @click="submitOrder"
            >
              {{ changeOrderId ? "Change" : side.charAt(0).toUpperCase() + side.substring(1) }}
            </button>
            <button type="button" class="ui mini grey button" :class="{ loading: processing, disabled: processing }" @click="clearOrder">
              {{ changeOrderId ? "Cancel" : "Clear" }}
            </button>
            <button
              type="button"
              class="ui mini button"
              style="background: rgb(200, 136, 65); color: #fff"
              :class="{ loading: processing }"
              @click="toggleAdvanceOrder"
            >
              {{ showOrderAdvance ? "Close" : "Adv." }}
            </button>
          </div>
        </div>
        <transition name="order-advance">
          <div v-show="marketSelected === 'SET' && showOrderAdvance" class="my-order-entry-advance">
            <div style="display: flex; flex-direction: column; height: 100%">
              <div style="display: flex; flex-direction: row; flex: 1">
                <div style="display: flex; flex-direction: column; flex: 0.8">
                  <div style="display: flex; flex-direction: row; line-height: 3rem">
                    <div class="my-input" style="display: inline-flex">
                      <label>Iceberg:</label>
                      <InputNumber
                        ref="eiceberg"
                        v-model="setIceberg"
                        type="number"
                        name="Iceberg"
                        :style="{
                          flex: 1,
                          'min-width': 0,
                        }"
                        placeholder="Iceberg"
                        :disabled="changeOrderId != null && changeOrderId != undefined && !changeCanDelete"
                        @enter="nextElement($refs.econd)"
                        @input="validateGoodTill"
                      />
                    </div>
                    <div class="my-input" style="display: inline-flex">
                      <label>Cond.:</label>
                      <CondDropdown
                        ref="econd"
                        v-model="setCond"
                        :disabled="(changeOrderId != null && changeOrderId != undefined && !changeCanDelete) || !enableSetCondition"
                        :selectableCallback="resolveSelectableSetCondition"
                        @enter="nextElement($refs.ettf)"
                      />
                    </div>
                    <div class="my-input" style="display: inline-flex">
                      <label>TTF:</label>
                      <TTFDropdown
                        ref="ettf"
                        v-model="setTtf"
                        @enter="
                          () => {
                            if (this.side === 'sell') {
                              nextElement($refs.eposition);
                            } else if (LicenseUtils.isEnableGoodTillOrder(this.setAccount)) {
                              nextElement($refs.evalidity);
                            } else {
                              this.submitOrder();
                            }
                          }
                        "
                      />
                    </div>
                  </div>
                  <div v-show="side === 'sell'" style="margin-top: 1rem">
                    <div class="my-input" style="display: inline-flex">
                      <label>Position:</label>
                      <SETPositionTypeDropdown ref="eposition" v-model="setPosition" @enter="nextElement($refs.evalidity)" />
                    </div>
                  </div>
                  <div style="margin-top: 1rem">
                    <div class="my-input" style="display: inline-flex">
                      <label>{{ "Validity:" }}</label>
                      <ValidityDropdown
                        ref="evalidity"
                        v-model="setValidity"
                        market="SET"
                        style="width: 7rem !important; min-width: 7rem !important"
                        :disabled="!!setCond || !enableSetValidity"
                        :selectable-callback="resolveSelectableSetValidity"
                        @enter="
                          () => {
                            this.$nextTick(() => {
                              if (this.setValidity && this.setValidity === 'SESS') {
                                nextElement($refs.evaliditysession, 10);
                              } else if (this.setValidity && this.setValidity === 'DATE') {
                                nextElement($refs.evaliditydate);
                              } else {
                                this.submitOrder();
                              }
                            });
                          }
                        "
                      />
                    </div>
                    <div v-show="setValidity && setValidity === 'SESS'" class="my-input" style="display: inline-flex">
                      <label>Session:</label>
                      <ValiditySessionDropdown
                        ref="evaliditysession"
                        v-model="setValiditySession"
                        :symbol="fullSymbol"
                        market="SET"
                        style="width: 10rem !important; min-width: 10rem !important"
                        @enter="submitOrder"
                      />
                    </div>
                    <div v-show="setValidity && setValidity === 'DATE'" class="my-input" style="display: inline-flex">
                      <DatePicker
                        ref="evaliditydate"
                        v-model="setValidityDate"
                        style="width: 140px !important; min-width: 140px !important"
                        name="Validity Date"
                        :is-validate="['DATE', 'TIME'].includes(setValidity)"
                        required
                        :start-date="setValidityStartDate"
                        placeholder="Pick a date"
                        @enter="nextElement($refs.evaliditytime)"
                      />
                      <TimePicker
                        v-if="setValidity === 'TIME'"
                        ref="evaliditytime"
                        v-model="setValidityTime"
                        style="margin-left: 5px; width: 100px !important; min-width: 100px !important"
                        name="Validity Time"
                        :is-validate="setValidity === 'TIME'"
                        required
                        placeholder="Select time"
                        @enter="submitOrder"
                      />
                    </div>
                  </div>
                </div>
                <div style="padding-right: 0.5rem"></div>
                <div style="border-left: 1px solid #1b2025"></div>
                <div style="padding-left: 0.5rem"></div>
                <div style="flex: 1; height: 100%">
                  <div class="ui top attached menu">
                    <a class="item" :class="{ active: setAdvanceOption === 'normal' }" data-tab="normal" @click="setAdvanceTab('SET', 'normal')">
                      <span>Normal</span>
                    </a>
                    <a
                      v-if="LicenseUtils.isEnableStopOrder(setAccount) && nightMode !== 'Y'"
                      class="item"
                      :class="{ active: setAdvanceOption === 'stop_order' }"
                      data-tab="stop_order"
                      @click="setAdvanceTab('SET', 'stop_order')"
                    >
                      <span>Stop Order</span>
                    </a>
                    <a
                      v-if="LicenseUtils.isEnableTrailingOrder(setAccount) && nightMode !== 'Y'"
                      class="item"
                      :class="{ active: setAdvanceOption === 'trailing' }"
                      data-tab="trailing"
                      @click="setAdvanceTab('SET', 'trailing')"
                    >
                      <span>Trailing</span>
                    </a>
                  </div>
                  <div class="ht-order-tab ui tab segment attached top" :class="{ active: setAdvanceOption === 'normal' }" data-tab="normal"></div>
                  <div
                    v-if="LicenseUtils.isEnableStopOrder(setAccount) && nightMode !== 'Y'"
                    class="ht-order-tab ui tab segment attached top"
                    :class="{ active: setAdvanceOption === 'stop_order' }"
                    data-tab="stop_order"
                  >
                    <!-- Condition 1 -->
                    <div style="display: flex; flex-direction: row">
                      <div class="my-input" style="display: inline-flex">
                        <SymbolDropdown
                          ref="estopsymbol1"
                          v-model="setStopSymbol1"
                          market="SET"
                          style="width: 100%; min-width: 20px"
                          :is-validate="setAdvanceOption === 'stop_order'"
                          :required="setAdvanceOption === 'stop_order'"
                          notification
                          :notificate-to-order="false"
                          :sync="false"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.estoptype1)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <StopTypeDropdown
                          ref="estoptype1"
                          v-model="setStopType1"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.estopoperand1)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <StopOperandDropdown
                          ref="estopoperand1"
                          v-model="setStopOperand1"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          market="SET"
                          @enter="nextElement($refs.estopcond1)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <StopConditionDropdown
                          ref="estopcond1"
                          v-model="setStopCond1"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.estopvalue1)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <InputPrice
                          ref="estopvalue1"
                          v-model="setStopValue1"
                          name="Stop Value"
                          placeholder="Stop Value"
                          style="width: 100% !important; min-width: 20px"
                          :is-validate="setAdvanceOption === 'stop_order'"
                          :required="setAdvanceOption === 'stop_order' && setStopSymbol1"
                          :format="PriceFormatter"
                          :min="0"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.estopoper)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <StopOperatorDropdown
                          ref="estopoper"
                          v-model="setStopOperator"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.estopsymbol2)"
                        />
                      </div>
                    </div>
                    <!-- Condition 2 -->
                    <div style="display: flex; flex-direction: row; margin-top: 0.5rem">
                      <div class="my-input" style="display: inline-flex">
                        <SymbolDropdown
                          ref="estopsymbol2"
                          v-model="setStopSymbol2"
                          market="SET"
                          style="width: 100%; min-width: 20px"
                          :is-validate="setAdvanceOption === 'stop_order'"
                          notification
                          :notificate-to-order="false"
                          :sync="false"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.estoptype2)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <StopTypeDropdown
                          ref="estoptype2"
                          v-model="setStopType2"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.estopoperand2)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <StopOperandDropdown
                          ref="estopoperand2"
                          v-model="setStopOperand2"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          market="SET"
                          @enter="nextElement($refs.estopcond2)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <StopConditionDropdown
                          ref="estopcond2"
                          v-model="setStopCond2"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.estopvalue2)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <InputPrice
                          ref="estopvalue2"
                          v-model="setStopValue2"
                          name="Stop Value"
                          placeholder="Stop Value"
                          style="width: 100% !important; min-width: 20px"
                          :is-validate="setAdvanceOption === 'stop_order'"
                          :required="setAdvanceOption === 'stop_order' && setStopSymbol2"
                          :format="PriceFormatter"
                          :min="0"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.estopsymbol3)"
                        />
                      </div>
                    </div>
                    <!-- Condition 3 -->
                    <div style="display: flex; flex-direction: row; margin-top: 0.5rem">
                      <div class="my-input" style="display: inline-flex">
                        <SymbolDropdown
                          ref="estopsymbol3"
                          v-model="setStopSymbol3"
                          market="SET"
                          style="width: 100% !important; min-width: 20px"
                          :is-validate="setAdvanceOption === 'stop_order'"
                          notification
                          :notificate-to-order="false"
                          :sync="false"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.estoptype3)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <StopTypeDropdown
                          ref="estoptype3"
                          v-model="setStopType3"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.estopoperand3)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <StopOperandDropdown
                          ref="estopoperand3"
                          v-model="setStopOperand3"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          market="SET"
                          @enter="nextElement($refs.estopcond3)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <StopConditionDropdown
                          ref="estopcond3"
                          v-model="setStopCond3"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.estopvalue3)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <InputPrice
                          ref="estopvalue3"
                          v-model="setStopValue3"
                          name="Stop Value"
                          placeholder="Stop Value"
                          style="width: 100% !important; min-width: 20px"
                          :is-validate="setAdvanceOption === 'stop_order'"
                          :format="PriceFormatter"
                          :min="0"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          :required="setAdvanceOption === 'stop_order' && setStopSymbol3"
                          @enter="
                            (e) => {
                              e.currentTarget.blur();
                              this.submitOrder();
                            }
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="LicenseUtils.isEnableTrailingOrder(setAccount) && nightMode !== 'Y'"
                    class="ht-order-tab ui tab segment attached top"
                    :class="{ active: setAdvanceOption === 'trailing' }"
                    style="line-height: 3rem"
                    data-tab="trailing"
                  >
                    <div class="my-input" style="display: inline-flex">
                      <label>Trailing Type:</label>
                      <TrailingTypeDropdown
                        ref="etltype"
                        v-model="setTrailingType"
                        :disabled="changeOrderId != null && changeOrderId != undefined"
                        @enter="nextElement($refs.etlamount)"
                      />
                    </div>
                    <div class="my-input" style="display: inline-flex">
                      <label>Trailing Amount:</label>
                      <InputNumber
                        ref="etlamount"
                        v-model="setTrailingAmount"
                        style="width: 8rem !important"
                        name="Trailing Amount"
                        placeholder="Trailing Amount"
                        :is-validate="setAdvanceOption === 'trailing'"
                        :required="setAdvanceOption === 'trailing'"
                        :disabled="changeOrderId != null && changeOrderId != undefined"
                        @enter="nextElement($refs.etlbasedon)"
                      />
                    </div>
                    <div class="my-input" style="display: inline-flex">
                      <label>Based On:</label>
                      <TrailingBasedOnDropdown
                        ref="etlbasedon"
                        v-model="setTrailingBasedOn"
                        :disabled="changeOrderId != null && changeOrderId != undefined"
                        @enter="nextElement($refs.etlstep)"
                      />
                    </div>
                    <div class="my-input" style="display: inline-flex">
                      <label>Step:</label>
                      <InputNumber
                        ref="etlstep"
                        v-model="setTrailingStep"
                        style="width: 3rem !important"
                        name="Step"
                        placeholder="Step"
                        format="0,0"
                        :disabled="changeOrderId != null && changeOrderId != undefined"
                        @enter="submitOrder"
                      />
                    </div>
                    <div class="my-input" style="display: inline-flex">
                      <label>Interval:</label>
                      <InputNumber
                        ref="etlinterval"
                        v-model="setTrailingInterval"
                        style="width: 5rem !important"
                        name="Interval"
                        placeholder="Interval"
                        format="0,0.0"
                        :disabled="changeOrderId != null && changeOrderId != undefined"
                        :enableTab="false"
                        @enter="
                          (e) => {
                            e.currentTarget.blur();
                            this.submitOrder();
                          }
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="order-advance">
          <div v-show="marketSelected === 'TFEX' && showOrderAdvance" class="my-order-entry-advance" :style="modeOrderEntryStyles">
            <div style="display: flex; flex-direction: column; height: 100%">
              <div style="display: flex; flex-direction: row; flex: 1">
                <div class="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                  <div
                    class="display: flex; flex-direction: row; align-items: center; justify-content: center;"
                    style="width: 441.67px !important; min-width: 441.67px !important"
                  >
                    <div class="my-input" style="display: inline-flex">
                      <label>Iceberg:</label>
                      <InputNumber
                        ref="diceberg"
                        v-model="tfexIceberg"
                        type="number"
                        name="Iceberg"
                        placeholder="Iceberg"
                        style="width: 7rem"
                        @enter="nextElement($refs.dvaliditysession)"
                      />
                    </div>
                  </div>
                  <div style="margin-top: 1rem">
                    <div class="my-input" style="display: inline-flex">
                      <label>Validity:</label>
                      <ValidityDropdown
                        ref="dvalidity"
                        v-model="tfexValidity"
                        market="TFEX"
                        style="width: 99px !important; min-width: 99px !important"
                        @enter="
                          () => {
                            this.$nextTick(() => {
                              if (this.tfexValidity && this.tfexValidity === 'SESS') {
                                nextElement($refs.dvaliditysession);
                              } else if (this.tfexValidity === 'DATE' || this.tfexValidity === 'TIME') {
                                nextElement($refs.dvaliditydate);
                              } else {
                                this.submitOrder();
                              }
                            });
                          }
                        "
                      />
                    </div>
                    <div v-if="tfexValidity && tfexValidity === 'SESS'" class="my-input" style="display: inline-flex">
                      <label>Session:</label>
                      <ValiditySessionDropdown
                        ref="dvaliditysession"
                        :symbol="fullSymbol"
                        v-model="tfexValiditySession"
                        market="TFEX"
                        style="width: 10rem !important; min-width: 10rem !important"
                      />
                    </div>
                    <div v-show="tfexValidity && ['DATE', 'TIME', 'EXP'].includes(tfexValidity)" class="my-input" style="display: inline-flex">
                      <DatePicker
                        ref="dvaliditydate"
                        v-model="tfexValidityDate"
                        style="width: 140px !important; min-width: 140px !important"
                        name="Validity Date"
                        :is-validate="['DATE', 'TIME'].includes(tfexValidity)"
                        :required="['DATE', 'TIME'].includes(tfexValidity)"
                        :disabled="tfexValidity === 'EXP'"
                        :start-date="tfexValidityStartDate"
                        placeholder="Pick a date"
                        @enter="
                          () => {
                            if (this.tfexValidity === 'DATE') {
                              nextElement($refs.dvaliditytime);
                            } else {
                              this.submitOrder();
                            }
                          }
                        "
                      />
                      <TimePicker
                        v-if="tfexValidity === 'TIME'"
                        ref="dvaliditytime"
                        v-model="tfexValidityTime"
                        style="margin-left: 5px; width: 100px !important; min-width: 100px !important"
                        name="Validity Time"
                        :is-validate="tfexValidity === 'TIME'"
                        required
                        placeholder="Select time"
                        @enter="submitOrder"
                      />
                    </div>
                  </div>
                </div>
                <div style="padding-right: 0.5rem"></div>
                <div style="border-left: 1px solid #1b2025"></div>
                <div style="padding-left: 0.5rem"></div>
                <div style="flex: 1; height: 100%">
                  <div class="ui top attached menu">
                    <a class="item" :class="{ active: tfexAdvanceOption === 'normal' }" data-tab="normal" @click="setAdvanceTab('TFEX', 'normal')">
                      <span>Normal</span>
                    </a>
                    <a
                      v-if="nightMode !== 'Y'"
                      class="item"
                      :class="{ active: tfexAdvanceOption === 'stop_order' }"
                      data-tab="stop_order"
                      @click="setAdvanceTab('TFEX', 'stop_order')"
                    >
                      <span>Stop Order</span>
                    </a>
                  </div>
                  <div class="ht-order-tab ui tab segment attached top" :class="{ active: tfexAdvanceOption === 'normal' }" data-tab="normal"></div>
                  <div
                    v-if="nightMode !== 'Y'"
                    class="ht-order-tab ui tab segment attached top"
                    :class="{ active: tfexAdvanceOption === 'stop_order' }"
                    data-tab="stop_order"
                  >
                    <div style="display: flex; flex-direction: row; line-height: 3rem">
                      <div class="my-input" style="display: inline-flex">
                        <SymbolDropdown
                          ref="dstopsymbol"
                          v-model="tfexStopSymbol"
                          market="TFEX"
                          style="width: 100%; min-width: 20px"
                          notification
                          :notificate-to-order="false"
                          :sync="false"
                          :is-validate="tfexAdvanceOption === 'stop_order'"
                          :required="tfexAdvanceOption === 'stop_order'"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.dstopoperand)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <StopOperandDropdown
                          ref="dstopoperand"
                          v-model="tfexStopOperand"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          market="TFEX"
                          @enter="nextElement($refs.dstopcond)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <StopConditionDropdown
                          ref="dstopcond"
                          v-model="tfexStopCond"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="nextElement($refs.dstopvalue)"
                        />
                      </div>
                      <div class="my-input" style="display: inline-flex">
                        <InputNumber
                          ref="dstopvalue"
                          v-model="tfexStopValue"
                          name="Stop Value"
                          placeholder="Stop Value"
                          style="width: 100%; min-width: 20px"
                          :is-validate="tfexAdvanceOption === 'stop_order'"
                          :required="tfexAdvanceOption === 'stop_order'"
                          :min="0"
                          :disabled="changeOrderId != null && changeOrderId != undefined"
                          @enter="
                            (e) => {
                              e.currentTarget.blur();
                              this.submitOrder();
                            }
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </form>
    </div>
    <OrderDetailModal class="custom-modal" ref="order-detail-modal" />
    <OrderCancelModal class="custom-modal" />
    <OrderChangeModal class="custom-modal" ref="orderChangeModal" />
    <OrderApproveModal class="custom-modal" v-if="userType === 'T'" />
    <PTDealModal class="custom-modal" v-if="userType === 'T'" />
    <PTDealCancelModal class="custom-modal" v-if="userType === 'T'" />
    <PTDealChangeModal class="custom-modal" v-if="userType === 'T'" />
    <PTDealApproveModalSET class="custom-modal" v-if="userType === 'T'" />
    <PTDealApproveModalTFEX class="custom-modal" v-if="userType === 'T'" />
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import numeral from "numeral";

import SymbolDropdown from "@/components/SymbolDropdown";
import CondDropdown from "@/components/CondDropdown";
import TTFDropdown from "@/components/TTFDropdown";
import TrailingTypeDropdown from "@/components/TrailingTypeDropdown";
import TrailingBasedOnDropdown from "@/components/TrailingBasedOnDropdown";
import StopConditionDropdown from "@/components/StopConditionDropdown";
import StopOperandDropdown from "@/components/StopOperandDropdown";
import ValidityDropdown from "@/components/ValidityDropdown";
import ValiditySessionDropdown from "@/components/ValiditySessionDropdown";
import StopTypeDropdown from "@/components/StopTypeDropdown";
import StopOperatorDropdown from "@/components/StopOperatorDropdown";
import SETPositionTypeDropdown from "@/components/SETPositionTypeDropdown";
import InputNumber from "@/components/InputNumber";
import InputPrice from "@/components/InputPrice";
import InputPIN from "@/components/InputPIN";
import InputAccount from "@/components/InputAccount";
import OrderDetailModal from "@/components/modal/OrderDetailModal";
import OrderCancelModal from "@/components/modal/OrderCancelModal";
import OrderChangeModal from "@/components/modal/OrderChangeModal";
import OrderApproveModal from "@/components/modal/OrderApproveModal";
import PTDealModal from "@/components/modal/PTDealModal";
import PTDealCancelModal from "@/components/modal/PTDealCancelModal";
import PTDealChangeModal from "@/components/modal/PTDealChangeModal";
import PTDealApproveModalSET from "@/components/modal/PTDealApproveModalSET";
import PTDealApproveModalTFEX from "@/components/modal/PTDealApproveModalTFEX";
import * as LicenseUtils from "@/utils/LicenseUtils";
import DatePicker from "@/components/DatePicker";
import TimePicker from "@/components/TimePicker";
import AccountDropdown from "@/components/AccountDropdown";
import { price as PriceFormatter, volume as VolumeFormatter, str2Price, str2Volume } from "@/utils/NumberFormatter";
import {
  parseStringEtsDateToEtsDate,
  parseEtsDateToUTCDate,
  formatDateToEtsShortDate,
  formatValidityTime,
  parseValidityTimeStrToUTCDate,
} from "@/utils/DateUtils";
import { parseStopOrderCondition } from "@/utils/StopOrderUtils";
// import { shortSide2FullSide } from "@/utils/UIUtils";
// import { isCancellableOrderStatus } from "@/utils/ValidationUtils";
// import { getBySymbolAndExchangeId } from "@/utils/SymbolUtils";

import OrderTable from "@/components/orderentry/OrderTable";
import DealTable from "@/components/orderentry/DealTable";
import PTDealTable from "@/components/orderentry/PTDealTable";
import MarketBBO from "@/components/market/MarketBBO";
import MarketStockGraph from "@/components/market/MarketStockGraph";
import MarketStockTicker from "@/components/market/MarketStockTicker";
import MarketStockQuote from "@/components/market/MarketStockQuote";

import { SecurityItem } from "@/messages/websocket/XR03";

// Don't edit this value, The 100 follows by AG-Grid infinity scroll.
const recordCount = 100;

export default {
  name: "OrderEntry",
  components: {
    MarketBBO,
    MarketStockGraph,
    MarketStockTicker,
    MarketStockQuote,
    OrderTable,
    DealTable,
    PTDealTable,
    SymbolDropdown,
    CondDropdown,
    TTFDropdown,
    TrailingTypeDropdown,
    TrailingBasedOnDropdown,
    StopConditionDropdown,
    ValidityDropdown,
    ValiditySessionDropdown,
    InputNumber,
    InputPrice,
    InputPIN,
    InputAccount,
    StopTypeDropdown,
    StopOperatorDropdown,
    StopOperandDropdown,
    OrderDetailModal,
    OrderCancelModal,
    OrderChangeModal,
    OrderApproveModal,
    PTDealModal,
    PTDealCancelModal,
    PTDealChangeModal,
    PTDealApproveModalSET,
    PTDealApproveModalTFEX,
    SETPositionTypeDropdown,
    DatePicker,
    TimePicker,
    AccountDropdown,
  },
  data: () => ({
    PriceFormatter,
    LicenseUtils,
    marketSelected: "SET",
    tab: "Order",
    market: "SET",
    entry: "BUY",
    orderContext: {
      resetFilter: false,
    },
    dealContext: {
      resetFilter: false,
    },
    selectedAccount: null,
    displayState: "all",
    changeOrderRefId: null,
    changeAdvOrderRefId: null,
    etsOrderInsertRefId: null,
    dtsOrderInsertRefId: null,
    etsDealQueryRefId: null,
    dtsDealQueryRefId: null,
    ordersSelected: {
      cancellable: [],
      approveable: [],
    },
    ptdealsSelected: {
      cancellable: [],
    },
    showOrderAdvance: false,
    inputsFilterable: ["InputNumber", "InputPrice", "InputText", "InputPIN", "SymbolDropdown", "DatePicker", "AccountDropdown", "InputAccount"],
    fullSymbol: null,
    isChangeOrder: false,
    changeOrderId: null,
    changeSendDate: null,
    changeAdvOrder: null,
    changeCanDelete: false,
    shortCheckbox: false,
    side: "buy",
    position: "open",
    etsSymbol: null,
    dtsSymbol: null,
    volume: null,
    price: null,
    setAccount: null,
    tfexAccount: null,
    pin: null,
    validity: null,
    setAdvanceOption: "normal",
    tfexAdvanceOption: "normal",
    setIceberg: null,
    setCond: null,
    setTtf: null,
    setPosition: null,
    setValidity: null,
    setValiditySession: null,
    setValidityDate: null,
    setValidityStartDate: null,
    setValidityTime: null,
    setStopSymbol1: null,
    setStopType1: null,
    setStopCond1: null,
    setStopOperand1: null,
    setStopValue1: null,
    setStopOperator: null,
    setStopSymbol2: null,
    setStopType2: null,
    setStopOperand2: null,
    setStopCond2: null,
    setStopValue2: null,
    setStopSymbol3: null,
    setStopType3: null,
    setStopOperand3: null,
    setStopCond3: null,
    setStopValue3: null,
    setTrailingType: null,
    setTrailingAmount: null,
    setTrailingBasedOn: null,
    setTrailingStep: 1,
    setTrailingInterval: 0.5,
    tfexIceberg: null,
    tfexValidity: null,
    tfexValiditySession: null,
    tfexValidityDate: null,
    tfexValidityStartDate: null,
    tfexValidityTime: null,
    tfexStopSymbol: null,
    tfexStopOperand: null,
    tfexStopCond: null,
    tfexStopValue: null,
    processing: false,
    floatingFiltersHeight: false,
    smoothToggleFilter: null,
    maxBBOLevel: 5,
    marketInfoSectionHeight: 175,
    enableSetCondtion: true,
    enableSetValidity: true,
  }),
  watch: {
    etsSymbol() {
      if (!this.etsSymbol) {
        this.fullSymbol = null;
      }
    },
    dtsSymbol() {
      if (!this.dtsSymbol) {
        this.fullSymbol = null;
      }
    },
    currentSelectedMode(currentSelectedMode) {
      if (currentSelectedMode === "SET" || currentSelectedMode === "TFEX") {
        this.marketSelected = currentSelectedMode;
      }
    },
    bussinessDate(value) {
      this.setValidityDate = new Date(value);
    },
    dtsBusinessDate(value) {
      this.tfexValidityDate = new Date(value);
    },
    nightMode(value) {
      if (value === "Y") {
        if (this.tab === "Order") {
          this.reloadOrderGrid();
        } else if (this.tab === "Deal") {
          this.reloadDealGrid();
        } else if (this.tab === "PTDeal") {
          this.reloadPTDealGrid();
        }
      }
    },
    showOrderAdvance(value) {
      if (value) {
        if (this.changeOrderId) {
          this.$nextTick(() => {
            if (this.marketSelected === "SET") {
              if (this.changeOrderId != null && this.changeOrderId != undefined && !this.changeCanDelete) {
                this.nextElement(this.$refs.ettf);
              }
            }
          });
        } else {
          this.validateGoodTill();
          this.$nextTick(() => {
            if (this.marketSelected === "SET") {
              this.$refs.eiceberg.focus();
            } else if (this.marketSelected === "TFEX") {
              this.$refs.diceberg.focus();
            }
          });
        }
      } else {
        if (this.marketSelected === "SET") {
          this.nextElement(this.$refs.esymbol);
        } else if (this.marketSelected === "TFEX") {
          this.nextElement(this.$refs.dsymbol);
        }
      }
    },
    marketSelected(value) {
      if (value === "SET") {
        this.setAccount = null;
        this.side = "buy";
        this.displayState = "equity";
      } else if (value === "TFEX") {
        this.tfexAccount = null;
        this.side = "long";
        this.position = "open";
        this.displayState = "derivative";
      }
      this.clearOrder();
      this.$nextTick(() => {
        this.$store.set("global/currentQuoteSelectedSymbol", null);
      });

      this.$EventBus.$emit("accountFreeMarket", this._uid, value);
      this.currentSelectedMode = value;
    },
    side(value) {
      if (this.changeOrderId) {
        this.changeOrderId = null;
        this.changeSendDate = null;
        this.changeAdvOrder = null;
        this.changeCanDelete = false;
        this.clearForm();
      }
      if (value === "buy" && this.shortCheckbox) {
        this.side = "cover";
      } else if (value === "sell" && this.shortCheckbox) {
        this.side = "short";
      }
    },
    displayState(value) {
      if (value.includes("equity")) {
        this.marketSelected = "SET";
      } else if (value.includes("derivative")) {
        this.marketSelected = "TFEX";
      }
    },
    tab(value) {
      if (value === "Order") {
        this.reloadOrderGrid();
      } else if (value === "Deal") {
        this.reloadDealGrid();
      } else if (value === "PTDeal") {
        this.reloadPTDealGrid();
      }
    },
    currentSelectedAccount(account) {
      if (!account || account?.accountCode === "all_accounts" || !account.accountCode) {
        this.displayState = "all";
        this.selectedAccount = null;
      } else if (account.accountCode === "all_equity_accounts") {
        this.displayState = "equity";
        this.selectedAccount = null;
      } else if (account.accountCode === "all_derivative_accounts") {
        this.displayState = "derivative";
        this.selectedAccount = null;
      } else {
        const exchangeId = account?.exchangeId || "0";
        this.selectedAccount = account.accountCode;

        // SET
        if (exchangeId == 1) {
          this.displayState = "equity";
        }
        // TFEX
        else if (exchangeId == 2) {
          this.displayState = "derivative";
        }
      }

      if (this.tab === "Order") {
        this.reloadOrderGrid();
      } else if (this.tab === "Deal") {
        this.reloadDealGrid();
      } else if (this.tab === "PTDeal") {
        this.reloadPTDealGrid();
      }
    },
    selectedAccount(value) {
      let account;
      if (this.marketSelected === "SET") {
        account = this.setAccount;
      } else if (this.marketSelected === "TFEX") {
        account = this.tfexAccount;
      }
      if (this.changeOrderId && account !== value) {
        // clear form
        this.clearOrder();
      }
      this.$nextTick(() => {
        if (this.marketSelected === "SET") {
          this.setAccount = value;
        } else if (this.marketSelected === "TFEX") {
          this.tfexAccount = value;
        }
      });
    },
    currentQuoteSelectedSymbol(value) {
      if (value) {
        if (value.value.exchangeId == 1) {
          this.market = "SET";
        } else if (value.value.exchangeId == 2) {
          this.market = "TFEX";
        }

        this.subscribeQuoteMarketStatus();

        if (this.modeOrder) {
          return this.XR03();
        }
      } else {
        this.removeQuoteMarketStatusSubscription();

        if (this.modeOrder) return this.removeSubscription();
      }
    },
    setValidity(value) {
      if (value) {
        if (value === "SESS") {
          this.$refs.evaliditysession.init().then(() => {
            this.$refs.evaliditysession.reset();
          });
        } else if (value === "DATE") {
          this.setValidityStartDate = this.bussinessDate;
        }
      }
    },
    tfexValidity(value) {
      if (value) {
        if (value === "EXP") {
          this.tfexValidityStartDate = null;
          if (this.fullSymbol) {
            this.tfexValidityDate = this.$moment(this.fullSymbol.expDate, "DD/MM/YYYY").toDate();
          } else {
            this.tfexValidityDate = new Date(this.dtsBusinessDate);
          }
        } else if (value === "SESS") {
          this.$refs.dvaliditysession.init().then(() => {
            this.$refs.dvaliditysession.reset();
          });
        } else if (value === "DATE" || value === "TIME") {
          this.tfexValidityStartDate = this.dtsBusinessDate;
        }
      }
    },
    setStopType1(value) {
      if (value === 3 && this.setStopSymbol1) {
        if (this.fullSymbol && this.setStopSymbol1.symbol === this.fullSymbol.symbol) {
          this.setStopValue1 = parseFloat(this.fullSymbol.prior);
        }
      }
    },
    setStopType2(value) {
      if (value === 3 && this.setStopSymbol2) {
        if (this.fullSymbol && this.setStopSymbol2.symbol === this.fullSymbol.symbol) {
          this.setStopValue2 = parseFloat(this.fullSymbol.prior);
        }
      }
    },
    setStopType3(value) {
      if (value === 3 && this.setStopSymbol3) {
        if (this.fullSymbol && this.setStopSymbol3.symbol === this.fullSymbol.symbol) {
          this.setStopValue3 = parseFloat(this.fullSymbol.prior);
        }
      }
    },
    price(newPrice, oldPrice) {
      if (Number(newPrice) >= 0 && (oldPrice == "ATO" || oldPrice == "ATC" || oldPrice == "MO")) {
        this.setCond = null;
      }

      this.validateGoodTill();
    },
    volume() {
      this.validateGoodTill();
    },
    modeOrder: {
      async handler(newMode) {
        if (newMode && this.currentQuoteSelectedSymbol) {
          await this.$nextTick();
          return this.XR03();
        }

        if (!newMode) return this.removeSubscription();
      },
      immediate: true,
    },
  },

  created() {
    if (!LicenseUtils.isEnableETS() && LicenseUtils.isEnableDTS()) {
      this.marketSelected = "TFEX";
      this.market = "TFEX";
    }

    this.setValidityDate = new Date(this.bussinessDate);
    this.tfexValidityDate = new Date(this.dtsBusinessDate);

    this.$EventBus.$on("accountFreeMarket", this.onReceiveAccountFreeMarket);
    this.$EventBus.$on("orderChange", this.changeOrder);
    this.$EventBus.$on("orderCancelled", this.onOrderCancelled);
    this.$EventBus.$on("ptdealCancelled", this.onPTDealCancelled);
    this.$EventBus.$on("onBBOPriceClicked", this.onBBOPriceClicked);
    this.$EventBus.$on("receiveShortcut", this.onReceiveShortcut);

    // security update info
    this.$EventBus.$on("ctinf/SS01", this.etsSecurityUpdateInfoCinf);
    this.$EventBus.$on("ctinf/SS51", this.dtsSecurityUpdateInfoCinf);
    this.$EventBus.$on("bcst/MB03", this.onRecieveQuoteMarketStatusUpdate);

    // order insert
    this.$EventBus.$on("ctord/OS01", this.etsOrderInsertCtord);
    this.$EventBus.$on("ctord/OS07", this.etsOrderAdvanceInsertCtord);
    this.$EventBus.$on("ctord/OS51", this.dtsOrderInsertCtord);

    // order changed
    this.$EventBus.$on("ctord/OS02", this.etsOrderChangedCtord);
    this.$EventBus.$on("ctord/OS52", this.dtsOrderChangedCtord);

    // order query
    this.$EventBus.$on("ctinf/OS04", this.etsOrderQueryCtinf);
    this.$EventBus.$on("ctinf/OS54", this.dtsOrderQueryCtinf);
  },
  beforeDestroy() {
    this.$EventBus.$off("accountFreeMarket", this.onReceiveAccountFreeMarket);
    this.$EventBus.$off("orderChange", this.changeOrder);
    this.$EventBus.$off("orderCancelled", this.onOrderCancelled);
    this.$EventBus.$off("ptdealCancelled", this.onPTDealCancelled);
    this.$EventBus.$off("onBBOPriceClicked", this.onBBOPriceClicked);
    this.$EventBus.$off("receiveShortcut", this.onReceiveShortcut);

    // security update info
    this.$EventBus.$off("ctinf/SS01", this.etsSecurityUpdateInfoCinf);
    this.$EventBus.$off("ctinf/SS51", this.dtsSecurityUpdateInfoCinf);
    this.$EventBus.$off("bcst/MB03", this.onRecieveQuoteMarketStatusUpdate);

    // order insert
    this.$EventBus.$off("ctord/OS01", this.etsOrderInsertCtord);
    this.$EventBus.$off("ctord/OS07", this.etsOrderAdvanceInsertCtord);
    this.$EventBus.$off("ctord/OS51", this.dtsOrderInsertCtord);

    // order changed
    this.$EventBus.$off("ctord/OS02", this.etsOrderChangedCtord);
    this.$EventBus.$off("ctord/OS52", this.dtsOrderChangedCtord);

    // order query
    this.$EventBus.$off("ctinf/OS04", this.etsOrderQueryCtinf);
    this.$EventBus.$off("ctinf/OS54", this.dtsOrderQueryCtinf);

    if (this.smoothToggleFilter) {
      clearInterval(this.smoothToggleFilter);
    }
  },
  methods: {
    onShortChange(e) {
      const checked = e.target.checked;
      if (this.side === "buy" && checked) {
        this.side = "cover";
      } else if (this.side === "sell" && checked) {
        this.side = "short";
      } else if (this.side === "cover" && !checked) {
        this.side = "buy";
      } else if (this.side === "short" && !checked) {
        this.side = "sell";
      }

      this.validateGoodTill();
    },
    onPositionChange(e) {
      const checked = e.target.checked;
      if (checked) {
        this.position = "close";
      } else {
        this.position = "open";
      }
    },
    onAccountChanged() {
      let exchangeId;
      let account;
      if (this.marketSelected === "SET") {
        exchangeId = "1";
        account = this.setAccount || "";
      } else if (this.marketSelected === "TFEX") {
        exchangeId = "2";
        account = this.tfexAccount || "";
      }

      this.$store.set("global/currentAccount", account);
      this.$store.set("global/currentSelectedAccount", {
        exchangeId,
        accountCode: account,
      });
    },
    onMarketSelected() {
      if (this.marketSelected === "SET" && LicenseUtils.isEnableDTS()) {
        this.marketSelected = "TFEX";
      } else if (this.marketSelected === "TFEX" && LicenseUtils.isEnableETS()) {
        this.marketSelected = "SET";
      }
    },
    beforeNofitySET(data) {
      if (this.currentAccount === "all_equity_accounts" || this.displayState === "all" || this.displayState === "equity") {
        if (this.selectedAccount && this.selectedAccount !== data.accountCode) {
          return false;
        }
      }
      return true;
    },
    beforeNofityTFEX(data) {
      if (this.currentAccount === "all_equity_accounts" || this.displayState === "all" || this.displayState === "derivative") {
        if (this.selectedAccount && this.selectedAccount !== data.accountCode) {
          return false;
        }
      }
      return true;
    },
    onReceiveShortcut(funcName) {
      if (funcName === "View Orders") {
        this.tab = "Order";
      } else if (funcName === "View Deals") {
        this.tab = "Deal";
      } else if (funcName === "View PT Deals") {
        this.tab = "PTDeal";
      } else if (funcName === "Buy Order") {
        this.tab = "Order";
        this.$nextTick(() => {
          if (this.marketSelected === "SET") {
            this.side = "buy";
            this.nextElement(this.$refs.esymbol);
          } else if (this.marketSelected === "TFEX") {
            this.side = "long";
            this.nextElement(this.$refs.dsymbol);
          }
        });
      } else if (funcName === "Sell Order") {
        this.tab = "Order";
        this.$nextTick(() => {
          if (this.marketSelected === "SET") {
            this.side = "sell";
            this.nextElement(this.$refs.esymbol);
          } else if (this.marketSelected === "TFEX") {
            this.side = "short";
            this.nextElement(this.$refs.dsymbol);
          }
        });
      } else if (funcName === "Toggle Advance Order") {
        this.tab = "Order";
        this.$nextTick(() => {
          this.showOrderAdvance = !this.showOrderAdvance;
        });
      } else if (funcName === "Toggle Order Exanpanded Mode") {
        this.toggleExpandOrder();
      }
    },
    etsOrderQueryCtinf(data) {
      if (this.changeOrderRefId === data.refId) {
        if (data.resultCode === 1001) {
          if (data.records.length === 1) {
            const obj = data.records[0];
            this.etsSymbol.symbol = obj.symbol;
            this.volume = str2Volume(obj.volume);
            this.price = str2Price(obj.price);
            this.setAccount = obj.accountCode;

            if (obj.icebergVol) {
              this.setIceberg = str2Price(obj.icebergVol);
            }
            if (obj.condition) {
              this.setCond = "I";
            }
            if (obj.ttf) {
              this.setTtf = obj.ttf;
            }
            this.$nextTick(() => {
              if (this.changeCanDelete) {
                this.nextElement(this.$refs.evol, 200);
              } else {
                this.nextElement(this.$refs.eaccount, 200);
              }
            });
          }
        } else {
          console.error(data.resultCode + ": " + data.resultMessage);
        }
      }
    },
    dtsOrderQueryCtinf(data) {
      if (this.changeOrderRefId === data.refId) {
        if (data.resultCode === 1001) {
          if (data.records.length === 1) {
            const obj = data.records[0];
            this.dtsSymbol = obj.symbol;
            this.volume = str2Volume(obj.volume);
            this.price = str2Price(obj.price);
            this.tfexAccount = obj.accountCode;

            if (obj.icebergVol) {
              this.tfexIceberg = str2Price(obj.icebergVol);
            }
            if (obj.validity) {
              // Goodtil
              this.tfexValidity = obj.validity;
              if (this.tfexValidity === "DATE") {
                this.$nextTick(() => {
                  this.tfexValidityDate = parseEtsDateToUTCDate(obj.validityData);
                });
              } else if (this.tfexValidity === "TIME") {
                const date = parseValidityTimeStrToUTCDate(obj.validityData);
                this.$nextTick(() => {
                  this.tfexValidityDate = date;
                  this.tfexValidityTime = date;
                });
              } else if (this.tfexValidity === "SESS") {
                this.$nextTick(() => {
                  try {
                    this.tfexValiditySession = parseInt(obj.validityData);
                  } catch (err) {
                    this.tfexValiditySession = null;
                  }
                });
              }
            }

            this.$nextTick(() => {
              if (this.changeCanDelete) {
                this.nextElement(this.$refs.dvol, 200);
              } else {
                this.nextElement(this.$refs.daccount, 200);
              }
            });
          }
        } else {
          console.error(data.resultCode + ": " + data.resultMessage);
        }
      }
    },
    etsOrderInsertCtord(data) {
      if (this.etsOrderInsertRefId !== data.refId) {
        return;
      }
      this.processing = false;
      if (data.resultCode === 1001) {
        this.clearForm();
        this.$nextTick(() => {
          this.$Swal.fire(`Complete`, `Your order number is ${data.orderId}`, "success").then(() => {
            if (this.marketSelected === "SET") {
              this.nextElement(this.$refs.evol, 200);
            } else if (this.marketSelected === "TFEX") {
              this.nextElement(this.$refs.dvol, 200);
            }
          });
        });
      } else {
        let input = null;
        if (data.resultCode === 2002) {
          // Invalid pin
          if (this.marketSelected === "SET") {
            input = this.$refs.epin;
          } else if (this.marketSelected === "TFEX") {
            input = this.$refs.dpin;
          }
        } else if (data.resultCode === 34 || data.resultCode === 35) {
          // ceiling floor
          if (this.marketSelected === "SET") {
            input = this.$refs.eprice;
          } else if (this.marketSelected === "TFEX") {
            input = this.$refs.dprice;
          }
        } else if (data.resultCode === 73) {
          // invalid customer
          if (this.marketSelected === "SET") {
            input = this.$refs.eaccount;
          } else if (this.marketSelected === "TFEX") {
            input = this.$refs.daccount;
          }
        } else {
          // Other go back to symbol
          if (this.marketSelected === "SET") {
            input = this.$refs.esymbol;
          } else if (this.marketSelected === "TFEX") {
            input = this.$refs.dsymbol;
          }
        }
        this.$Swal.fire(`Error ${data.resultCode}!`, data.resultMessage, "error").then(() => {
          if (input) {
            input.customError(data.resultMessage);
            // break for keyup event
            setTimeout(() => {
              input.select();
            }, 200);
          }
        });
      }
    },
    etsOrderAdvanceInsertCtord(data) {
      if (this.etsOrderInsertRefId !== data.refId) {
        return;
      }
      this.processing = false;
      if (data.resultCode === 1001) {
        this.clearForm();
        this.$nextTick(() => {
          this.$Swal.fire(`Complete`, `Your order number is ${data.orderId}`, "success").then(() => {
            this.nextElement(this.$refs.evol, 200);
          });
        });
      } else {
        let input = null;
        if (data.resultCode === 2002) {
          // Invalid pin
          input = this.$refs.epin;
        } else if (data.resultCode === 34 || data.resultCode === 35) {
          // ceiling floor
          input = this.$refs.eprice;
        } else if (data.resultCode === 73) {
          // invalid customer
          input = this.$refs.eaccount;
        } else {
          // Other go back to symbol
          input = this.$refs.esymbol;
        }
        this.$Swal.fire(`Error ${data.resultCode}!`, data.resultMessage, "error").then(() => {
          if (input) {
            input.customError(data.resultMessage);
            // break for keyup event
            setTimeout(() => {
              input.select();
            }, 200);
          }
        });
      }
    },
    dtsOrderInsertCtord(data) {
      if (this.dtsOrderInsertRefId !== data.refId) {
        return;
      }
      this.processing = false;
      if (data.resultCode === 1001) {
        this.clearForm();
        this.$nextTick(() => {
          this.$Swal.fire(`Complete`, `Your order number is ${data.orderId}`, "success").then(() => {
            if (this.marketSelected === "SET") {
              this.nextElement(this.$refs.evol, 200);
            } else if (this.marketSelected === "TFEX") {
              this.nextElement(this.$refs.dvol, 200);
            }
          });
        });
      } else {
        let input = null;
        if (data.resultCode === 2002) {
          // Invalid pin
          if (this.marketSelected === "SET") {
            input = this.$refs.epin;
          } else if (this.marketSelected === "TFEX") {
            input = this.$refs.dpin;
          }
        } else if (data.resultCode === 34 || data.resultCode === 35) {
          // ceiling floor
          if (this.marketSelected === "SET") {
            input = this.$refs.eprice;
          } else if (this.marketSelected === "TFEX") {
            input = this.$refs.dprice;
          }
        } else {
          // Other go back to symbol
          if (this.marketSelected === "SET") {
            input = this.$refs.esymbol;
          } else if (this.marketSelected === "TFEX") {
            input = this.$refs.dsymbol;
          }
        }
        this.$Swal.fire(`Error ${data.resultCode}!`, data.resultMessage, "error").then(() => {
          if (input) {
            input.customError(data.resultMessage);
            // break for keyup event
            setTimeout(() => {
              input.select();
            }, 200);
          }
        });
      }
    },
    etsOrderChangedCtord(data) {
      if (this.changeOrderRefId !== data.refId) {
        return;
      }
      this.processing = false;
      if (data.resultCode === 1001) {
        this.clearOrder();
        this.$nextTick(() => {
          this.$Swal.fire(`Complete`, `Your order number ${data.orderId} has been changed`, "success").then(() => {
            this.$refs.gridOrder.gridApi.deselectAll();
            this.ordersSelected = {
              cancellable: [],
              approveable: [],
            };
          });
        });
      } else {
        let input = null;
        if (data.resultCode === 2002) {
          // Invalid pin
          input = this.$refs.epin;
        } else if (data.resultCode === 34 || data.resultCode === 35) {
          // ceiling floor
          input = this.$refs.eprice;
        } else {
          // Other go back to symbol
          input = this.$refs.esymbol;
        }
        this.$Swal.fire(`Error ${data.resultCode}!`, data.resultMessage, "error").then(() => {
          if (input) {
            input.customError(data.resultMessage);
            // break for keyup event
            setTimeout(() => {
              input.select();
            }, 200);
          }
        });
      }
    },
    dtsOrderChangedCtord(data) {
      if (this.changeOrderRefId !== data.refId) {
        return;
      }
      this.processing = false;
      if (data.resultCode === 1001) {
        this.clearOrder();
        this.$nextTick(() => {
          this.$Swal.fire(`Complete`, `Your order number ${data.orderId} has been changed`, "success").then(() => {
            this.$refs.gridOrder.gridApi.deselectAll();
            this.ordersSelected = {
              cancellable: [],
              approveable: [],
            };
          });
        });
      } else {
        let input = null;
        if (data.resultCode === 2002) {
          // Invalid pin
          input = this.$refs.dpin;
        } else if (data.resultCode === 34 || data.resultCode === 35) {
          // ceiling floor
          input = this.$refs.dprice;
        } else {
          // Other go back to symbol
          input = this.$refs.esymbol;
        }
        this.$Swal.fire(`Error ${data.resultCode}!`, data.resultMessage, "error").then(() => {
          if (input) {
            input.customError(data.resultMessage);
            // break for keyup event
            setTimeout(() => {
              input.select();
            }, 200);
          }
        });
      }
    },
    etsSecurityUpdateInfoCinf(data) {
      if (data.resultCode === 1001) {
        this.fullSymbol = data;

        this.validateGoodTill();
      } else {
        console.error(data.resultCode + ": " + data.resultMessage);
      }
    },
    dtsSecurityUpdateInfoCinf(data) {
      if (data.resultCode === 1001) {
        this.fullSymbol = data;
        if (this.tfexValidity && this.tfexValidity === "EXP") {
          this.tfexValidityDate = this.$moment(this.fullSymbol.expDate, "DD/MM/YYYY").toDate();
        }
        if (this.isChangeOrder) {
          this.$refs.orderChangeModal.setFullSymbol(this.fullSymbol);
        }
      } else {
        console.error(data.resultCode + ": " + data.resultMessage);
      }
    },
    setAdvanceTab(market, value) {
      if (this.changeOrderId) {
        return;
      }
      if (market === "SET") {
        this.setAdvanceOption = value;
      } else if (market === "TFEX") {
        this.tfexAdvanceOption = value;
      }
    },
    clearForm() {
      this.volume = null;
      this.price = null;
      this.pin = null;
      this.clearAdvCond();
    },
    clearOrder() {
      this.changeOrderId = null;
      this.changeSendDate = null;
      this.changeAdvOrder = null;
      this.changeCanDelete = false;
      this.volume = null;
      this.price = null;
      if (this.selectedAccount) {
        if (this.marketSelected === "SET") {
          this.setAccount = this.selectedAccount;
        } else if (this.marketSelected === "TFEX") {
          this.tfexAccount = this.selectedAccount;
        }
      } else {
        this.setAccount = null;
        this.tfexAccount = null;
      }
      this.pin = null;
      this.clearAdvCond();
    },
    clearAdvCond() {
      this.icebreg = null;
      this.validity = null;
      this.setAdvanceOption = "normal";
      this.tfexAdvanceOption = "normal";
      this.setIceberg = null;
      this.setCond = null;
      this.setTtf = null;
      this.setStopSymbol1 = null;
      this.setStopType1 = null;
      this.setStopCond1 = null;
      this.setStopValue1 = null;
      this.setStopOperator = null;
      this.setStopSymbol2 = null;
      this.setStopType2 = null;
      this.setStopCond2 = null;
      this.setStopValue2 = null;
      this.setStopSymbol3 = null;
      this.setStopType3 = null;
      this.setStopCond3 = null;
      this.setStopValue3 = null;
      this.setTrailingType = null;
      this.setTrailingAmount = null;
      this.setTrailingBasedOn = null;
      this.setTrailingStep = 1;
      this.setTrailingInterval = 0;
      this.setValidity = null;
      this.setValiditySession = null;
      this.setValidityTime = null;
      this.tfexIceberg = null;
      this.tfexValidity = null;
      this.tfexValiditySession = null;
      this.tfexValidityTime = null;
      this.tfexStopSymbol = null;
      this.tfexStopCond = null;
      this.tfexStopValue = null;

      this.$nextTick(() => {
        setTimeout(() => {
          this.$children.forEach((c) => {
            if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
              c.clearValidate();
            }
          });
        }, 10);
      });
    },
    submitOrder() {
      let validate = true;
      const filterErrors = this.$children.filter((c) => {
        if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
          if (c.isValidate) {
            const result = c.validate();
            validate &= result;
            return !result;
          } else {
            c.clearValidate();
          }
        }
        return false;
      });
      if (validate) {
        let account = "";
        let symbol = "";
        let advanceOption = "";
        const fullSide = this.side.charAt(0).toUpperCase() + this.side.substring(1);
        const isEnableGoodTillOrder = LicenseUtils.isEnableGoodTillOrder(this.setAccount);
        let positionHtml = null;
        if (this.marketSelected === "SET") {
          symbol = this.etsSymbol.symbol;
          account = this.setAccount;

          if (this.setIceberg) {
            advanceOption += `<div class="right aligned column" style="padding-right: 1rem !important;">Iceberg:</div><div class="left aligned column" style="padding-left: 1rem !important;">${PriceFormatter(
              this.setIceberg
            )}</div>`;
          }

          if (this.setCond) {
            advanceOption += `<div class="right aligned column" style="padding-right: 1rem !important;">Condition:</div><div class="left aligned column" style="padding-left: 1rem !important;">${this.conditionFormatter(
              this.setCond
            )}</div>`;
          }

          if (this.setTtf) {
            advanceOption += `<div class="right aligned column" style="padding-right: 1rem !important;">TTF:</div><div class="left aligned column" style="padding-left: 1rem !important;">${this.ttfFormatter(
              this.setTtf
            )}</div>`;
          }

          if (this.setValidity && this.setValidity !== "DAY" && isEnableGoodTillOrder) {
            advanceOption += `<div class="right aligned column" style="padding-right: 1rem !important;">Validity:</div><div class="left aligned column" style="padding-left: 1rem !important;">${this.setValidityFormatter(
              this.setValidity
            )}</div>`;
          }

          if (this.setValidity && this.setValidity === "DATE" && isEnableGoodTillOrder) {
            advanceOption += `<div class="right aligned column" style="padding-right: 1rem !important;">Validity Data:</div><div class="left aligned column" style="padding-left: 1rem !important;">${
              this.$moment(this.setValidityDate).format("DD/MM/YYYY") || "-"
            }</div>`;
          }

          if (this.setValidity && this.setValidity == "SESS" && isEnableGoodTillOrder) {
            advanceOption += `<div class="right aligned column" style="padding-right: 1rem !important;">Validity Data:</div><div class="left aligned column" style="padding-left: 1rem !important;">${this.setValiditySessionFormatter(
              this.setValiditySession
            )}</div>`;
          }
        } else if (this.marketSelected === "TFEX") {
          symbol = this.dtsSymbol.symbol;
          account = this.tfexAccount;
          positionHtml = `
            <div class="right aligned column" style="padding-right: 1rem !important;">Position:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${
              this.position.charAt(0).toUpperCase() + this.position.substring(1)
            }</div>
          `;
          if (this.tfexIceberg) {
            advanceOption += `<div class="right aligned column" style="padding-right: 1rem !important;">Iceberg:</div><div class="left aligned column" style="padding-left: 1rem !important;">${PriceFormatter(
              this.tfexIceberg
            )}</div>`;
          }
          if (this.tfexValidity && this.tfexValidity !== "DAY") {
            advanceOption += `<div class="right aligned column" style="padding-right: 1rem !important;">Validity:</div><div class="left aligned column" style="padding-left: 1rem !important;">${this.tfexValidityFormatter(
              this.tfexValidity
            )}</div>`;
          }
          if (this.tfexValidity && (this.tfexValidity === "DATE" || this.tfexValidity === "EXP")) {
            advanceOption += `<div class="right aligned column" style="padding-right: 1rem !important;">Validity Data:</div><div class="left aligned column" style="padding-left: 1rem !important;">${
              this.$moment(this.tfexValidityDate).format("DD/MM/YYYY") || "-"
            }</div>`;
          }

          if (this.tfexValidity && this.tfexValidity === "TIME") {
            advanceOption += `<div class="right aligned column" style="padding-right: 1rem !important;">Validity Data:</div><div class="left aligned column" style="padding-left: 1rem !important;">${
              this.$moment(this.tfexValidityDate).format("DD/MM/YYYY") || "-"
            } ${this.$moment(this.tfexValidityTime).format("HH:mm") || "-"}</div>`;
          }
        }
        this.$refs.unused.focus();
        this.$refs.unused.blur();

        let totalPriceStr = "N/A";
        if (!isNaN(this.price)) {
          totalPriceStr = PriceFormatter((this.price * this.volume).toFixed(2));
        }
        const html = `
        <div>
            <div class="ui two column grid">
              <div class="right aligned column ht-${this.side}" style="padding-right: 1rem !important;">${fullSide}:</div>
              <div class="left aligned column" style="padding-left: 1rem !important;">${symbol}</div>
              ${positionHtml ? positionHtml : ""}
              <div class="right aligned column" style="padding-right: 1rem !important;">Volume:</div>
              <div class="left aligned column" style="padding-left: 1rem !important;">${VolumeFormatter(this.volume)}</div>
              <div class="right aligned column" style="padding-right: 1rem !important;">Price:</div>
              <div class="left aligned column" style="padding-left: 1rem !important;">${PriceFormatter(this.price)}</div>
              ${advanceOption || ""}
              <div class="right aligned column" style="padding-right: 1rem !important;">Account:</div>
              <div class="left aligned column" style="padding-left: 1rem !important;">${account}</div>
              <div class="right aligned column" style="padding-right: 1rem !important;">Total Amount:</div>
              <div class="left aligned column" style="padding-left: 1rem !important;">${totalPriceStr}</div>
            </div>
            ${
              !isEnableGoodTillOrder && this.setValidity !== "DAY"
                ? `<br/><div style="color:yellow;">*This account is not allowed to use good till order</div>`
                : ""
            }
          </div>
        `;
        this.$Swal
          .fire({
            title: "Order Confirmation",
            html: html,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              this.confirmOrder();
            } else if (
              result.dismiss === this.$Swal.DismissReason.cancel ||
              result.dismiss === this.$Swal.DismissReason.backdrop ||
              result.dismiss === this.$Swal.DismissReason.esc
            ) {
              setTimeout(() => {
                if (this.marketSelected === "SET") {
                  this.$refs.esymbol.select();
                } else if (this.marketSelected === "TFEX") {
                  this.$refs.dsymbol.select();
                }
              }, 100);
              return false;
            }
          });
      } else {
        filterErrors[0].select();
      }
    },
    confirmOrder() {
      if (this.marketSelected === "SET") {
        if (this.changeOrderId) {
          this.OR02();
        } else {
          if (this.setAdvanceOption === "normal") {
            this.OR01();
          } else {
            this.OR07();
          }
        }
      } else if (this.marketSelected === "TFEX") {
        if (this.changeOrderId) {
          this.OR52();
        } else {
          this.OR51();
        }
      } else {
        this.$Swal.fire(`Warning!`, `Invalid market type`, "warning");
      }
    },
    onReceiveAccountFreeMarket(uid, market) {
      this.marketSelected = market;
    },
    async changeOrder(data) {
      if (!data) {
        return;
      }

      this.isChangeOrder = true;
      const result = await this.$refs.orderChangeModal.changeOrder(data, this.fullSymbol);
      this.isChangeOrder = false;
      if (!result) {
        return;
      }
      if (data.exchangeId == 1) {
        this.OR02(result.oldOrder, result.changeCanDelete, result.newValue);
      } else if (data.exchangeId == 2) {
        this.OR52(result.oldOrder, result.changeCanDelete, result.newValue);
      } else {
        this.$Swal.fire(`Warning!`, `Invalid market type`, "warning");
      }
    },
    nextElement(nextElement, timeout) {
      if (!nextElement) {
        this.$nextTick(() => {
          this.submitOrder();
        });
        return;
      }
      if (nextElement.isDisabled()) {
        this.$nextTick(() => {
          this.submitOrder();
        });
        return;
      }
      if (timeout) {
        setTimeout(() => {
          nextElement.select();
        }, timeout);
      } else {
        nextElement.select();
      }
    },
    reloadOrderGrid() {
      this.$refs.gridOrder.reload();
    },
    reloadDealGrid() {
      this.$refs.gridDeal.reload();
    },
    reloadPTDealGrid() {
      this.$refs.gridPTDeal.reload();
    },
    getClassLabelColor(entry) {
      if (this.market === "SET") {
        switch (entry) {
          case "BUY":
            return "ht-buy-label";
          case "SELL":
            return "ht-sell-label";
          case "COVER":
            return "ht-cover-label";
          case "SHORT":
            return "ht-short-label";
        }
      } else {
        switch (entry) {
          case "LONG":
            return "ht-buy-label";
          case "SHORT":
            return "ht-sell-label";
        }
      }
    },
    getClassOrderButtonColor(entry) {
      if (this.market === "SET") {
        switch (entry) {
          case "BUY":
            return "green";
          case "SELL":
            return "red";
          case "COVER":
            return "blue";
          case "SHORT":
            return "purple";
        }
      } else {
        switch (entry) {
          case "LONG":
            return "green";
          case "SHORT":
            return "red";
        }
      }
    },
    deleteSelectedPTDealItems() {
      if (this.ptdealsSelected.cancellable.length <= 0) {
        return;
      }
      this.$EventBus.$emit("ptdealCancel", this.ptdealsSelected.cancellable);
    },
    deleteSelectedItems() {
      if (this.ordersSelected.cancellable.length <= 0) {
        return;
      }
      this.$EventBus.$emit("orderCancel", this.ordersSelected.cancellable);
    },
    approveSelectedItems() {
      if (this.ordersSelected.approveable.length <= 0) {
        return;
      }
      this.$EventBus.$emit("orderApprove", this.ordersSelected.approveable);
    },
    disapproveSelectedItems() {
      if (this.ordersSelected.approveable.length <= 0) {
        return;
      }
      this.$EventBus.$emit("orderApprove", this.ordersSelected.approveable, false);
    },
    refreshGrid() {
      if (this.tab === "Order") {
        this.reloadOrderGrid();
      } else if (this.tab === "Deal") {
        this.reloadDealGrid();
      } else if (this.tab === "PTDeal") {
        this.reloadPTDealGrid();
      }
    },
    toggleExpandOrder() {
      this.modeOrder = !this.modeOrder;
      this.$EventBus.$emit("market-bbo-size-change");
    },
    toggleFilterOrder() {
      console.log("toggle filter");
      this.floatingFiltersHeight = !this.floatingFiltersHeight;
      if (this.smoothToggleFilter) {
        clearInterval(this.smoothToggleFilter);
      }
      if (this.floatingFiltersHeight) {
        let start = -1;
        this.smoothToggleFilter = setInterval(() => {
          this.$refs.gridOrder.gridApi.setFloatingFiltersHeight(start);
          this.$refs.gridDeal.gridApi.setFloatingFiltersHeight(start);
          this.$refs.gridPTDeal.gridApi.setFloatingFiltersHeight(start);
          start += 2;
          if (start >= 32) {
            clearInterval(this.smoothToggleFilter);
          }
        }, 1);
      } else {
        let start = 32;
        this.smoothToggleFilter = setInterval(() => {
          this.$refs.gridOrder.gridApi.setFloatingFiltersHeight(start);
          this.$refs.gridDeal.gridApi.setFloatingFiltersHeight(start);
          this.$refs.gridPTDeal.gridApi.setFloatingFiltersHeight(start);
          start -= 2;
          if (start <= -1) {
            clearInterval(this.smoothToggleFilter);
          }
        }, 1);
      }
    },
    toggleAdvanceOrder() {
      this.showOrderAdvance = !this.showOrderAdvance;
    },
    onOrderCancelled() {
      this.$refs.gridOrder.gridApi.deselectAll();
      this.ordersSelected = {
        cancellable: [],
        approveable: [],
      };
    },
    onPTDealCancelled() {
      this.$refs.gridPTDeal.gridApi.deselectAll();
      this.ptdealsSelected = {
        cancellable: [],
      };
    },
    onBBOPriceClicked(symbol, exchangeId, type, price) {
      if (exchangeId == "1") {
        this.marketSelected = "SET";
        let side = "";
        if (type === "bid") {
          side = "sell";
        } else if (type === "offer") {
          side = "buy";
        }
        this.$nextTick(() => {
          this.side = side;
          this.etsSymbol.symbol = symbol;
          this.price = str2Price(price);

          this.nextElement(this.$refs.evol, 200);
        });
      } else if (exchangeId == "2") {
        this.marketSelected = "TFEX";
        let side = "";
        if (type === "bid") {
          side = "short";
        } else if (type === "offer") {
          side = "long";
        }
        this.$nextTick(() => {
          this.position = "open";
          this.side = side;
          this.dtsSymbol.symbol = symbol;
          this.price = str2Price(price);

          this.nextElement(this.$refs.dvol, 200);
        });
      }
    },
    OR01() {
      this.processing = true;

      const msg = this.$messageFactory.createMessage("OR01");

      msg.symbol.set(this.etsSymbol.symbol);

      let side = "";
      if (this.side === "buy") {
        side = "B";
      } else if (this.side === "sell") {
        side = "S";
        msg.positionType.set(this.setPosition);
      } else if (this.side === "cover") {
        side = "C";
      } else if (this.side === "short") {
        side = "H";
      }
      msg.side.set(side);
      msg.volume.set(this.volume);
      msg.price.set(this.price);
      msg.accountCode.set(this.setAccount);

      if (this.setIceberg) {
        msg.icebergVol.set(this.setIceberg);
      }
      if (this.setCond) {
        msg.condition.set(this.setCond);
      }
      if (this.setTtf) {
        msg.ttf.set(this.setTtf);
      }
      if (this.userType === "I") {
        msg.pinCode.set(this.pin);
      }

      // Goodtil
      msg.validity.set(" ");
      if (LicenseUtils.isEnableGoodTillOrder(this.setAccount) && this.setValidity) {
        msg.validity.set(this.setValidity);
        if (this.setValidity === "DATE") {
          msg.validityData.set(formatDateToEtsShortDate(this.setValidityDate));
        } else if (this.setValidity === "SESS") {
          msg.validityData.set(this.setValiditySession);
        }
      }

      this.etsOrderInsertRefId = this.$shortid.generate();
      this.$ws.send(msg, this.etsOrderInsertRefId);
    },
    OR02(order, changeCanDelete, newValue) {
      this.processing = true;

      const msg = this.$messageFactory.createMessage("OR02");
      msg.orderId.set(order.orderId);
      msg.accountCode.set(newValue.account);
      msg.validity.set(newValue.validity);

      if (newValue.validity === "DATE") {
        msg.validityData.set(formatDateToEtsShortDate(newValue.validityData));
      } else if (newValue.validity === "SESS") {
        msg.validityData.set(newValue.validityData);
      }
      if (newValue.ttf) {
        msg.ttf.set(newValue.ttf);
      }
      if (this.userType === "I") {
        msg.pinCode.set(newValue.pin);
      }

      if (changeCanDelete) {
        msg.volume.set(newValue.volume);
        msg.price.set(newValue.price);
        if (newValue.iceberg) {
          msg.icebergVol.set(newValue.iceberg);
        }
      }

      this.changeOrderRefId = this.$shortid.generate();
      this.$ws.send(msg, this.changeOrderRefId);
    },
    OR52(order, changeCanDelete, newValue) {
      this.processing = true;

      const msg = this.$messageFactory.createMessage("OR52");
      msg.orderId.set(order.orderId);
      msg.sendDate.set(parseStringEtsDateToEtsDate(order.sendDate));
      msg.accountCode.set(newValue.account);

      if (newValue.iceberg) {
        msg.icebergVol.set(newValue.iceberg);
      }
      if (this.userType === "I") {
        msg.pinCode.set(newValue.pin);
      }

      if (changeCanDelete) {
        msg.volume.set(newValue.volume);
        msg.price.set(newValue.price);

        // Goodtil
        msg.validity.set(" ");
        if (newValue.validity) {
          msg.validity.set(newValue.validity);
          if (newValue.validity === "DATE") {
            msg.validityData.set(formatDateToEtsShortDate(newValue.validityDate));
          } else if (newValue.validity === "TIME") {
            msg.validityData.set(formatValidityTime(newValue.validityDate, newValue.validityTime));
          } else if (newValue.validity === "SESS") {
            msg.validityData.set(newValue.validitySession);
          }
        }
      }

      this.changeOrderRefId = this.$shortid.generate();
      this.$ws.send(msg, this.changeOrderRefId);
    },
    OR07() {
      this.processing = true;

      const msg = this.$messageFactory.createMessage("OR07");

      msg.symbol.set(this.etsSymbol.symbol);

      let side = "";
      if (this.side === "buy") {
        side = "B";
      } else if (this.side === "sell") {
        side = "S";
        msg.positionType.set(this.setPosition);
      } else if (this.side === "cover") {
        side = "C";
      } else if (this.side === "short") {
        side = "H";
      }
      msg.side.set(side);
      msg.volume.set(this.volume);
      msg.price.set(this.price);
      msg.accountCode.set(this.setAccount);

      if (this.setIceberg) {
        msg.icebergVol.set(this.setIceberg);
      }
      if (this.setCond) {
        msg.condition.set(this.setCond);
      }
      if (this.setTtf) {
        msg.ttf.set(this.setTtf);
      }
      if (this.userType === "I") {
        msg.pinCode.set(this.pin);
      }

      // Goodtil
      msg.validity.set(" ");
      if (this.setValidity && LicenseUtils.isEnableGoodTillOrder(this.setAccount)) {
        msg.validity.set(this.setValidity);
        if (this.setValidity === "DATE") {
          msg.validityData.set(formatDateToEtsShortDate(this.setValidityDate));
        } else if (this.setValidity === "SESS") {
          msg.validityData.set(this.setValiditySession);
        }
      }

      // advance order
      if (this.setAdvanceOption === "stop_order" && LicenseUtils.isEnableStopOrder(this.setAccount)) {
        msg.specialOrderType.set("S");
        let conditions = 0;

        if (this.setStopSymbol1) {
          ++conditions;
          msg.tickerSec1.set(this.setStopSymbol1.symbol);
          msg.advType1.set(this.setStopType1);
          msg.advVal1.set(numeral(this.setStopValue1).format("0.00"));
          msg.condition1.set(parseStopOrderCondition(this.setStopOperand1, this.setStopCond1));
        }
        if (this.setStopSymbol2) {
          ++conditions;
          msg.tickerSec2.set(this.setStopSymbol2.symbol);
          msg.advType2.set(this.setStopType2);
          msg.advVal2.set(numeral(this.setStopValue2).format("0.00"));
          msg.condition2.set(parseStopOrderCondition(this.setStopOperand2, this.setStopCond2));
        }
        if (this.setStopSymbol3) {
          ++conditions;
          msg.tickerSec3.set(this.setStopSymbol3.symbol);
          msg.advType3.set(this.setStopType3);
          msg.advVal3.set(numeral(this.setStopValue3).format("0.00"));
          msg.condition3.set(parseStopOrderCondition(this.setStopOperand3, this.setStopCond3));
        }

        if (conditions === 0) {
          // handle hacking process
          this.processing = false;
          this.$Swal.fire(`Warning!`, `The stop condition is required`, "warning");
          return;
        }

        msg.operation.set(this.setStopOperator);
      } else if (this.setAdvanceOption === "trailing" && LicenseUtils.isEnableTrailingOrder(this.setAccount)) {
        msg.specialOrderType.set("T");

        msg.advType1.set(this.setTrailingType);
        msg.advVal1.set(this.setTrailingAmount);
        msg.condition1.set(this.setTrailingBasedOn);
        msg.advStep.set(this.setTrailingStep);
        if (this.setTrailingStep === null) {
          msg.advStep.set("1");
        }
        msg.advIntervalTime.set(this.setTrailingInterval);
        if (this.setTrailingInterval === null) {
          msg.advIntervalTime.set("0");
        }
      } else {
        // handle hacking process
        this.processing = false;
        this.$Swal.fire(`Warning!`, `Invalid stop option`, "warning");
        return;
      }

      this.etsOrderInsertRefId = this.$shortid.generate();
      this.$ws.send(msg, this.etsOrderInsertRefId);
    },
    OR51() {
      this.processing = true;

      const msg = this.$messageFactory.createMessage("OR51");

      msg.symbol.set(this.dtsSymbol.symbol);

      let side = "";
      if (this.side === "long") {
        side = "L";
      } else if (this.side === "short") {
        side = "S";
      }
      msg.side.set(side);
      let position = "";
      if (this.position === "open") {
        position = "O";
      } else if (this.position === "close") {
        position = "C";
      }
      msg.positionType.set(position);
      msg.volume.set(this.volume);
      msg.price.set(this.price);
      msg.accountCode.set(this.tfexAccount);

      if (this.tfexIceberg) {
        msg.icebergVol.set(this.tfexIceberg);
      }
      if (this.userType === "I") {
        msg.pinCode.set(this.pin);
      }

      // Goodtil
      msg.validity.set(" ");
      if (this.tfexValidity) {
        msg.validity.set(this.tfexValidity);
        if (this.tfexValidity === "DATE") {
          msg.validityData.set(formatDateToEtsShortDate(this.tfexValidityDate));
        } else if (this.tfexValidity === "TIME") {
          msg.validityData.set(formatValidityTime(this.tfexValidityDate, this.tfexValidityTime));
        } else if (this.tfexValidity === "SESS") {
          msg.validityData.set(this.tfexValiditySession);
        }
      }

      // Advance order
      if (this.tfexAdvanceOption === "stop_order") {
        msg.specialOrderType.set("S");
        msg.stopSymbol.set(this.tfexStopSymbol.symbol);
        msg.stopCondition.set(parseStopOrderCondition(this.tfexStopOperand, this.tfexStopCond));
        msg.stopPrice.set(numeral(this.tfexStopValue).format("0.00"));
      }

      this.dtsOrderInsertRefId = this.$shortid.generate();
      this.$ws.send(msg, this.dtsOrderInsertRefId);
    },
    onNewPTDealClicked() {
      this.$EventBus.$emit("ptdealOpen");
    },
    onSwitchLevel(isShowAll) {
      if (this.maxBBOLevel === 5) {
        this.maxBBOLevel = 10;
      } else {
        this.maxBBOLevel = 5;
      }
      this.$EventBus.$emit("market-bbo-size-change");
    },
    resolveSelectableSetCondition(option) {
      const marketOrderList = ["ATO", "ATC", "MO", "ML"];

      if (!this.fullSymbol || this.fullSymbol?.securityId !== this.etsSymbol?.value.securityId) return true;

      const marketStatus = this.fullSymbol.mktStCode;

      if (marketStatus === "Pre-Open") {
        if (this.setIceberg || (this.price && !marketOrderList.includes(this.price)) || (this.price && this.price == "MO"))
          return option.value !== "F";
      }
      return true;
    },
    resolveSelectableSetValidity(option) {
      if (!this.fullSymbol || this.fullSymbol?.securityId !== this.etsSymbol?.value.securityId) return true;

      const marketStatus = this.fullSymbol.mktStCode;

      if (marketStatus === "Open") {
        if (this.side == "short") {
          return option.value == "DAY";
        }
      }

      return true;
    },
    validateGoodTill() {
      const preTradeSessionList = ["Pre-Open", "Call Mkt"];
      const marketOrderList = ["ATO", "ATC", "MO", "ML"];

      if (!this.fullSymbol || this.fullSymbol?.securityId !== this.etsSymbol?.value.securityId) return true;

      const marketStatus = this.fullSymbol.mktStCode;
      this.enableSetValidity = true;
      this.enableSetCondition = true;

      if (this.setCond) {
        this.setValidity = null;
      }

      if (preTradeSessionList.includes(marketStatus)) {
        if (this.side == "short") {
          this.setValidity = null;
          this.setCond = null;
          this.enableSetCondition = false;
          this.enableSetValidity = false;
          return;
        }

        if (this.price && marketOrderList.includes(this.price) && this.setIceberg) {
          this.setIceberg = null;
          if (this.price == "ATO" || this.price == "ATC") {
            this.setCond = "I";
            this.enableSetCondition = false;
            return;
          }

          if (!marketOrderList.includes(this.price) && this.setCond == "F") {
            this.setCond = null;
            return;
          }
        }

        if (this.price) {
          if (this.price == "ATO" || this.price == "ATC") {
            this.setCond = "I";
            this.enableSetCondition = false;
            return;
          }

          if (this.price == "MO") {
            this.setValidity = null;
            this.enableSetValidity = false;
            this.setCond = "I";
            return;
          }

          if (!marketOrderList.includes(this.price) && this.setCond == "F") {
            this.setCond = null;
            return;
          }
        }
        if (this.setIceberg) {
          if (this.setCond == "F") {
            this.setCond = "";
            return;
          }
        }

        return;
      }

      if (marketStatus === "Open") {
        if (this.volume && Number(this.volume) < Number(this.fullSymbol.boardLot)) {
          this.setCond = null;
          this.enableSetCondition = false;
        }

        if (this.side == "short") {
          if (this.setValidity == "DATE" || this.setValidity == "Cancel") {
            this.setValidity = null;
            return;
          }
        }

        if (this.price && marketOrderList.includes(this.price) && this.setIceberg) {
          this.setIceberg = null;

          if (this.price == "MO") {
            this.setValidity = null;
            this.enableSetValidity = false;
            this.setCond = "I";
            return;
          }

          return;
        }

        if (this.price) {
          if (this.price == "MO") {
            this.setValidity = null;
            this.enableSetValidity = false;
            this.setCond = "I";
            return;
          }

          if (this.price == "ATO" || this.price == "ATC") {
            this.setValidity = null;
            this.enableSetValidity = false;
            this.enableSetCondtion = false;
            this.setCond = "I";
            return;
          }

          return;
        }
      }
    },
    onEnterPriceInput() {
      this.validateGoodTill();
      this.nextElement(this.$refs.eaccount);
    },
    ttfFormatter(value) {
      switch (value) {
        case "1":
          return "TTF";
        case "2":
          return "NVDR";
        default:
          return "-";
      }
    },
    conditionFormatter(value) {
      switch (value) {
        case "F":
          return "FOK";
        case "I":
          return "IOC";
        default:
          return "-";
      }
    },
    setValidityFormatter(value) {
      switch (value) {
        case "DAY":
          return "Day";
        case "DATE":
          return "Date";
        case "SESS":
          return "Session";
        case "GTC":
          return "Cancel";
        default:
          return "-";
      }
    },
    setValiditySessionFormatter(value) {
      switch (value) {
        case "O1":
          return "Open1";
        case "PO2":
          return "Pre-Open2";
        case "O2":
          return "Open2";
        case "CMK":
          return "Call Market";
        case "CL":
          return "Close";
        default:
          return "-";
      }
    },
    tfexValidityFormatter(value) {
      switch (value) {
        case "DAY":
          return "Day";
        case "DATE":
          return "Date";
        case "FOK":
          return "FOK";
        case "FAK":
          return "FAK";
        case "EXP":
          return "Exp";
        case "TIME":
          return "Time";
        case "SESS":
          return "Session";
        case "NXPRE":
          return "NXPRE";
        default:
          return "-";
      }
    },
    subscribeQuoteMarketStatus() {
      //quote market status
      const mb03Msg = this.$messageFactory.createMessage("XR03");

      let resolvedSecurityList = [];

      if (this.currentQuoteSelectedSymbol) {
        const securityItem = new SecurityItem();
        securityItem.securityId = Number(this.currentQuoteSelectedSymbol.value.securityId);

        resolvedSecurityList.push(securityItem);
      }

      mb03Msg.messageCode.set("MB03");
      mb03Msg.securityList.set(resolvedSecurityList);
      this.$ws.send(mb03Msg);
    },

    removeQuoteMarketStatusSubscription() {
      //quote market status
      const mb03Msg = this.$messageFactory.createMessage("XR03");

      mb03Msg.messageCode.set("MB03");
      mb03Msg.mode.set("D");
      this.$ws.send(mb03Msg);
    },

    XR03() {
      // bbos
      const pb21Msg = this.$messageFactory.createMessage("XR03");
      const pb22Msg = this.$messageFactory.createMessage("XR03");

      //PriceOpenTradeBcst
      const pb03Msg = this.$messageFactory.createMessage("XR03");
      //PriceProjectionBcst
      const pb04Msg = this.$messageFactory.createMessage("XR03");

      pb21Msg.messageCode.set("PB21");
      pb22Msg.messageCode.set("PB22");
      pb03Msg.messageCode.set("PB03");
      pb04Msg.messageCode.set("PB04");

      let resolvedSecurityList = [];

      if (this.currentQuoteSelectedSymbol) {
        const securityItem = new SecurityItem();
        securityItem.securityId = Number(this.currentQuoteSelectedSymbol.value.securityId);

        resolvedSecurityList.push(securityItem);
      }

      pb21Msg.securityList.set(resolvedSecurityList);
      pb22Msg.securityList.set(resolvedSecurityList);
      pb03Msg.securityList.set(resolvedSecurityList);
      pb04Msg.securityList.set(resolvedSecurityList);

      this.$ws.send(pb21Msg);
      this.$ws.send(pb22Msg);
      this.$ws.send(pb03Msg);
      this.$ws.send(pb04Msg);

      if (LicenseUtils.isEnableDTS()) {
        const pb52Msg = this.$messageFactory.createMessage("XR03");

        pb52Msg.messageCode.set("PB52");
        pb52Msg.securityList.set(resolvedSecurityList);

        this.$ws.send(pb52Msg);
      }
    },
    removeSubscription() {
      const pb21Msg = this.$messageFactory.createMessage("XR03");
      const pb22Msg = this.$messageFactory.createMessage("XR03");

      //PriceOpenTradeBcst
      const pb03Msg = this.$messageFactory.createMessage("XR03");
      //PriceProjectionBcst
      const pb04Msg = this.$messageFactory.createMessage("XR03");

      pb21Msg.messageCode.set("PB21");
      pb22Msg.messageCode.set("PB22");
      pb03Msg.messageCode.set("PB03");
      pb04Msg.messageCode.set("PB04");

      pb21Msg.mode.set("D");
      pb22Msg.mode.set("D");
      pb03Msg.mode.set("D");
      pb04Msg.mode.set("D");

      this.$ws.send(pb21Msg);
      this.$ws.send(pb22Msg);
      this.$ws.send(pb03Msg);
      this.$ws.send(pb04Msg);

      if (LicenseUtils.isEnableDTS()) {
        const pb52Msg = this.$messageFactory.createMessage("XR03");

        pb52Msg.messageCode.set("PB52");
        pb52Msg.mode.set("D");

        this.$ws.send(pb52Msg);
      }
    },

    onSETAccountInputChange() {
      this.onAccountChanged();

      const isEnableGoodTillOrder = LicenseUtils.isEnableGoodTillOrder(this.setAccount);

      if (!isEnableGoodTillOrder && this.setValidity !== "DAY")
        return this.$Swal.fire(`Warning!`, "This account is not allowed to use good till order.", "warning").then(() => {
          this.nextElement(this.$refs.epin);
        });

      this.nextElement(this.$refs.epin);
    },
    onRecieveQuoteMarketStatusUpdate(update) {
      if (update.securityId === this.fullSymbol?.securityId) {
        this.fullSymbol = JSON.parse(JSON.stringify({ ...this.fullSymbol, ...update }));
      }
    },
  },
  computed: {
    modeOrderEntryStyles() {
      if (this.modeOrder) {
        return "height: 12rem !important;";
      } else {
        return "height: calc(100vh - 6rem - 20px - 55vh - 32px - 3rem - 3rem) !important;";
      }
    },
    showOrder: function () {
      if (this.modeOrder) {
        return true;
      }
      return !this.showOrderAdvance;
    },

    theme: sync("global/theme"),
    modeOrder: sync("global/modeOrder"),
    userType: get("model/systemRefData@userType"),
    traderType: get("global/traderType"),
    currentAccount: get("global/currentAccount"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
    goodtillTraderFlag: get("model/systemRefData@goodtillTraderFlag"),
    pinDigitsOnly: get("model/systemRefData@pinDigitsOnly"),
    pinMaxLength: get("model/systemRefData@pinMaxLength"),

    nightMode: get("model/systemRefData@nightMode"),
    bussinessDate: get("model/systemRefData@bussinessDate"),
    dtsBusinessDate: get("model/systemRefData@dtsBusinessDate"),
    currentSelectedMode: sync("global/currentSelectedMode"),
    currentSelectedAccount: get("global/currentSelectedAccount"),
  },
};
</script>

<style scoped>
.order-entry {
  display: flex;
  flex-direction: column;
  background-color: black;
  row-gap: 5px;
}

.order-entry .custom-modal {
  position: absolute;
  top: -10000px;
}

.order-entry .market-stock-quote-container {
  /* height: 58px; */
}

.order-entry .order-table-section {
  height: 100%;
  flex: 1;
}
.order-entry .market-info-section {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0 5px;

  background-color: black;
}

.order-entry .expand-mode-detail {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.order-entry .responsive-label {
  display: none;
}

/* .order-entry.expand .my-order-entry-container.advance-mode {
  max-height: 16rem;
}

@media screen and (min-width: 1500px) {
  .order-entry.expand .my-order-entry-container.advance-mode {
    max-height: 16rem;
  }
} */

@media screen and (min-width: 768px) {
  .order-entry .responsive-label {
    display: inline-block;
  }
}

.ht-ord-symbol {
  width: 100% !important;
  min-width: 0 !important;
}

.ht-ord-volume {
  width: 100% !important;
  min-width: 0 !important;
}
.ht-ord-price {
  width: 100% !important;
  min-width: 0 !important;
}

@media screen and (max-width: 1600px) {
  /* .investor .ht-ord-symbol {
    width: 7rem !important;
    min-width: 7rem !important;
  }
  .investor .ht-ord-volume {
    width: 5rem !important;
    min-width: 5rem !important;
  }
  .investor .ht-ord-price {
    width: 5rem !important;
    min-width: 5rem !important;
  } */
}

.ht-border {
  border-style: solid;
  border-color: black;
}

.ht-buy-label {
  color: #2185d0;
}

.ht-sell-label {
  color: #e03997;
}

.ht-cover-label {
  color: #2185d0;
}

.ht-short-label {
  color: #e03997;
}

.ht-input-box {
  font-weight: bold !important;
  padding: 0.25rem !important;
  background-color: #2f383f !important;
  color: #dcddde !important;
}

.ui.grey.header {
  color: #dcddde !important;
}

.ui.button.fluid.dropdown {
  padding: 0.25rem !important;
  background-color: #2f383f;
}

div.five.wide.field > button {
  text-align: center !important;
}

.ht-buy-button,
.ht-sell-button,
.ht-cover-button,
.ht-short-button,
.ht-order-button,
.ht-deal-button {
  margin: 0px !important;
  border-radius: 0px !important;
}

.ht-buy-button.active,
.ht-sell-button.active,
.ht-cover-button.active,
.ht-short-button.active,
.ht-order-button.active,
.ht-deal-button.active {
  background-color: #262d33 !important;
}

.my-approve-btn {
  padding: 5px 1rem;
  border: 1px solid #00aa00;
  color: #00aa00;
  border-radius: 5px;
  cursor: pointer;
  background-color: #1b2025;
}
.my-approve-btn.disable {
  border: 1px solid #2f383f;
  color: #2f383f;
  cursor: default;
}

.my-ptdeal-btn {
  padding: 5px 1rem;
  border: 1px solid #2185d0;
  color: #2185d0;
  border-radius: 5px;
  cursor: pointer;
  background-color: #1b2025;
}
.my-ptdeal-btn.disable {
  border: 1px solid #2f383f;
  color: #2f383f;
  cursor: default;
}

.my-disapprove-btn {
  padding: 5px 1rem;
  border: 1px solid #d91e18;
  color: #d91e18;
  border-radius: 5px;
  cursor: pointer;
  background-color: #1b2025;
}
.my-disapprove-btn.disable {
  border: 1px solid #2f383f;
  color: #2f383f;
  cursor: default;
}

.my-delete-btn {
  padding: 5px 1rem;
  border: 1px solid #838383;
  color: #838383;
  border-radius: 5px;
  cursor: pointer;
  background-color: #1b2025;
}
.my-delete-btn.disable {
  border: 1px solid #2f383f;
  color: #2f383f;
  cursor: default;
}

.my-refresh-btn {
  padding: 5px 1rem;
  border: 1px solid #dcddde;
  color: #dcddde;
  border-radius: 5px;
  cursor: pointer;
  background-color: #1b2025;
}

.my-filter-btn {
  padding: 5px 1rem;
  border: 1px solid #dcddde;
  color: #dcddde;
  border-radius: 5px;
  cursor: pointer;
  background-color: #1b2025;
}

.my-order-entry {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  align-items: center;
}

.my-order-entry-button {
  width: 3.5rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  /* border: 1px solid #DCDDDE; */
  color: #dcddde;
  cursor: pointer;
  background-color: #262d33;
}

.my-order-entry-checkbox {
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  /* border: 1px solid #DCDDDE; */
  color: #dcddde;
  cursor: pointer;
}

.my-order-entry-button h4 {
  padding: 0;
  line-height: 3rem;
}
.my-order-entry-button.ebuy,
.my-order-entry-button.dlong {
  color: #2185d0;
}
.my-order-entry-button.ebuy.active,
.my-order-entry-button.dlong.active {
  color: #fff;
  background: #2185d0;
}
.my-order-entry-button.esell,
.my-order-entry-button.dshort {
  color: #e03997;
}
.my-order-entry-button.esell.active,
.my-order-entry-button.dshort.active {
  color: #fff;
  background: #e03997;
}
.my-order-entry-button.ecover {
  color: #2185d0;
}
.my-order-entry-button.ecover.active {
  color: #fff;
  background: #2185d0;
}
.my-order-entry-button.eshort {
  color: #e03997;
}
.my-order-entry-button.eshort.active {
  color: #fff;
  background: #e03997;
}

.my-order-entry-button.position {
  background: #dcddde;
  color: #000;
}

.my-order-entry.order-input-container {
  flex: 1;
  min-width: 0;
  padding-right: 1rem;
}

.my-bg-ebuy,
.my-bg-dlong {
  background: rgb(33, 133, 208, 0.25);
}
.my-bg-esell,
.my-bg-dshort {
  background: rgb(224, 57, 151, 0.25);
}
.my-bg-ecover {
  background: rgb(33, 133, 208, 0.25);
}
.my-bg-eshort {
  background: rgb(224, 57, 151, 0.25);
}

.my-order-entry-button.set {
  border: none;
  background: #fcb134;
  color: #000;
}
.my-order-entry-button.tfex {
  border: none;
  background: #6285bf;
  color: #000;
}

.my-input {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 20px;
}

.my-order-entry .my-input {
  height: 100%;
}

@media screen and (max-width: 1280px) {
  .my-input {
    margin-left: 0.75rem;
  }
}

.my-input label {
  color: #fff;
  padding-right: 0.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.my-input .account-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.ui.input {
  width: 100px;
  height: 2rem;
}
.ui.selection {
  height: 2rem;
  min-height: 2rem;
  line-height: 2rem;
  padding: 0 0.25rem;
  margin: 0;
  width: 100px;
  min-width: 100px;
}
.ui.input input {
  color: #dcddde;
  background: #2f383f;
  padding: 0 0.25rem;
  font-weight: 700;
}
.ui.input input:focus {
  color: #dcddde !important;
  background: #2f383f !important;
}

.my-order-entry-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.my-order-entry-container.advance-mode {
  /* height: 100%; */
  flex: 1;
  min-height: 0;
}

.my-order-entry-container > form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.my-order-entry-container button {
  font-weight: 700;
}
.my-order-entry-container button.ebuy,
.my-order-entry-container button.dlong {
  color: #2185d0;
}
.my-order-entry-container button.esell,
.my-order-entry-container button.dshort {
  color: #e03997;
}
.my-order-entry-container button.ecover {
  color: #2185d0;
}
.my-order-entry-container button.eshort {
  color: #e03997;
}

.my-order-entry-advance {
  padding: 1rem 0;
  background: rgb(38, 45, 51) !important;
  overflow: auto;
  flex: 1;
}

.order-advance-enter-active {
  transition: all 0.3s ease;
}
.order-advance-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.order-advance-enter,
.order-advance-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.ht-order-tab.ui.tab.segment.attached.active.top {
  padding: 1rem 0 !important;
}
</style>

<style>
.ht-ord-account {
  width: 100% !important;
  min-width: 0 !important;
  height: 2rem;
}

@media screen and (max-width: 1600px) {
  /* .investor .ht-ord-account,
  .investor .ht-ord-account .ht-dropdown {
    width: 6rem !important;
    min-width: 6rem !important;
  } */
}

.ht-order-entry .ag-overlay-wrapper.ag-layout-normal.ag-overlay-no-rows-wrapper,
.ht-order-entry .ag-overlay-wrapper.ag-layout-normal.ag-overlay-loading-wrapper {
  padding-top: 5.5vh;
}

.inline.fields > * > .ui.button.fluid.dropdown > .dropdown.icon {
  display: none !important;
}

.five.wide.field > .ui.button.fluid.dropdown > div.text {
  font-size: 0.85rem !important;
}

.ht-buy-header {
  height: 32px !important;
  border: 0px !important;
  background-color: #1b2025 !important;
}

.ht-sell-header {
  height: 32px !important;
  border: 0px !important;
  background-color: #1b2025 !important;
}

.ui.secondary.menu {
  min-height: unset;
  height: 32px !important;
  border: 0px !important;
  background-color: #1b2025 !important;
}

a.item.ht-buy-button > span,
.ht-long,
.ht-buy {
  color: #2185d0 !important;
}

a.item.ht-sell-button > span,
.ht-sell {
  color: #e03997 !important;
}

a.item.ht-cover-button > span,
.ht-cover {
  color: #2185d0 !important;
}

a.item.ht-short-button > span,
.ht-short {
  color: #e03997 !important;
}

a.item.active.ht-buy-button > span,
a.item.active.ht-sell-button > span,
a.item.active.ht-cover-button > span,
a.item.active.ht-short-button > span {
  border-radius: 0.5rem !important;
  padding: 0.25rem !important;
}

a.item.active.ht-buy-button > span {
  color: #2185d0 !important;
  border: 1px solid #2185d0 !important;
}

a.item.active.ht-sell-button > span {
  color: #e03997 !important;
  border: 1px solid #e03997 !important;
}

a.item.active.ht-cover-button > span {
  color: #2185d0 !important;
  border: 1px solid #2185d0 !important;
}

a.item.active.ht-short-button > span {
  color: #e03997 !important;
  border: 1px solid #e03997 !important;
}

.ui.tabular.menu .active.item {
  background-color: #262d33 !important;
  border-color: #262d33 !important;
}
.ui.attached:not(.message) + .ui.attached.segment:not(.top) {
  margin-top: -8px;
  background: rgb(38, 45, 51) !important;
  border: none !important;
}

.ui.tab.segment.attached {
  overflow: unset !important;
}

.ui .checkbox label {
  color: #dcddde !important;
}
</style>
?
