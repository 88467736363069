/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class SS03 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._securities = new ArrayMessageBase(Security);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get securities() {
		return this._securities;
	}
}

export class Security {
	constructor() {
		this._recordId = new StringMessageBase();
		this._securityId = new NumberMessageBase();
		this._symbol = new StringMessageBase();
		this._prior = new StringMessageBase();
		this._open1 = new StringMessageBase();
		this._open2 = new StringMessageBase();
		this._high = new StringMessageBase();
		this._low = new StringMessageBase();
		this._last = new StringMessageBase();
		this._change = new StringMessageBase();
		this._changePercent = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._value = new StringMessageBase();
		this._topBid = new StringMessageBase();
		this._topOffer = new StringMessageBase();
		this._topBidVol = new StringMessageBase();
		this._topOfferVol = new StringMessageBase();
		this._ceiling = new StringMessageBase();
		this._floor = new StringMessageBase();
		this._changeFlag = new StringMessageBase();
		this._swingPercent = new StringMessageBase();
		this._turnoverFlag = new StringMessageBase();
		this._turnoverStartDate = new StringMessageBase();
		this._turnoverEndDate = new StringMessageBase();
		this._turnoverByBrokFlag = new StringMessageBase();
		this._turnoverByBrokStartDate = new StringMessageBase();
		this._turnoverByBrokEndDate = new StringMessageBase();
		this._exchangeId = new NumberMessageBase();
		this._decInPrice = new NumberMessageBase();
		this._open = new StringMessageBase();
		this._openVol = new StringMessageBase();
		this._openPercent = new StringMessageBase();
		this._projectedOpen = new StringMessageBase();
		this._projectedVol = new StringMessageBase();
		this._projectedOpenPercent = new StringMessageBase();
		this._projectedClose = new StringMessageBase();
		this._projectedClosePercent = new StringMessageBase();
		this._valBrk = new StringMessageBase();
		this._volBrk = new StringMessageBase();
		this._prevClose = new StringMessageBase();
		this._average = new StringMessageBase();
	}
	
	get recordId() {
		return this._recordId;
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get prior() {
		return this._prior;
	}
	
	get open1() {
		return this._open1;
	}
	
	get open2() {
		return this._open2;
	}
	
	get high() {
		return this._high;
	}
	
	get low() {
		return this._low;
	}
	
	get last() {
		return this._last;
	}
	
	get change() {
		return this._change;
	}
	
	get changePercent() {
		return this._changePercent;
	}
	
	get volume() {
		return this._volume;
	}
	
	get value() {
		return this._value;
	}
	
	get topBid() {
		return this._topBid;
	}
	
	get topOffer() {
		return this._topOffer;
	}
	
	get topBidVol() {
		return this._topBidVol;
	}
	
	get topOfferVol() {
		return this._topOfferVol;
	}
	
	get ceiling() {
		return this._ceiling;
	}
	
	get floor() {
		return this._floor;
	}
	
	get changeFlag() {
		return this._changeFlag;
	}
	
	get swingPercent() {
		return this._swingPercent;
	}
	
	get turnoverFlag() {
		return this._turnoverFlag;
	}
	
	get turnoverStartDate() {
		return this._turnoverStartDate;
	}
	
	get turnoverEndDate() {
		return this._turnoverEndDate;
	}
	
	get turnoverByBrokFlag() {
		return this._turnoverByBrokFlag;
	}
	
	get turnoverByBrokStartDate() {
		return this._turnoverByBrokStartDate;
	}
	
	get turnoverByBrokEndDate() {
		return this._turnoverByBrokEndDate;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	get decInPrice() {
		return this._decInPrice;
	}
	
	get open() {
		return this._open;
	}
	
	get openVol() {
		return this._openVol;
	}
	
	get openPercent() {
		return this._openPercent;
	}
	
	get projectedOpen() {
		return this._projectedOpen;
	}
	
	get projectedVol() {
		return this._projectedVol;
	}
	
	get projectedOpenPercent() {
		return this._projectedOpenPercent;
	}
	
	get projectedClose() {
		return this._projectedClose;
	}
	
	get projectedClosePercent() {
		return this._projectedClosePercent;
	}
	
	get valBrk() {
		return this._valBrk;
	}
	
	get volBrk() {
		return this._volBrk;
	}
	
	get prevClose() {
		return this._prevClose;
	}
	
	get average() {
		return this._average;
	}
}

export default SS03;
