<script>
import BaseDropdown from "./BaseDropdown";

export default {
  extends: BaseDropdown,
  props: {
    value: {
      type: String,
    },
    market: {
      type: String,
      default: "SET",
    },
    placeholder: {
      type: String,
      default: "Condition",
    },
    filterable: {
      type: String,
      default: "startsWith",
    },
  },
  data() {
    return {
      style: "width: 100%; min-width: 20px;",
      defaultValue: "Bid",
      displayValue: "Bid",
      options: [
        {
          text: "Bid",
          value: "Bid",
        },
        {
          text: "Offer",
          value: "Offer",
        },
        {
          text: "Last",
          value: "Last",
        },
      ],
    };
  },
  created() {
    if (this.market === "SET") {
      const SETOptions = [
        {
          text: "High",
          value: "High",
        },
        {
          text: "Low",
          value: "Low",
        },
        {
          text: "Avg",
          value: "Avg",
        },
      ];
      this.options = this.options.concat(SETOptions);
    }
  },
};
</script>
