<script>
import BaseDropdown from "./BaseDropdown";

export default {
  extends: BaseDropdown,
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "Condition",
    },
    filterable: {
      type: String,
      default: "startsWith",
    },
  },
  data() {
    return {
      style: "width: 100%; min-width: 20px;",
      defaultValue: ">=",
      displayValue: ">=",
      options: [
        {
          text: ">=",
          value: ">=",
        },
        {
          text: "<=",
          value: "<=",
        },
      ],
    };
  },
};
</script>
