const parseStopOrderCondition = (operand, condition) => {
  if (operand === "Bid") {
    switch (condition) {
      case ">=":
        return 1;
      case "<=":
        return 2;
    }
  }

  if (operand === "Offer") {
    switch (condition) {
      case ">=":
        return 3;
      case "<=":
        return 4;
    }
  }

  if (operand === "Last") {
    switch (condition) {
      case ">=":
        return 5;
      case "<=":
        return 6;
    }
  }

  if (operand === "High") {
    switch (condition) {
      case ">=":
        return 7;
      case "<=":
        return 8;
    }
  }

  if (operand === "Low") {
    switch (condition) {
      case ">=":
        return 9;
      case "<=":
        return 10;
    }
  }

  if (operand === "Avg") {
    switch (condition) {
      case ">=":
        return 11;
      case "<=":
        return 12;
    }
  }
};

export { parseStopOrderCondition };
