/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class XB01 extends WsMsg {
	constructor() {
		super(2);
		this._time = new StringMessageBase();
		this._message = new StringMessageBase();
	}
	
	get time() {
		return this._time;
	}
	
	get message() {
		return this._message;
	}
}

export default XB01;
