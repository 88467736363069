/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class IB51 extends WsMsg {
	constructor() {
		super(2);
		this._indexId = new NumberMessageBase();
		this._last = new StringMessageBase();
		this._high = new StringMessageBase();
		this._low = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._value = new StringMessageBase();
		this._time = new StringMessageBase();
	}
	
	get indexId() {
		return this._indexId;
	}
	
	get last() {
		return this._last;
	}
	
	get high() {
		return this._high;
	}
	
	get low() {
		return this._low;
	}
	
	get volume() {
		return this._volume;
	}
	
	get value() {
		return this._value;
	}
	
	get time() {
		return this._time;
	}
}

export default IB51;
