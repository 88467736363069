/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class AS53 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._sumPrevIM = new StringMessageBase();
		this._sumPrevMM = new StringMessageBase();
		this._sumPrevFM = new StringMessageBase();
		this._sumPrevInterCom = new StringMessageBase();
		this._sumCurrQty = new StringMessageBase();
		this._sumCurrIM = new StringMessageBase();
		this._sumCurrMM = new StringMessageBase();
		this._sumCurrFM = new StringMessageBase();
		this._sumCurrInterCom = new StringMessageBase();
		this._sumExptQty = new StringMessageBase();
		this._sumExptIM = new StringMessageBase();
		this._sumExptMM = new StringMessageBase();
		this._sumExptFM = new StringMessageBase();
		this._sumExptInterCom = new StringMessageBase();
		this._records = new ArrayMessageBase(Record);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get sumPrevIM() {
		return this._sumPrevIM;
	}
	
	get sumPrevMM() {
		return this._sumPrevMM;
	}
	
	get sumPrevFM() {
		return this._sumPrevFM;
	}
	
	get sumPrevInterCom() {
		return this._sumPrevInterCom;
	}
	
	get sumCurrQty() {
		return this._sumCurrQty;
	}
	
	get sumCurrIM() {
		return this._sumCurrIM;
	}
	
	get sumCurrMM() {
		return this._sumCurrMM;
	}
	
	get sumCurrFM() {
		return this._sumCurrFM;
	}
	
	get sumCurrInterCom() {
		return this._sumCurrInterCom;
	}
	
	get sumExptQty() {
		return this._sumExptQty;
	}
	
	get sumExptIM() {
		return this._sumExptIM;
	}
	
	get sumExptMM() {
		return this._sumExptMM;
	}
	
	get sumExptFM() {
		return this._sumExptFM;
	}
	
	get sumExptInterCom() {
		return this._sumExptInterCom;
	}
	
	get records() {
		return this._records;
	}
}

export class Record {
	constructor() {
		this._marketId = new NumberMessageBase();
		this._symbol = new StringMessageBase();
		this._prevQty = new StringMessageBase();
		this._prevIM = new StringMessageBase();
		this._prevMM = new StringMessageBase();
		this._prevFM = new StringMessageBase();
		this._prevInterCom = new StringMessageBase();
		this._currQty = new StringMessageBase();
		this._currIM = new StringMessageBase();
		this._currMM = new StringMessageBase();
		this._currFM = new StringMessageBase();
		this._currInterCom = new StringMessageBase();
		this._exptQty = new StringMessageBase();
		this._exptIM = new StringMessageBase();
		this._exptMM = new StringMessageBase();
		this._exptFM = new StringMessageBase();
		this._exptInterCom = new StringMessageBase();
	}
	
	get marketId() {
		return this._marketId;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get prevQty() {
		return this._prevQty;
	}
	
	get prevIM() {
		return this._prevIM;
	}
	
	get prevMM() {
		return this._prevMM;
	}
	
	get prevFM() {
		return this._prevFM;
	}
	
	get prevInterCom() {
		return this._prevInterCom;
	}
	
	get currQty() {
		return this._currQty;
	}
	
	get currIM() {
		return this._currIM;
	}
	
	get currMM() {
		return this._currMM;
	}
	
	get currFM() {
		return this._currFM;
	}
	
	get currInterCom() {
		return this._currInterCom;
	}
	
	get exptQty() {
		return this._exptQty;
	}
	
	get exptIM() {
		return this._exptIM;
	}
	
	get exptMM() {
		return this._exptMM;
	}
	
	get exptFM() {
		return this._exptFM;
	}
	
	get exptInterCom() {
		return this._exptInterCom;
	}
}

export default AS53;
