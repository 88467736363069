<template>
  <div class="index-bar" :class="{ 'enable-dts': isEnableDTS() }">
    <div class="set-info-section">
      <div class="set-info-container">
        <div class="ui gray header set-label" :class="{ inverted: theme === 'Dark' }">
          {{ "SET" }}
        </div>
        <div
          class="ui header set-index"
          ref="setIndex"
          :class="[getClassPriceColor(indexesLatest['.SET'].last, indexesLatest['.SET'].prior), { blink: componentsClass.setIndex }]"
        >
          {{
            $numeral(indexesLatest[".SET"].last).value() === 0
              ? $numeral(indexesLatest[".SET"].prior).format("0,0.00")
              : $numeral(indexesLatest[".SET"].last).format("0,0.00")
          }}
        </div>
        <div class="set-index-changes-container">
          <div
            ref="setIndexChange"
            class="ui header set-index-changes"
            :class="[getClassPriceColor(indexesLatest['.SET'].last, indexesLatest['.SET'].prior), { blink: componentsClass.setIndexChange }]"
          >
            {{
              $numeral(indexesLatest[".SET"].last).value() === 0
                ? "0.00"
                : ($numeral(indexesLatest[".SET"].last).value() > $numeral(indexesLatest[".SET"].prior).value() ? "+" : "") +
                  $numeral(Number(indexesLatest[".SET"].last.replaceAll(",", "")) - Number(indexesLatest[".SET"].prior.replaceAll(",", ""))).format(
                    "0,0.00"
                  )
            }}
          </div>
          <div
            ref="setIndexPercentChange"
            class="ui header set-index-changes"
            :class="[getClassPriceColor(indexesLatest['.SET'].last, indexesLatest['.SET'].prior), { blink: componentsClass.setIndexPercentChange }]"
          >
            {{
              $numeral(indexesLatest[".SET"].last).value() === 0
                ? "0.00%"
                : ($numeral(indexesLatest[".SET"].last).value() > $numeral(indexesLatest[".SET"].prior).value() ? "+" : "") +
                  $numeral(
                    ((Number(indexesLatest[".SET"].last.replaceAll(",", "")) - Number(indexesLatest[".SET"].prior.replaceAll(",", ""))) /
                      Number(indexesLatest[".SET"].prior.replaceAll(",", ""))) *
                      100
                  ).format("0,0.00") +
                  "%"
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="set-50-info-section">
      <div class="set-open-info-container">
        <div>{{ "Open" }}</div>
        <div class="ui yellow header set-open-value">{{ indexesLatest[".SET"].open1 || "-" }}</div>
      </div>
      <div class="value-info-container">
        <div class="ui grey header value-info-label" :class="{ inverted: theme === 'Dark' }">
          {{ "Value" }}
        </div>
        <div ref="setIndexVal" class="ui yellow header value-info-label" :class="[{ blink: componentsClass.setIndexVal }]">
          {{ `${$numeral(Number(indexesLatest[".SET"].value.replaceAll(",", "")) / 1000000).format("0,0.00")} MB` }}
        </div>
      </div>
      <div class="set-index-dropdown-container" ref="dropdown-container">
        <SET50Dropdown :select-value="currentIndexDropdown" :options="indexMarquee" @select-item="onSelectDropdownItem" />
      </div>
      <div
        ref="set50Index"
        class="ui header set-50-index"
        :class="[
          getClassPriceColor(indexesLatest[currentIndexDropdown].last, indexesLatest[currentIndexDropdown].prior),
          { blink: componentsClass.set50Index },
        ]"
      >
        <i v-if="$numeral(indexesLatest[currentIndexDropdown].last).value() === 0" class="sort rotated yellow icon" />
        <i
          v-else-if="$numeral(indexesLatest[currentIndexDropdown].last).value() > $numeral(indexesLatest[currentIndexDropdown].prior).value()"
          class="caret up green icon"
        />
        <i
          v-else-if="$numeral(indexesLatest[currentIndexDropdown].last).value() < $numeral(indexesLatest[currentIndexDropdown].prior).value()"
          class="caret down red icon"
        />
        <i v-else class="sort rotated yellow icon" />
        <div>
          {{
            $numeral(indexesLatest[currentIndexDropdown].last).value() === 0
              ? $numeral(indexesLatest[currentIndexDropdown].prior).format("0,0.00")
              : $numeral(indexesLatest[currentIndexDropdown].last).format("0,0.00")
          }}
        </div>
      </div>
      <div
        ref="set50IndexChange"
        class="ui header set-50-index-change"
        :class="[
          getClassPriceColor(indexesLatest[currentIndexDropdown].last, indexesLatest[currentIndexDropdown].prior),
          { blink: componentsClass.set50IndexChange },
        ]"
      >
        {{
          $numeral(indexesLatest[".SET"].last).value() === 0
            ? "0.00"
            : ($numeral(indexesLatest[currentIndexDropdown].last).value() > $numeral(indexesLatest[currentIndexDropdown].prior).value() ? "+" : "") +
              $numeral(indexesLatest[currentIndexDropdown].last - indexesLatest[currentIndexDropdown].prior).format("0,0.00")
        }}
      </div>
      <div class="set-50-open1">
        <div>{{ "Open" }}</div>
        <div class="ui yellow header open-label">
          {{ indexesLatest[currentIndexDropdown]?.open1 || "-" }}
        </div>
      </div>
      <div class="high-low-changes">
        <div class="high-low-label">{{ "H/L" }}</div>
        <div class="high-low-value">
          <span v-if="$numeral(indexesLatest[currentIndexDropdown].high).value() === 0" ref="set50IndexHighPrice" class="ui small grey header"
            >-
          </span>
          <span
            v-else
            ref="set50IndexHighPrice"
            class="ui small header"
            :class="[
              getClassPriceColor(indexesLatest[currentIndexDropdown].high, indexesLatest[currentIndexDropdown].prior),
              { blink: componentsClass.set50IndexHighPrice },
            ]"
            >{{ $numeral(indexesLatest[currentIndexDropdown].high).format("0,0.00") }}
          </span>

          <span class="ui grey small header">/</span>

          <span v-if="$numeral(indexesLatest[currentIndexDropdown].low).value() === 0" ref="set50IndexLowPrice" class="ui small grey header">- </span>
          <span
            v-else
            ref="set50IndexLowPrice"
            class="ui small header"
            :class="[
              getClassPriceColor(indexesLatest[currentIndexDropdown].low, indexesLatest[currentIndexDropdown].prior),
              { blink: componentsClass.set50IndexLowPrice },
            ]"
            >{{ $numeral(indexesLatest[currentIndexDropdown].low).format("0,0.00") }}
          </span>
        </div>
      </div>
      <div class="set-50-value">
        <div class="ui grey header value-label" :class="{ inverted: theme === 'Dark' }">
          {{ "Value" }}
        </div>
        <div ref="set50IndexVal" class="ui yellow header value-label" :class="[{ blink: componentsClass.set50IndexVal }]">
          {{ `${$numeral(indexesLatest[currentIndexDropdown].value / 1000000).format("0,0.00")} MB` }}
        </div>
      </div>
      <div class="set-50-volume">
        <div class="ui grey header volume-label" :class="{ inverted: theme === 'Dark' }">{{ "Volume" }}</div>
        <div ref="set50IndexVol" class="ui yellow header volume-label" :class="[{ blink: componentsClass.set50IndexVol }]">
          {{ $numeral(indexesLatest[currentIndexDropdown].volume).format("0,0") }}
        </div>
      </div>
      <div class="set-status">
        <div class="ui grey header status-label" :class="{ inverted: theme === 'Dark' }">
          {{ "SET Status" }}
        </div>
        <div class="ui grey header seperator" :class="{ inverted: theme === 'Dark' }">{{ ":" }}</div>
        <div ref="marketStatus" class="ui yellow header status-label" :class="[{ blink: componentsClass.marketStatus }]">
          <div v-if="nightMode === 'Y'">{{ "After Close" }}</div>
          <div v-else>
            {{ marketsLatest && ($_.find(marketsLatest, ["name", "SET"]).mktStCode || "-") }}
          </div>
        </div>
      </div>
      <div class="set-high-price-info">
        <div class="ui grey header high-info-label title" :class="{ inverted: theme === 'Dark' }">{{ "High" }}</div>
        <div>
          <div v-if="$numeral(indexesLatest['.SET'].high).value() === 0" ref="setIndexHighPrice" class="ui grey header">-</div>
          <div
            v-else
            ref="setIndexHighPrice"
            class="ui header high-info-label"
            :class="[getClassPriceColor(indexesLatest['.SET'].high, indexesLatest['.SET'].prior), { blink: componentsClass.setIndexHighPrice }]"
          >
            {{ $numeral(indexesLatest[".SET"].high).format("0,0.00") }}
          </div>
        </div>
        <div class="high-price-diff">
          <div v-if="$numeral(indexesLatest['.SET'].high).value() === 0" ref="setIndexHighPriceDiff" class="ui grey header high-info-label">-</div>
          <div
            v-else
            ref="setIndexHighPriceDiff"
            class="ui header high-info-label"
            :class="[getClassPriceColor(indexesLatest['.SET'].high, indexesLatest['.SET'].prior), { blink: componentsClass.setIndexHighPriceDiff }]"
          >
            {{
              ($numeral(indexesLatest[".SET"].high).value() > $numeral(indexesLatest[".SET"].prior).value() ? "+" : "") +
              $numeral(indexesLatest[".SET"].high - indexesLatest[".SET"].prior).format("0,0.00")
            }}
          </div>
        </div>
      </div>
      <div class="set-low-price-info">
        <div class="ui grey header low-info-label title" :class="{ inverted: theme === 'Dark' }">{{ "Low" }}</div>
        <div class="low-price-container">
          <div v-if="$numeral(indexesLatest['.SET'].low).value() === 0" ref="setIndexLowPrice" class="ui grey header low-info-label">-</div>
          <div
            v-else
            ref="setIndexLowPrice"
            class="ui header low-info-label"
            :class="[getClassPriceColor(indexesLatest['.SET'].low, indexesLatest['.SET'].prior), { blink: componentsClass.setIndexLowPrice }]"
          >
            {{ $numeral(indexesLatest[".SET"].low).format("0,0.00") }}
          </div>
        </div>
        <div class="low-price-diff-container">
          <div v-if="$numeral(indexesLatest['.SET'].low).value() === 0" ref="setIndexLowPriceDiff" class="ui grey header low-info-label">-</div>
          <div
            v-else
            ref="setIndexLowPriceDiff"
            class="ui header low-info-label"
            :class="[getClassPriceColor(indexesLatest['.SET'].low, indexesLatest['.SET'].prior), { blink: componentsClass.setIndexLowPriceDiff }]"
          >
            {{
              ($numeral(indexesLatest[".SET"].low).value() > $numeral(indexesLatest[".SET"].prior).value() ? "+" : "") +
              $numeral(indexesLatest[".SET"].low - indexesLatest[".SET"].prior).format("0,0.00")
            }}
          </div>
        </div>
      </div>
      <div class="set-index-gainer-loser-container">
        <div class="set-index-loser-container">
          <i class="caret down red icon" />
          <div ref="setIndexLosers" class="ui tiny red header set-index-loser" :class="[{ blink: componentsClass.setIndexLosers }]">
            {{ marketsLatest["SET"].down || 0 }}
          </div>
        </div>
        <div class="set-index-unchanges-container">
          <i class="sort rotated yellow icon" style="line-height: 1rem" />
          <div ref="setIndexUnchanges" class="ui tiny yellow header set-index-unchanges" :class="[{ blink: componentsClass.setIndexUnchanges }]">
            {{ marketsLatest["SET"].unchange || 0 }}
          </div>
        </div>
        <div class="set-index-gainer-container">
          <i class="caret up green icon" />
          <div ref="setIndexGainers" class="ui tiny green header set-index-gainer" :class="[{ blink: componentsClass.setIndexGainers }]">
            {{ marketsLatest["SET"].up || 0 }}
          </div>
        </div>
      </div>
      <div class="marquee-set-info">
        <marquee-text class="marquee-text" :duration="indexMarquee.length * 4">
          <div class="marquee-set-container">
            <div v-for="(index, key) in indexMarquee" :key="key" class="marquee-set">
              <div class="ui grey tiny header marquee-label" :class="{ inverted: theme === 'Dark' }">
                {{ SETIndexNameFormatter(index.name) }}
              </div>
              <div class="icon-container">
                <i v-if="$numeral(index.last).value() === 0" class="sort rotated yellow icon" style="line-height: 1rem" />
                <i v-else-if="$numeral(index.last).value() > $numeral(index.prior).value()" class="caret up green icon" />
                <i v-else-if="$numeral(index.last).value() < $numeral(index.prior).value()" class="caret down red icon" />
                <i v-else class="sort rotated yellow icon" style="line-height: 1rem" />
              </div>
              <div class="ui tiny header marquee-label" :class="getClassPriceColor(index.last, index.prior)">
                {{ $numeral(index.last).value() === 0 ? $numeral(index.prior).format("0,0.00") : $numeral(index.last).format("0,0.00") }}
              </div>
              <div class="ui tiny header marquee-label" :class="getClassPriceColor(index.last, index.prior)">
                {{
                  $numeral(index.last).value() === 0
                    ? "0.00"
                    : ($numeral(index.last).value() > $numeral(index.prior).value() ? "+" : "") + $numeral(index.last - index.prior).format("0,0.00")
                }}
              </div>
              <div class="ui tiny header marquee-label" :class="getClassPriceColor(index.last, index.prior)">
                {{
                  $numeral(index.last).value() === 0
                    ? "0.00%"
                    : ($numeral(index.last).value() > $numeral(index.prior).value() ? "+" : "") +
                      $numeral(((index.last - index.prior) / index.prior) * 100).format("0,0.00") +
                      "%"
                }}
              </div>
              <div class="ui grey tiny header marquee-label" :class="{ inverted: theme === 'Dark' }">{{ "Value" }}</div>

              <div class="ui yellow tiny header marquee-label">
                {{ `${$numeral(index.value / 1000000).format("0,0.00")} MB` }}
              </div>
            </div>
          </div>
        </marquee-text>
      </div>
      <Popover trigger="hover" :disabled="!marketStatusList?.length" popper-class="market-status-popper">
        <div class="set-status" slot="reference" v-if="othersMarket">
          <div class="ui grey header status-label" :class="{ inverted: theme === 'Dark' }">
            {{ othersMarket.market }}
          </div>
          <div class="ui grey header seperator" :class="{ inverted: theme === 'Dark' }">{{ ":" }}</div>
          <div class="ui yellow header status-label">
            {{ othersMarket.marketInfo.mktStCode || "-" }}
          </div>
        </div>
        <div class="market-status-list-container">
          <div class="market-status-list">
            <template v-for="marketStatus in marketStatusList">
              <div class="ui grey header" :class="{ inverted: theme === 'Dark' }" :key="`market-status-for-${marketStatus.market}`">
                {{ marketStatus.market }}
              </div>
              <div class="ui yellow header" :key="`market-status-for-${marketStatus.market}-status`">{{ marketStatus.status }}</div>
            </template>
          </div>
        </div>
      </Popover>

      <div class="dts-info-section" v-if="isEnableDTS()">
        <TFEXDropdown :select-value="currentDtsMarketDropdown" :options="tfexOptions" @select-item="onSelectTFexDropdown" />
        <div ref="dtsMarketStatus" class="ui yellow header dts-label" :class="[{ blink: componentsClass.dtsMarketStatus }]">
          {{ dtsMarketsLatest && ($_.find(dtsMarketsLatest, ["name", currentDtsMarketDropdown]).mktStCode || "-") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import MarqueeText from "vue-marquee-text-component";
import { getMarketStatusByKey } from "@/utils/MarketStatusFormatter.js";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";
import SET50Dropdown from "../grid/index/SET50Dropdown.vue";
import TFEXDropdown from "../grid/index/TFEXDropdown.vue";
import { Popover } from "element-ui";

export default {
  name: "IndexBar",
  components: {
    MarqueeText,
    SET50Dropdown,
    TFEXDropdown,
    Popover,
  },
  data: () => ({
    indexesLatest: {
      ".SET": {
        name: ".SET",
        prior: "",
        last: "",
        high: "",
        low: "",
        volume: "",
        value: "",
      },
      ".SET50": {
        name: ".SET50",
        prior: "",
        last: "",
        high: "",
        low: "",
        volume: "",
        value: "",
      },
    },
    marketsLatest: {
      SET: {
        name: "SET",
        mktStCode: "",
      },
    },
    dtsMarketsLatest: {
      TXI: {
        name: "TXI",
        mktStCode: "",
      },
    },
    currentIndexDropdown: ".SET50",
    currentDtsMarketDropdown: "TXI",
    componentsClass: {
      setIndex: false,
      setIndexChange: false,
      setIndexHighPrice: false,
      setIndexHighPriceDiff: false,
      setIndexVal: false,
      set50Index: false,
      set50IndexChange: false,
      set50IndexHighPrice: false,
      set50IndexLowPrice: false,
      set50IndexVal: false,
      set50IndexVol: false,
      marketStatus: false,
      setIndexPercentChange: false,
      setIndexLowPrice: false,
      setIndexLowPriceDiff: false,
      setIndexLosers: false,
      setIndexUnchanges: false,
      setIndexGainers: false,
      dtsMarketStatus: false,
    },
    othersMarketInterval: null,
    othersMarket: null,
    IR01RefId: "",
    isEnableETS,
    isEnableDTS,
  }),
  watch: {
    markets(markets) {
      this.initMarketsLatest(markets);
    },
    dtsMarkets(dtsMarkets) {
      this.initDtsMarketsLatest(dtsMarkets);
    },
    indexes(indexes) {
      this.initIndexesLatest(indexes);
    },
    marketsLatest(marketsLatest) {
      //Send it to other component
      const newMarkets = [];
      Object.keys(marketsLatest).forEach((key) => {
        const data = marketsLatest[key];
        newMarkets.push(data);
      });
      this.$store.set("model/systemRefData@markets", newMarkets);
    },
    dtsMarketsLatest(dtsMarketsLatest) {
      //Send it to other component
      const newMarkets = [];
      Object.keys(dtsMarketsLatest).forEach((key) => {
        const data = dtsMarketsLatest[key];
        newMarkets.push(data);
      });
      this.$store.set("model/systemRefData@dtsMarkets", newMarkets);
    },
  },
  created() {
    if (this.markets) {
      this.initMarketsLatest(this.markets);
    }
    if (this.dtsMarkets) {
      this.initDtsMarketsLatest(this.dtsMarkets);
    }
    if (this.indexes) {
      this.initIndexesLatest(this.indexes);
    }

    /* Subscribe all */
    if (this.isEnableETS()) {
      this.XR03("IB01"); /* IndexUpdateBcst */
      this.XR03("MB01"); /* MarketStateUpdateBcst */
      this.XR03("MB02"); /* MarketStatisticUpdateBcst */
      this.IR01();
    }

    if (this.isEnableDTS()) {
      this.XR03("IB51"); /* DtsIndexUpdateBcst */
      this.XR03("MB51"); /* DtsMarketStateUpdateBcst */
      this.XR03("MB52"); /* DtsMarketStatisticUpdateBcst */
    }

    this.changeDisplayedOtherMarketStatus();

    this.othersMarketInterval = setInterval(() => {
      this.changeDisplayedOtherMarketStatus();
    }, 5000);

    this.$EventBus.$on("bcst/MB01", this.updateMarketsLatest);
    this.$EventBus.$on("bcst/MB51", this.updateDtsMarketsLatest);
    this.$EventBus.$on("bcst/MB02", this.updateMarketsLatest);
    this.$EventBus.$on("bcst/IB01", this.updateIndexesLatest);
    this.$EventBus.$on("ctinf/IS01", this.updateSetIndex);
  },

  beforeDestroy() {
    this.$EventBus.$off("bcst/MB01", this.updateMarketsLatest);
    this.$EventBus.$off("bcst/MB51", this.updateDtsMarketsLatest);
    this.$EventBus.$off("bcst/MB02", this.updateMarketsLatest);
    this.$EventBus.$off("bcst/IB01", this.updateIndexesLatest);
    this.$EventBus.$off("ctinf/IS01", this.updateSetIndex);

    if (this.othersMarketInterval) {
      clearInterval(this.othersMarketInterval);
    }
  },
  methods: {
    updateSetIndex(data) {
      if (data.refId === this.IR01RefId) this.indexes = data.indexes;
    },
    IR01() {
      const msg = this.$messageFactory.createMessage("IR01");

      msg.indexType.set("1");
      msg.marketId.set("");
      msg.tradingDate.set(this.$moment(this.selectedDate).format("DD/MM/YYYY"));
      this.IR01RefId = this.$shortid.generate();
      this.$ws.send(msg, this.IR01RefId);
    },

    onSelectDropdownItem(item) {
      this.currentIndexDropdown = item;
    },
    onSelectTFexDropdown(name) {
      this.currentDtsMarketDropdown = name;
    },

    initMarketsLatest(markets) {
      if (markets?.length) {
        markets.forEach((market) => {
          this.marketsLatest[market.name] = market;
        });
      }
    },
    initDtsMarketsLatest(dtsMarkets) {
      if (dtsMarkets?.length) {
        dtsMarkets.forEach((market) => {
          this.dtsMarketsLatest[market.name] = market;
        });
      }
    },
    initIndexesLatest(indexes) {
      if (indexes?.length) {
        indexes.forEach((index) => {
          this.indexesLatest[index.name] = index;
        });
      }
    },
    getClassPriceColor(current, past) {
      current = this.$numeral(current).value();
      past = this.$numeral(past).value();

      if (current === 0) {
        return "yellow";
      }

      if (current > past) {
        return "green";
      } else if (current < past) {
        return "red";
      } else if (current === past) {
        return "yellow";
      }
    },
    blinkBackground(ref) {
      this.$refs[ref].classList.add("blink");
      setTimeout(() => {
        this.$refs[ref].classList.remove("blink");
      }, 250);
    },
    blinkText(ref, direction) {
      this.$refs[ref].classList.add(direction);
      setTimeout(() => {
        this.$refs[ref].classList.remove(direction);
      }, 500);
    },
    updateMarketsLatest(update, doWorker) {
      const market = this.$_.find(this.marketsLatest, ["marketId", update.marketId]);
      if (!market) return;
      const name = market.name;

      if (name === "SET") {
        if (update.mktStCode && update.mktStCode !== this.marketsLatest["SET"].mktStCode) {
          this.blinkBackground("marketStatus");
        }

        if (update.down !== this.marketsLatest["SET"].down) {
          this.blinkBackground("setIndexGainers");
        }

        if (update.unchange !== this.marketsLatest["SET"].unchange) {
          this.blinkBackground("setIndexGainers");
        }

        if (update.up !== this.marketsLatest["SET"].up) {
          this.blinkBackground("setIndexGainers");
        }
      }

      this.$set(this.marketsLatest, name, { ...this.marketsLatest[name], ...update });
      this.marketsLatest = JSON.parse(JSON.stringify(this.marketsLatest));

      if (this.indexesLatest["." + name]?.value !== update.totalMktVal) {
        this.blinkBackground("setIndexVal");
      }
      this.$set(this.indexesLatest, "." + name, { ...this.indexesLatest["." + name], value: update.totalMktVal });
    },
    updateDtsMarketsLatest(update, doWorker) {
      const market = this.$_.find(this.dtsMarketsLatest, ["marketId", update.marketId]);
      if (!market) return;
      const name = market.name;

      if (name === this.currentDtsMarketDropdown) {
        if (update.mktStCode && update.mktStCode !== this.dtsMarketsLatest[this.currentDtsMarketDropdown].mktStCode) {
          this.blinkBackground("dtsMarketStatus");
        }
      }

      this.$set(this.dtsMarketsLatest, name, { ...this.dtsMarketsLatest[name], ...update });
      this.$set(this.indexesLatest, "." + name, { ...this.indexesLatest["." + name], value: update.totalMktVal });
    },
    updateIndexesLatest(update, doWorker) {
      const index = this.$_.find(this.indexesLatest, ["indexId", update.indexId]);

      if (!index) return;

      const name = index.name;

      if (name === ".SET") {
        if (update.last !== this.indexesLatest[".SET"].last) {
          const indexRefs = ["setIndex", "setIndexChange", "setIndexPercentChange"];
          indexRefs.forEach((ref) => {
            this.blinkBackground(ref);
          });
        }

        if (update.high !== this.indexesLatest[".SET"].high) {
          const highPriceRefs = ["setIndexHighPrice", "setIndexHighPriceDiff"];
          highPriceRefs.forEach((ref) => {
            this.blinkBackground(ref);
          });
        }

        if (update.low !== this.indexesLatest[".SET"].low) {
          const lowPriceRefs = ["setIndexLowPrice", "setIndexLowPriceDiff"];
          lowPriceRefs.forEach((ref) => {
            this.blinkBackground(ref);
          });
        }
      } else if (name === this.currentIndexDropdown) {
        if (update.last !== this.indexesLatest[this.currentIndexDropdown].last) {
          const indexRefs = ["set50Index", "set50IndexChange"];
          indexRefs.forEach((ref) => {
            this.blinkBackground(ref);
          });
        }

        if (update.high !== this.indexesLatest[this.currentIndexDropdown].high) {
          const highPriceRefs = ["set50IndexHighPrice"];
          highPriceRefs.forEach((ref) => {
            this.blinkBackground(ref);
          });
        }

        if (update.low !== this.indexesLatest[this.currentIndexDropdown].low) {
          const lowPriceRefs = ["set50IndexLowPrice"];
          lowPriceRefs.forEach((ref) => {
            this.blinkBackground(ref);
          });
        }

        if (update.volume !== this.indexesLatest[this.currentIndexDropdown].volume) {
          this.blinkBackground("set50IndexVol");
        }
      }
      this.indexesLatest[name] = { ...this.indexesLatest[name], ...update };
      if (name === ".SET" || name === ".MAI") {
        this.indexesLatest[name] = { ...this.indexesLatest[name], value: this.marketsLatest[name.replace(".", "")].totalMktVal };
      }
    },
    SETIndexNameFormatter(name) {
      switch (name) {
        case ".MAI":
          return ".mai";
        case ".SSET":
          return ".sSET";
        default:
          return name;
      }
    },
    TFEXMarketNameFormatter(name) {
      switch (name) {
        case "TXI":
          return "Equity Index";
        case "TXS":
          return "Single Stock";
        case "TXR":
          return "Interest Rate";
        case "TXM":
          return "Metal";
        case "TXE":
          return "Energy";
        case "TXC":
          return "Currency";
        case "TXA":
          return "Aqricultural";
        case "TXD":
          return "Deferred";
        default:
          return name;
      }
    },
    getMarketStatusByKey,
    XR03(messageCode) {
      const msg = this.$messageFactory.createMessage("XR03");

      msg.messageCode.set(messageCode);

      this.$ws.send(msg);
    },
    changeDisplayedOtherMarketStatus() {
      const marketList = Object.entries(this.marketsLatest);

      if (!marketList.length) return;

      const displayMarketIndex = marketList.findIndex(([market]) => market === this.othersMarket?.market);

      if (displayMarketIndex == marketList.length - 1 || displayMarketIndex < 0) {
        this.othersMarket = { market: marketList[0][0], marketInfo: marketList[0][1] };
      } else {
        this.othersMarket = { market: marketList[displayMarketIndex + 1][0], marketInfo: marketList[displayMarketIndex + 1][1] };
      }

      console.log("test other market", this.othersMarket);
    },
  },
  computed: {
    indexMarquee() {
      return this.$_.filter(this.indexesLatest, (index) => {
        return index.name !== ".SET";
      });
    },
    tfexOptions() {
      return this.$_.toArray(this.dtsMarketsLatest);
    },
    marketStatusList() {
      const marketList = Object.entries(this.marketsLatest) || [];

      if (!marketList.length) return [];

      const resolvedList = marketList.map(([market, marketInfo]) => ({ market: market, status: marketInfo.mktStCode }));

      return resolvedList;
    },

    theme: sync("global/theme"),
    markets: get("model/systemRefData@markets"),
    dtsMarkets: get("model/systemRefData@dtsMarkets"),
    indexes: sync("model/systemRefData@indexes"),
    nightMode: get("model/systemRefData@nightMode"),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/main-layout/index-bar.scss";
</style>
