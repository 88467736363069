/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class BR52 extends WsReqMsg {
	constructor() {
		super(1);
		this._ownTid = new StringMessageBase();
		this._reqTid = new StringMessageBase();
		this._viewAs = new StringMessageBase();
	}
	
	get ownTid() {
		return this._ownTid;
	}
	
	set ownTid(ownTid) {
		this._ownTid = new StringMessageBase(ownTid);
	}
	
	get reqTid() {
		return this._reqTid;
	}
	
	set reqTid(reqTid) {
		this._reqTid = new StringMessageBase(reqTid);
	}
	
	get viewAs() {
		return this._viewAs;
	}
	
	set viewAs(viewAs) {
		this._viewAs = new StringMessageBase(viewAs);
	}
}

export default BR52;
