/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class CR53 extends WsReqMsg {
	constructor() {
		super(1);
		this._ownTid = new StringMessageBase();
		this._sec = new StringMessageBase();
		this._reqTid = new StringMessageBase();
		this._trustId = new StringMessageBase();
		this._posiType = new StringMessageBase();
		this._recordCount = new NumberMessageBase();
		this._lastRecordId = new NumberMessageBase();
		this._lastCustId = new StringMessageBase();
	}
	
	get ownTid() {
		return this._ownTid;
	}
	
	set ownTid(ownTid) {
		this._ownTid = new StringMessageBase(ownTid);
	}
	
	get sec() {
		return this._sec;
	}
	
	set sec(sec) {
		this._sec = new StringMessageBase(sec);
	}
	
	get reqTid() {
		return this._reqTid;
	}
	
	set reqTid(reqTid) {
		this._reqTid = new StringMessageBase(reqTid);
	}
	
	get trustId() {
		return this._trustId;
	}
	
	set trustId(trustId) {
		this._trustId = new StringMessageBase(trustId);
	}
	
	get posiType() {
		return this._posiType;
	}
	
	set posiType(posiType) {
		this._posiType = new StringMessageBase(posiType);
	}
	
	get recordCount() {
		return this._recordCount;
	}
	
	set recordCount(recordCount) {
		this._recordCount = new NumberMessageBase(recordCount);
	}
	
	get lastRecordId() {
		return this._lastRecordId;
	}
	
	set lastRecordId(lastRecordId) {
		this._lastRecordId = new NumberMessageBase(lastRecordId);
	}
	
	get lastCustId() {
		return this._lastCustId;
	}
	
	set lastCustId(lastCustId) {
		this._lastCustId = new StringMessageBase(lastCustId);
	}
}

export default CR53;
