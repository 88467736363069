/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class ON53 extends WsMsg {
	constructor() {
		super(3);
		this._orderId = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._side = new StringMessageBase();
		this._matchedVol = new StringMessageBase();
		this._publishedVol = new StringMessageBase();
		this._dealVol = new StringMessageBase();
		this._dealPrice = new StringMessageBase();
		this._status = new StringMessageBase();
		this._priceFlag = new StringMessageBase();
		this._specialOrderType = new StringMessageBase();
		this._price = new StringMessageBase();
		this._accountCode = new StringMessageBase();
		this._sendDate = new StringMessageBase();
		this._decimalPoint = new StringMessageBase();
	}
	
	get orderId() {
		return this._orderId;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get side() {
		return this._side;
	}
	
	get matchedVol() {
		return this._matchedVol;
	}
	
	get publishedVol() {
		return this._publishedVol;
	}
	
	get dealVol() {
		return this._dealVol;
	}
	
	get dealPrice() {
		return this._dealPrice;
	}
	
	get status() {
		return this._status;
	}
	
	get priceFlag() {
		return this._priceFlag;
	}
	
	get specialOrderType() {
		return this._specialOrderType;
	}
	
	get price() {
		return this._price;
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	get sendDate() {
		return this._sendDate;
	}
	
	get decimalPoint() {
		return this._decimalPoint;
	}
}

export default ON53;
