/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class XS02 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._messages = new ArrayMessageBase(Message);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get messages() {
		return this._messages;
	}
}

export class Message {
	constructor() {
		this._time = new StringMessageBase();
		this._message = new StringMessageBase();
	}
	
	get time() {
		return this._time;
	}
	
	get message() {
		return this._message;
	}
}

export default XS02;
