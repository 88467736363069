/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class CS52 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._records = new ArrayMessageBase(Record);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get records() {
		return this._records;
	}
}

export class Record {
	constructor() {
		this._crdtBalRec = new NumberMessageBase();
		this._custId = new StringMessageBase();
		this._custName = new StringMessageBase();
		this._ownTid = new StringMessageBase();
		this._ownTidName = new StringMessageBase();
		this._cashBal = new StringMessageBase();
		this._unrealPl = new StringMessageBase();
		this._floatPl = new StringMessageBase();
		this._EE = new StringMessageBase();
		this._MB = new StringMessageBase();
		this._unrealMB = new StringMessageBase();
		this._equityBal = new StringMessageBase();
		this._MMVal = new StringMessageBase();
		this._force = new StringMessageBase();
		this._forceCallValue = new StringMessageBase();
	}
	
	get crdtBalRec() {
		return this._crdtBalRec;
	}
	
	get custId() {
		return this._custId;
	}
	
	get custName() {
		return this._custName;
	}
	
	get ownTid() {
		return this._ownTid;
	}
	
	get ownTidName() {
		return this._ownTidName;
	}
	
	get cashBal() {
		return this._cashBal;
	}
	
	get unrealPl() {
		return this._unrealPl;
	}
	
	get floatPl() {
		return this._floatPl;
	}
	
	get EE() {
		return this._EE;
	}
	
	get MB() {
		return this._MB;
	}
	
	get unrealMB() {
		return this._unrealMB;
	}
	
	get equityBal() {
		return this._equityBal;
	}
	
	get MMVal() {
		return this._MMVal;
	}
	
	get force() {
		return this._force;
	}
	
	get forceCallValue() {
		return this._forceCallValue;
	}
}

export default CS52;
