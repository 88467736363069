/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class OR51 extends WsReqMsg {
	constructor() {
		super(0);
		this._symbol = new StringMessageBase();
		this._side = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._price = new StringMessageBase();
		this._accountCode = new StringMessageBase();
		this._icebergVol = new StringMessageBase();
		this._positionType = new StringMessageBase();
		this._pinCode = new StringMessageBase();
		this._validity = new StringMessageBase();
		this._validityData = new StringMessageBase();
		this._specialOrderType = new StringMessageBase();
		this._stopSymbol = new StringMessageBase();
		this._stopCondition = new StringMessageBase();
		this._stopPrice = new StringMessageBase();
	}
	
	get symbol() {
		return this._symbol;
	}
	
	set symbol(symbol) {
		this._symbol = new StringMessageBase(symbol);
	}
	
	get side() {
		return this._side;
	}
	
	set side(side) {
		this._side = new StringMessageBase(side);
	}
	
	get volume() {
		return this._volume;
	}
	
	set volume(volume) {
		this._volume = new StringMessageBase(volume);
	}
	
	get price() {
		return this._price;
	}
	
	set price(price) {
		this._price = new StringMessageBase(price);
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get icebergVol() {
		return this._icebergVol;
	}
	
	set icebergVol(icebergVol) {
		this._icebergVol = new StringMessageBase(icebergVol);
	}
	
	get positionType() {
		return this._positionType;
	}
	
	set positionType(positionType) {
		this._positionType = new StringMessageBase(positionType);
	}
	
	get pinCode() {
		return this._pinCode;
	}
	
	set pinCode(pinCode) {
		this._pinCode = new StringMessageBase(pinCode);
	}
	
	get validity() {
		return this._validity;
	}
	
	set validity(validity) {
		this._validity = new StringMessageBase(validity);
	}
	
	get validityData() {
		return this._validityData;
	}
	
	set validityData(validityData) {
		this._validityData = new StringMessageBase(validityData);
	}
	
	get specialOrderType() {
		return this._specialOrderType;
	}
	
	set specialOrderType(specialOrderType) {
		this._specialOrderType = new StringMessageBase(specialOrderType);
	}
	
	get stopSymbol() {
		return this._stopSymbol;
	}
	
	set stopSymbol(stopSymbol) {
		this._stopSymbol = new StringMessageBase(stopSymbol);
	}
	
	get stopCondition() {
		return this._stopCondition;
	}
	
	set stopCondition(stopCondition) {
		this._stopCondition = new StringMessageBase(stopCondition);
	}
	
	get stopPrice() {
		return this._stopPrice;
	}
	
	set stopPrice(stopPrice) {
		this._stopPrice = new StringMessageBase(stopPrice);
	}
}

export default OR51;
