/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class BS56 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._totalBrkVol = new NumberMessageBase();
		this._totalBrkVal = new NumberMessageBase();
		this._name = new StringMessageBase();
		this._title = new StringMessageBase();
		this._approvalOver = new NumberMessageBase();
		this._level = new NumberMessageBase();
		this._supervisor = new StringMessageBase();
		this._value = new NumberMessageBase();
		this._commission = new NumberMessageBase();
		this._sides = new ArrayMessageBase(SideRecord);
		this._traders = new ArrayMessageBase(TraderRecord);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get totalBrkVol() {
		return this._totalBrkVol;
	}
	
	get totalBrkVal() {
		return this._totalBrkVal;
	}
	
	get name() {
		return this._name;
	}
	
	get title() {
		return this._title;
	}
	
	get approvalOver() {
		return this._approvalOver;
	}
	
	get level() {
		return this._level;
	}
	
	get supervisor() {
		return this._supervisor;
	}
	
	get value() {
		return this._value;
	}
	
	get commission() {
		return this._commission;
	}
	
	get sides() {
		return this._sides;
	}
	
	get traders() {
		return this._traders;
	}
}

export class SideRecord {
	constructor() {
		this._side = new StringMessageBase();
		this._vol = new NumberMessageBase();
		this._volBrkPct = new NumberMessageBase();
		this._val = new NumberMessageBase();
		this._valBrkPct = new NumberMessageBase();
		this._commission = new NumberMessageBase();
		this._commissionBrkPct = new NumberMessageBase();
	}
	
	get side() {
		return this._side;
	}
	
	get vol() {
		return this._vol;
	}
	
	get volBrkPct() {
		return this._volBrkPct;
	}
	
	get val() {
		return this._val;
	}
	
	get valBrkPct() {
		return this._valBrkPct;
	}
	
	get commission() {
		return this._commission;
	}
	
	get commissionBrkPct() {
		return this._commissionBrkPct;
	}
}

export class TraderRecord {
	constructor() {
		this._recordId = new NumberMessageBase();
		this._traderId = new StringMessageBase();
		this._level = new NumberMessageBase();
		this._title = new StringMessageBase();
		this._vol = new NumberMessageBase();
		this._volPct = new NumberMessageBase();
		this._val = new NumberMessageBase();
		this._valPct = new NumberMessageBase();
		this._commission = new NumberMessageBase();
		this._commissionPct = new NumberMessageBase();
	}
	
	get recordId() {
		return this._recordId;
	}
	
	get traderId() {
		return this._traderId;
	}
	
	get level() {
		return this._level;
	}
	
	get title() {
		return this._title;
	}
	
	get vol() {
		return this._vol;
	}
	
	get volPct() {
		return this._volPct;
	}
	
	get val() {
		return this._val;
	}
	
	get valPct() {
		return this._valPct;
	}
	
	get commission() {
		return this._commission;
	}
	
	get commissionPct() {
		return this._commissionPct;
	}
}

export default BS56;
