/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class OR07 extends WsReqMsg {
	constructor() {
		super(0);
		this._symbol = new StringMessageBase();
		this._side = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._price = new StringMessageBase();
		this._accountCode = new StringMessageBase();
		this._icebergVol = new StringMessageBase();
		this._condition = new StringMessageBase();
		this._ttf = new StringMessageBase();
		this._positionType = new StringMessageBase();
		this._pinCode = new StringMessageBase();
		this._specialOrderType = new StringMessageBase();
		this._advType1 = new StringMessageBase();
		this._advType2 = new StringMessageBase();
		this._advType3 = new StringMessageBase();
		this._condition1 = new StringMessageBase();
		this._condition2 = new StringMessageBase();
		this._condition3 = new StringMessageBase();
		this._operation = new StringMessageBase();
		this._advVal1 = new StringMessageBase();
		this._advVal2 = new StringMessageBase();
		this._advVal3 = new StringMessageBase();
		this._tickerSec1 = new StringMessageBase();
		this._tickerSec2 = new StringMessageBase();
		this._tickerSec3 = new StringMessageBase();
		this._refPrice1 = new StringMessageBase();
		this._refPrice2 = new StringMessageBase();
		this._refPrice3 = new StringMessageBase();
		this._advIntervalTime = new StringMessageBase();
		this._advStep = new StringMessageBase();
		this._validity = new StringMessageBase();
		this._validityData = new StringMessageBase();
	}
	
	get symbol() {
		return this._symbol;
	}
	
	set symbol(symbol) {
		this._symbol = new StringMessageBase(symbol);
	}
	
	get side() {
		return this._side;
	}
	
	set side(side) {
		this._side = new StringMessageBase(side);
	}
	
	get volume() {
		return this._volume;
	}
	
	set volume(volume) {
		this._volume = new StringMessageBase(volume);
	}
	
	get price() {
		return this._price;
	}
	
	set price(price) {
		this._price = new StringMessageBase(price);
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get icebergVol() {
		return this._icebergVol;
	}
	
	set icebergVol(icebergVol) {
		this._icebergVol = new StringMessageBase(icebergVol);
	}
	
	get condition() {
		return this._condition;
	}
	
	set condition(condition) {
		this._condition = new StringMessageBase(condition);
	}
	
	get ttf() {
		return this._ttf;
	}
	
	set ttf(ttf) {
		this._ttf = new StringMessageBase(ttf);
	}
	
	get positionType() {
		return this._positionType;
	}
	
	set positionType(positionType) {
		this._positionType = new StringMessageBase(positionType);
	}
	
	get pinCode() {
		return this._pinCode;
	}
	
	set pinCode(pinCode) {
		this._pinCode = new StringMessageBase(pinCode);
	}
	
	get specialOrderType() {
		return this._specialOrderType;
	}
	
	set specialOrderType(specialOrderType) {
		this._specialOrderType = new StringMessageBase(specialOrderType);
	}
	
	get advType1() {
		return this._advType1;
	}
	
	set advType1(advType1) {
		this._advType1 = new StringMessageBase(advType1);
	}
	
	get advType2() {
		return this._advType2;
	}
	
	set advType2(advType2) {
		this._advType2 = new StringMessageBase(advType2);
	}
	
	get advType3() {
		return this._advType3;
	}
	
	set advType3(advType3) {
		this._advType3 = new StringMessageBase(advType3);
	}
	
	get condition1() {
		return this._condition1;
	}
	
	set condition1(condition1) {
		this._condition1 = new StringMessageBase(condition1);
	}
	
	get condition2() {
		return this._condition2;
	}
	
	set condition2(condition2) {
		this._condition2 = new StringMessageBase(condition2);
	}
	
	get condition3() {
		return this._condition3;
	}
	
	set condition3(condition3) {
		this._condition3 = new StringMessageBase(condition3);
	}
	
	get operation() {
		return this._operation;
	}
	
	set operation(operation) {
		this._operation = new StringMessageBase(operation);
	}
	
	get advVal1() {
		return this._advVal1;
	}
	
	set advVal1(advVal1) {
		this._advVal1 = new StringMessageBase(advVal1);
	}
	
	get advVal2() {
		return this._advVal2;
	}
	
	set advVal2(advVal2) {
		this._advVal2 = new StringMessageBase(advVal2);
	}
	
	get advVal3() {
		return this._advVal3;
	}
	
	set advVal3(advVal3) {
		this._advVal3 = new StringMessageBase(advVal3);
	}
	
	get tickerSec1() {
		return this._tickerSec1;
	}
	
	set tickerSec1(tickerSec1) {
		this._tickerSec1 = new StringMessageBase(tickerSec1);
	}
	
	get tickerSec2() {
		return this._tickerSec2;
	}
	
	set tickerSec2(tickerSec2) {
		this._tickerSec2 = new StringMessageBase(tickerSec2);
	}
	
	get tickerSec3() {
		return this._tickerSec3;
	}
	
	set tickerSec3(tickerSec3) {
		this._tickerSec3 = new StringMessageBase(tickerSec3);
	}
	
	get refPrice1() {
		return this._refPrice1;
	}
	
	set refPrice1(refPrice1) {
		this._refPrice1 = new StringMessageBase(refPrice1);
	}
	
	get refPrice2() {
		return this._refPrice2;
	}
	
	set refPrice2(refPrice2) {
		this._refPrice2 = new StringMessageBase(refPrice2);
	}
	
	get refPrice3() {
		return this._refPrice3;
	}
	
	set refPrice3(refPrice3) {
		this._refPrice3 = new StringMessageBase(refPrice3);
	}
	
	get advIntervalTime() {
		return this._advIntervalTime;
	}
	
	set advIntervalTime(advIntervalTime) {
		this._advIntervalTime = new StringMessageBase(advIntervalTime);
	}
	
	get advStep() {
		return this._advStep;
	}
	
	set advStep(advStep) {
		this._advStep = new StringMessageBase(advStep);
	}
	
	get validity() {
		return this._validity;
	}
	
	set validity(validity) {
		this._validity = new StringMessageBase(validity);
	}
	
	get validityData() {
		return this._validityData;
	}
	
	set validityData(validityData) {
		this._validityData = new StringMessageBase(validityData);
	}
}

export default OR07;
