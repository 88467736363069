/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class CS53 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._totalAccount = new StringMessageBase();
		this._totalShares = new StringMessageBase();
		this._totalCostAmount = new StringMessageBase();
		this._totalCurrentValue = new StringMessageBase();
		this._totalUnrealPL = new StringMessageBase();
		this._records = new ArrayMessageBase(Record);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get totalAccount() {
		return this._totalAccount;
	}
	
	get totalShares() {
		return this._totalShares;
	}
	
	get totalCostAmount() {
		return this._totalCostAmount;
	}
	
	get totalCurrentValue() {
		return this._totalCurrentValue;
	}
	
	get totalUnrealPL() {
		return this._totalUnrealPL;
	}
	
	get records() {
		return this._records;
	}
}

export class Record {
	constructor() {
		this._posiRec = new StringMessageBase();
		this._custId = new StringMessageBase();
		this._vol = new StringMessageBase();
		this._avgPri = new StringMessageBase();
		this._sec = new StringMessageBase();
		this._totCashRecord = new StringMessageBase();
		this._totCashShares = new StringMessageBase();
		this._totPricePaid = new StringMessageBase();
		this._startShr = new StringMessageBase();
		this._buyShr = new StringMessageBase();
		this._selShr = new StringMessageBase();
		this._lstSale = new StringMessageBase();
		this._custName = new StringMessageBase();
		this._ownTid = new StringMessageBase();
		this._ownTidName = new StringMessageBase();
		this._shares = new StringMessageBase();
		this._currentValue = new StringMessageBase();
		this._unrealPL = new StringMessageBase();
		this._side = new StringMessageBase();
		this._sellableVol = new StringMessageBase();
		this._markAvg = new StringMessageBase();
	}
	
	get posiRec() {
		return this._posiRec;
	}
	
	get custId() {
		return this._custId;
	}
	
	get vol() {
		return this._vol;
	}
	
	get avgPri() {
		return this._avgPri;
	}
	
	get sec() {
		return this._sec;
	}
	
	get totCashRecord() {
		return this._totCashRecord;
	}
	
	get totCashShares() {
		return this._totCashShares;
	}
	
	get totPricePaid() {
		return this._totPricePaid;
	}
	
	get startShr() {
		return this._startShr;
	}
	
	get buyShr() {
		return this._buyShr;
	}
	
	get selShr() {
		return this._selShr;
	}
	
	get lstSale() {
		return this._lstSale;
	}
	
	get custName() {
		return this._custName;
	}
	
	get ownTid() {
		return this._ownTid;
	}
	
	get ownTidName() {
		return this._ownTidName;
	}
	
	get shares() {
		return this._shares;
	}
	
	get currentValue() {
		return this._currentValue;
	}
	
	get unrealPL() {
		return this._unrealPL;
	}
	
	get side() {
		return this._side;
	}
	
	get sellableVol() {
		return this._sellableVol;
	}
	
	get markAvg() {
		return this._markAvg;
	}
}

export default CS53;
