/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class AS05 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._commRate = new StringMessageBase();
		this._minCharge = new StringMessageBase();
		this._unitRate = new StringMessageBase();
		this._unitCharge = new StringMessageBase();
		this._otherRate = new StringMessageBase();
		this._otherCharge = new StringMessageBase();
		this._slipRate = new StringMessageBase();
		this._slipCharge = new StringMessageBase();
		this._dbtRate = new StringMessageBase();
		this._dbtCharge = new StringMessageBase();
		this._convRate = new StringMessageBase();
		this._convCharge = new StringMessageBase();
		this._optRate = new StringMessageBase();
		this._optCharge = new StringMessageBase();
		this._commSvts = new ArrayMessageBase(CommSvt);
		this._commGrps = new ArrayMessageBase(CommGrp);
		this._commTbls = new ArrayMessageBase(CommTbl);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get commRate() {
		return this._commRate;
	}
	
	get minCharge() {
		return this._minCharge;
	}
	
	get unitRate() {
		return this._unitRate;
	}
	
	get unitCharge() {
		return this._unitCharge;
	}
	
	get otherRate() {
		return this._otherRate;
	}
	
	get otherCharge() {
		return this._otherCharge;
	}
	
	get slipRate() {
		return this._slipRate;
	}
	
	get slipCharge() {
		return this._slipCharge;
	}
	
	get dbtRate() {
		return this._dbtRate;
	}
	
	get dbtCharge() {
		return this._dbtCharge;
	}
	
	get convRate() {
		return this._convRate;
	}
	
	get convCharge() {
		return this._convCharge;
	}
	
	get optRate() {
		return this._optRate;
	}
	
	get optCharge() {
		return this._optCharge;
	}
	
	get commSvts() {
		return this._commSvts;
	}
	
	get commGrps() {
		return this._commGrps;
	}
	
	get commTbls() {
		return this._commTbls;
	}
}

export class CommSvt {
	constructor() {
		this._serviceType = new StringMessageBase();
		this._commRate = new StringMessageBase();
		this._minCharge = new StringMessageBase();
	}
	
	get serviceType() {
		return this._serviceType;
	}
	
	get commRate() {
		return this._commRate;
	}
	
	get minCharge() {
		return this._minCharge;
	}
}

export class CommGrp {
	constructor() {
		this._commType = new StringMessageBase();
		this._minCharge = new StringMessageBase();
		this._tradingFee = new StringMessageBase();
		this._clearingFee = new StringMessageBase();
		this._atsFee = new StringMessageBase();
		this._securityGroup = new StringMessageBase();
		this._serviceType = new StringMessageBase();
		this._commFlag = new StringMessageBase();
		this._accumId = new StringMessageBase();
	}
	
	get commType() {
		return this._commType;
	}
	
	get minCharge() {
		return this._minCharge;
	}
	
	get tradingFee() {
		return this._tradingFee;
	}
	
	get clearingFee() {
		return this._clearingFee;
	}
	
	get atsFee() {
		return this._atsFee;
	}
	
	get securityGroup() {
		return this._securityGroup;
	}
	
	get serviceType() {
		return this._serviceType;
	}
	
	get commFlag() {
		return this._commFlag;
	}
	
	get accumId() {
		return this._accumId;
	}
}

export class CommTbl {
	constructor() {
		this._commType = new StringMessageBase();
		this._maxValue = new StringMessageBase();
		this._commRate = new StringMessageBase();
		this._minChargeFlag = new StringMessageBase();
	}
	
	get commType() {
		return this._commType;
	}
	
	get maxValue() {
		return this._maxValue;
	}
	
	get commRate() {
		return this._commRate;
	}
	
	get minChargeFlag() {
		return this._minChargeFlag;
	}
}

export default AS05;
