/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class DS01 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._records = new ArrayMessageBase(Record);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get records() {
		return this._records;
	}
}

export class Record {
	constructor() {
		this._recordId = new StringMessageBase();
		this._orderId = new StringMessageBase();
		this._dealId = new StringMessageBase();
		this._tradeId = new StringMessageBase();
		this._side = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._price = new StringMessageBase();
		this._time = new StringMessageBase();
		this._commVat = new StringMessageBase();
		this._confirmNo = new StringMessageBase();
		this._traderId = new StringMessageBase();
		this._ttf = new StringMessageBase();
		this._accountCode = new StringMessageBase();
		this._orderType = new StringMessageBase();
		this._serviceType = new StringMessageBase();
		this._origin = new StringMessageBase();
		this._status = new StringMessageBase();
		this._firm = new StringMessageBase();
		this._contractFirm = new StringMessageBase();
		this._controlKey = new StringMessageBase();
		this._ftt = new StringMessageBase();
		this._trcode = new StringMessageBase();
	}
	
	get recordId() {
		return this._recordId;
	}
	
	get orderId() {
		return this._orderId;
	}
	
	get dealId() {
		return this._dealId;
	}
	
	get tradeId() {
		return this._tradeId;
	}
	
	get side() {
		return this._side;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get volume() {
		return this._volume;
	}
	
	get price() {
		return this._price;
	}
	
	get time() {
		return this._time;
	}
	
	get commVat() {
		return this._commVat;
	}
	
	get confirmNo() {
		return this._confirmNo;
	}
	
	get traderId() {
		return this._traderId;
	}
	
	get ttf() {
		return this._ttf;
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	get orderType() {
		return this._orderType;
	}
	
	get serviceType() {
		return this._serviceType;
	}
	
	get origin() {
		return this._origin;
	}
	
	get status() {
		return this._status;
	}
	
	get firm() {
		return this._firm;
	}
	
	get contractFirm() {
		return this._contractFirm;
	}
	
	get controlKey() {
		return this._controlKey;
	}
	
	get ftt() {
		return this._ftt;
	}
	
	get trcode() {
		return this._trcode;
	}
}

export default DS01;
