/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class AR07 extends WsReqMsg {
	constructor() {
		super(1);
		this._accountCode = new StringMessageBase();
		this._option = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._headerAccInfo = new StringMessageBase();
		this._exchangeId = new NumberMessageBase();
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get option() {
		return this._option;
	}
	
	set option(option) {
		this._option = new StringMessageBase(option);
	}
	
	get symbol() {
		return this._symbol;
	}
	
	set symbol(symbol) {
		this._symbol = new StringMessageBase(symbol);
	}
	
	get headerAccInfo() {
		return this._headerAccInfo;
	}
	
	set headerAccInfo(headerAccInfo) {
		this._headerAccInfo = new StringMessageBase(headerAccInfo);
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	set exchangeId(exchangeId) {
		this._exchangeId = new NumberMessageBase(exchangeId);
	}
}

export default AR07;
