/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class XS04 extends WsResMsg {
	constructor() {
		super(3);
		this._resultCode = new NumberMessageBase();
		this._sourceChannelId = new NumberMessageBase();
		this._time = new NumberMessageBase();
	}
    
    fromSeparatedValuesString(data) {
    	super.fromSeparatedValuesString(data);
    	super.channelId = parseInt(this._sourceChannelId.get());
    }
	
	get resultCode() {
		return this._resultCode;
	}
	
	get sourceChannelId() {
		return this._sourceChannelId;
	}
	
	get time() {
		return this._time;
	}
}

export default XS04;
