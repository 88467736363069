/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class SB51 extends WsMsg {
	constructor() {
		super(2);
		this._securityId = new NumberMessageBase();
		this._ceiling = new StringMessageBase();
		this._floor = new StringMessageBase();
		this._prevSettlement = new StringMessageBase();
		this._flag = new StringMessageBase();
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get ceiling() {
		return this._ceiling;
	}
	
	get floor() {
		return this._floor;
	}
	
	get prevSettlement() {
		return this._prevSettlement;
	}
	
	get flag() {
		return this._flag;
	}
}

export default SB51;
