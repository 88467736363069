/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class SS09 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._securityId = new NumberMessageBase();
		this._exchangeId = new NumberMessageBase();
		this._buyVol = new StringMessageBase();
		this._sellVol = new StringMessageBase();
		this._openVol = new StringMessageBase();
		this._sectorBuyVol = new StringMessageBase();
		this._sectorSellVol = new StringMessageBase();
		this._sectorVol = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	get buyVol() {
		return this._buyVol;
	}
	
	get sellVol() {
		return this._sellVol;
	}
	
	get openVol() {
		return this._openVol;
	}
	
	get sectorBuyVol() {
		return this._sectorBuyVol;
	}
	
	get sectorSellVol() {
		return this._sectorSellVol;
	}
	
	get sectorVol() {
		return this._sectorVol;
	}
}

export default SS09;
