/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class ON02 extends WsMsg {
	constructor() {
		super(3);
		this._orderId = new StringMessageBase();
		this._quote = new StringMessageBase();
		this._approved = new StringMessageBase();
		this._status = new StringMessageBase();
		this._specialOrderType = new StringMessageBase();
		this._sendDate = new StringMessageBase();
	}
	
	get orderId() {
		return this._orderId;
	}
	
	get quote() {
		return this._quote;
	}
	
	get approved() {
		return this._approved;
	}
	
	get status() {
		return this._status;
	}
	
	get specialOrderType() {
		return this._specialOrderType;
	}
	
	get sendDate() {
		return this._sendDate;
	}
}

export default ON02;
