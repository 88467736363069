/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class SR03 extends WsReqMsg {
	constructor() {
		super(1);
		this._securityIds = new StringMessageBase();
		this._listType = new StringMessageBase();
		this._listId = new StringMessageBase();
		this._recordCount = new NumberMessageBase();
		this._lastRecordId = new StringMessageBase();
		this._securityTypes = new StringMessageBase();
		this._exchangeId = new NumberMessageBase();
		this._listGroup = new StringMessageBase();
	}
	
	get securityIds() {
		return this._securityIds;
	}
	
	set securityIds(securityIds) {
		this._securityIds = new StringMessageBase(securityIds);
	}
	
	get listType() {
		return this._listType;
	}
	
	set listType(listType) {
		this._listType = new StringMessageBase(listType);
	}
	
	get listId() {
		return this._listId;
	}
	
	set listId(listId) {
		this._listId = new StringMessageBase(listId);
	}
	
	get recordCount() {
		return this._recordCount;
	}
	
	set recordCount(recordCount) {
		this._recordCount = new NumberMessageBase(recordCount);
	}
	
	get lastRecordId() {
		return this._lastRecordId;
	}
	
	set lastRecordId(lastRecordId) {
		this._lastRecordId = new StringMessageBase(lastRecordId);
	}
	
	get securityTypes() {
		return this._securityTypes;
	}
	
	set securityTypes(securityTypes) {
		this._securityTypes = new StringMessageBase(securityTypes);
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	set exchangeId(exchangeId) {
		this._exchangeId = new NumberMessageBase(exchangeId);
	}
	
	get listGroup() {
		return this._listGroup;
	}
	
	set listGroup(listGroup) {
		this._listGroup = new StringMessageBase(listGroup);
	}
}

export default SR03;
