/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class OR01 extends WsReqMsg {
	constructor() {
		super(0);
		this._symbol = new StringMessageBase();
		this._side = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._price = new StringMessageBase();
		this._accountCode = new StringMessageBase();
		this._icebergVol = new StringMessageBase();
		this._condition = new StringMessageBase();
		this._ttf = new StringMessageBase();
		this._positionType = new StringMessageBase();
		this._pinCode = new StringMessageBase();
		this._validity = new StringMessageBase();
		this._validityData = new StringMessageBase();
		this._strategyId = new StringMessageBase();
	}
	
	get symbol() {
		return this._symbol;
	}
	
	set symbol(symbol) {
		this._symbol = new StringMessageBase(symbol);
	}
	
	get side() {
		return this._side;
	}
	
	set side(side) {
		this._side = new StringMessageBase(side);
	}
	
	get volume() {
		return this._volume;
	}
	
	set volume(volume) {
		this._volume = new StringMessageBase(volume);
	}
	
	get price() {
		return this._price;
	}
	
	set price(price) {
		this._price = new StringMessageBase(price);
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get icebergVol() {
		return this._icebergVol;
	}
	
	set icebergVol(icebergVol) {
		this._icebergVol = new StringMessageBase(icebergVol);
	}
	
	get condition() {
		return this._condition;
	}
	
	set condition(condition) {
		this._condition = new StringMessageBase(condition);
	}
	
	get ttf() {
		return this._ttf;
	}
	
	set ttf(ttf) {
		this._ttf = new StringMessageBase(ttf);
	}
	
	get positionType() {
		return this._positionType;
	}
	
	set positionType(positionType) {
		this._positionType = new StringMessageBase(positionType);
	}
	
	get pinCode() {
		return this._pinCode;
	}
	
	set pinCode(pinCode) {
		this._pinCode = new StringMessageBase(pinCode);
	}
	
	get validity() {
		return this._validity;
	}
	
	set validity(validity) {
		this._validity = new StringMessageBase(validity);
	}
	
	get validityData() {
		return this._validityData;
	}
	
	set validityData(validityData) {
		this._validityData = new StringMessageBase(validityData);
	}
	
	get strategyId() {
		return this._strategyId;
	}
	
	set strategyId(strategyId) {
		this._strategyId = new StringMessageBase(strategyId);
	}
}

export default OR01;
