/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class XN01 extends WsMsg {
	constructor() {
		super(3);
		this._isNightMode = new StringMessageBase();
		this._bussinessDate = new StringMessageBase();
	}
	
	get isNightMode() {
		return this._isNightMode;
	}
	
	get bussinessDate() {
		return this._bussinessDate;
	}
}

export default XN01;
