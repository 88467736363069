/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class SS51 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._securityId = new NumberMessageBase();
		this._marketId = new NumberMessageBase();
		this._symbol = new StringMessageBase();
		this._spread = new StringMessageBase();
		this._flag = new StringMessageBase();
		this._prior = new StringMessageBase();
		this._ceiling = new StringMessageBase();
		this._floor = new StringMessageBase();
		this._open1 = new StringMessageBase();
		this._open2 = new StringMessageBase();
		this._high = new StringMessageBase();
		this._low = new StringMessageBase();
		this._last = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._value = new StringMessageBase();
		this._average = new StringMessageBase();
		this._projectedPrice = new StringMessageBase();
		this._projectedVolume = new StringMessageBase();
		this._projectedType = new StringMessageBase();
		this._change = new StringMessageBase();
		this._changePercent = new StringMessageBase();
		this._lastTradeDate = new StringMessageBase();
		this._prevOI = new StringMessageBase();
		this._contractSize = new StringMessageBase();
		this._tickSize = new StringMessageBase();
		this._decInPrice = new StringMessageBase();
		this._open0 = new StringMessageBase();
		this._settlementPrice = new StringMessageBase();
		this._prevSettlePrice = new StringMessageBase();
		this._underlying = new StringMessageBase();
		this._basis = new StringMessageBase();
		this._name = new StringMessageBase();
		this._averageLong = new StringMessageBase();
		this._averageShort = new StringMessageBase();
		this._longVolume = new StringMessageBase();
		this._shortVolume = new StringMessageBase();
		this._mktStCode = new StringMessageBase();
		this._bid1 = new StringMessageBase();
		this._bid2 = new StringMessageBase();
		this._bid3 = new StringMessageBase();
		this._bid4 = new StringMessageBase();
		this._bid5 = new StringMessageBase();
		this._offer1 = new StringMessageBase();
		this._offer2 = new StringMessageBase();
		this._offer3 = new StringMessageBase();
		this._offer4 = new StringMessageBase();
		this._offer5 = new StringMessageBase();
		this._bidVol1 = new StringMessageBase();
		this._bidVol2 = new StringMessageBase();
		this._bidVol3 = new StringMessageBase();
		this._bidVol4 = new StringMessageBase();
		this._bidVol5 = new StringMessageBase();
		this._offerVol1 = new StringMessageBase();
		this._offerVol2 = new StringMessageBase();
		this._offerVol3 = new StringMessageBase();
		this._offerVol4 = new StringMessageBase();
		this._offerVol5 = new StringMessageBase();
		this._trades = new ArrayMessageBase(Trade);
		this._exchangeId = new NumberMessageBase();
		this._expDate = new StringMessageBase();
		this._multiplier = new StringMessageBase();
		this._securityType = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get marketId() {
		return this._marketId;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get spread() {
		return this._spread;
	}
	
	get flag() {
		return this._flag;
	}
	
	get prior() {
		return this._prior;
	}
	
	get ceiling() {
		return this._ceiling;
	}
	
	get floor() {
		return this._floor;
	}
	
	get open1() {
		return this._open1;
	}
	
	get open2() {
		return this._open2;
	}
	
	get high() {
		return this._high;
	}
	
	get low() {
		return this._low;
	}
	
	get last() {
		return this._last;
	}
	
	get volume() {
		return this._volume;
	}
	
	get value() {
		return this._value;
	}
	
	get average() {
		return this._average;
	}
	
	get projectedPrice() {
		return this._projectedPrice;
	}
	
	get projectedVolume() {
		return this._projectedVolume;
	}
	
	get projectedType() {
		return this._projectedType;
	}
	
	get change() {
		return this._change;
	}
	
	get changePercent() {
		return this._changePercent;
	}
	
	get lastTradeDate() {
		return this._lastTradeDate;
	}
	
	get prevOI() {
		return this._prevOI;
	}
	
	get contractSize() {
		return this._contractSize;
	}
	
	get tickSize() {
		return this._tickSize;
	}
	
	get decInPrice() {
		return this._decInPrice;
	}
	
	get open0() {
		return this._open0;
	}
	
	get settlementPrice() {
		return this._settlementPrice;
	}
	
	get prevSettlePrice() {
		return this._prevSettlePrice;
	}
	
	get underlying() {
		return this._underlying;
	}
	
	get basis() {
		return this._basis;
	}
	
	get name() {
		return this._name;
	}
	
	get averageLong() {
		return this._averageLong;
	}
	
	get averageShort() {
		return this._averageShort;
	}
	
	get longVolume() {
		return this._longVolume;
	}
	
	get shortVolume() {
		return this._shortVolume;
	}
	
	get mktStCode() {
		return this._mktStCode;
	}
	
	get bid1() {
		return this._bid1;
	}
	
	get bid2() {
		return this._bid2;
	}
	
	get bid3() {
		return this._bid3;
	}
	
	get bid4() {
		return this._bid4;
	}
	
	get bid5() {
		return this._bid5;
	}
	
	get offer1() {
		return this._offer1;
	}
	
	get offer2() {
		return this._offer2;
	}
	
	get offer3() {
		return this._offer3;
	}
	
	get offer4() {
		return this._offer4;
	}
	
	get offer5() {
		return this._offer5;
	}
	
	get bidVol1() {
		return this._bidVol1;
	}
	
	get bidVol2() {
		return this._bidVol2;
	}
	
	get bidVol3() {
		return this._bidVol3;
	}
	
	get bidVol4() {
		return this._bidVol4;
	}
	
	get bidVol5() {
		return this._bidVol5;
	}
	
	get offerVol1() {
		return this._offerVol1;
	}
	
	get offerVol2() {
		return this._offerVol2;
	}
	
	get offerVol3() {
		return this._offerVol3;
	}
	
	get offerVol4() {
		return this._offerVol4;
	}
	
	get offerVol5() {
		return this._offerVol5;
	}
	
	get trades() {
		return this._trades;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	get expDate() {
		return this._expDate;
	}
	
	get multiplier() {
		return this._multiplier;
	}
	
	get securityType() {
		return this._securityType;
	}
}

export class Trade {
	constructor() {
		this._time = new StringMessageBase();
		this._side = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._price = new StringMessageBase();
		this._changeFlag = new StringMessageBase();
	}
	
	get time() {
		return this._time;
	}
	
	get side() {
		return this._side;
	}
	
	get volume() {
		return this._volume;
	}
	
	get price() {
		return this._price;
	}
	
	get changeFlag() {
		return this._changeFlag;
	}
}

export default SS51;
