/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class DR52 extends WsReqMsg {
	constructor() {
		super(0);
		this._traderId = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._side = new StringMessageBase();
		this._positionType = new StringMessageBase();
		this._vol = new StringMessageBase();
		this._price = new StringMessageBase();
		this._accountCode = new StringMessageBase();
		this._cpMember = new StringMessageBase();
		this._cpTrader = new StringMessageBase();
		this._trType = new StringMessageBase();
	}
	
	get traderId() {
		return this._traderId;
	}
	
	set traderId(traderId) {
		this._traderId = new StringMessageBase(traderId);
	}
	
	get symbol() {
		return this._symbol;
	}
	
	set symbol(symbol) {
		this._symbol = new StringMessageBase(symbol);
	}
	
	get side() {
		return this._side;
	}
	
	set side(side) {
		this._side = new StringMessageBase(side);
	}
	
	get positionType() {
		return this._positionType;
	}
	
	set positionType(positionType) {
		this._positionType = new StringMessageBase(positionType);
	}
	
	get vol() {
		return this._vol;
	}
	
	set vol(vol) {
		this._vol = new StringMessageBase(vol);
	}
	
	get price() {
		return this._price;
	}
	
	set price(price) {
		this._price = new StringMessageBase(price);
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get cpMember() {
		return this._cpMember;
	}
	
	set cpMember(cpMember) {
		this._cpMember = new StringMessageBase(cpMember);
	}
	
	get cpTrader() {
		return this._cpTrader;
	}
	
	set cpTrader(cpTrader) {
		this._cpTrader = new StringMessageBase(cpTrader);
	}
	
	get trType() {
		return this._trType;
	}
	
	set trType(trType) {
		this._trType = new StringMessageBase(trType);
	}
}

export default DR52;
