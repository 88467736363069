/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class IR01 extends WsReqMsg {
	constructor() {
		super(1);
		this._indexType = new StringMessageBase();
		this._marketId = new StringMessageBase();
		this._indexId = new StringMessageBase();
		this._tradingDate = new StringMessageBase();
	}
	
	get indexType() {
		return this._indexType;
	}
	
	set indexType(indexType) {
		this._indexType = new StringMessageBase(indexType);
	}
	
	get marketId() {
		return this._marketId;
	}
	
	set marketId(marketId) {
		this._marketId = new StringMessageBase(marketId);
	}
	
	get indexId() {
		return this._indexId;
	}
	
	set indexId(indexId) {
		this._indexId = new StringMessageBase(indexId);
	}
	
	get tradingDate() {
		return this._tradingDate;
	}
	
	set tradingDate(tradingDate) {
		this._tradingDate = new StringMessageBase(tradingDate);
	}
}

export default IR01;
