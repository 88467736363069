/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class SS07 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._volumeGroups = new ArrayMessageBase(VolumeGroup);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get volumeGroups() {
		return this._volumeGroups;
	}
}

export class VolumeGroup {
	constructor() {
		this._price = new StringMessageBase();
		this._buyVolume = new StringMessageBase();
		this._sellVolume = new StringMessageBase();
		this._openVolume = new StringMessageBase();
	}
	
	get price() {
		return this._price;
	}
	
	get buyVolume() {
		return this._buyVolume;
	}
	
	get sellVolume() {
		return this._sellVolume;
	}
	
	get openVolume() {
		return this._openVolume;
	}
}

export default SS07;
