/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class OS06 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._records = new ArrayMessageBase(Record);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get records() {
		return this._records;
	}
}

export class Record {
	constructor() {
		this._time = new StringMessageBase();
		this._action = new StringMessageBase();
		this._traderId = new StringMessageBase();
		this._oldPrice = new StringMessageBase();
		this._oldVol = new StringMessageBase();
		this._oldPub = new StringMessageBase();
		this._oldCust = new StringMessageBase();
		this._oldPc = new StringMessageBase();
		this._oldTr = new StringMessageBase();
		this._oldValidity = new StringMessageBase();
		this._oldValidityData = new StringMessageBase();
		this._cause = new StringMessageBase();
		this._orgWebServerIp = new StringMessageBase();
		this._orgISPAssgIp = new StringMessageBase();
		this._orgClientIp = new StringMessageBase();
		this._oldTrustId = new StringMessageBase();
		this._SETOrderId = new StringMessageBase();
	}
	
	get time() {
		return this._time;
	}
	
	get action() {
		return this._action;
	}
	
	get traderId() {
		return this._traderId;
	}
	
	get oldPrice() {
		return this._oldPrice;
	}
	
	get oldVol() {
		return this._oldVol;
	}
	
	get oldPub() {
		return this._oldPub;
	}
	
	get oldCust() {
		return this._oldCust;
	}
	
	get oldPc() {
		return this._oldPc;
	}
	
	get oldTr() {
		return this._oldTr;
	}
	
	get oldValidity() {
		return this._oldValidity;
	}
	
	get oldValidityData() {
		return this._oldValidityData;
	}
	
	get cause() {
		return this._cause;
	}
	
	get orgWebServerIp() {
		return this._orgWebServerIp;
	}
	
	get orgISPAssgIp() {
		return this._orgISPAssgIp;
	}
	
	get orgClientIp() {
		return this._orgClientIp;
	}
	
	get oldTrustId() {
		return this._oldTrustId;
	}
	
	get SETOrderId() {
		return this._SETOrderId;
	}
}

export default OS06;
