<template>
  <ag-grid-vue
    style="width: 100%; height: 100%; padding: 0px"
    :class="{
      'ag-theme-balham-dark': theme === 'Dark',
      'ag-theme-balham': theme === 'Light',
      'empty-table': rowData.length === 0,
    }"
    :get-row-node-id="getRowNodeId"
    row-model-type="infinite"
    :column-defs="columnDefsPTDealEntry"
    :row-height="21"
    :header-height="32"
    row-selection="multiple"
    :animate-rows="true"
    :context="context"
    :default-col-def="{ resizable: true, floatingFilter: true }"
    :suppress-drag-leave-hides-columns="true"
    :enable-cell-change-flash="true"
    :auto-params-refresh="true"
    :row-deselection="true"
    :row-drag-managed="true"
    overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
    overlay-no-rows-template="<span style='color: #F5F5F5;'>Don't have any Deal.</span>"
    @grid-ready="onGridReady"
    @selection-changed="onSelectionChanged"
    @row-selected="onRowSelected"
  />
</template>

<script>
import { get } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsPTDealEntry } from "@/components/grid/ptdeal/ColumnDefs.js";
import ActionCellRenderer from "@/components/grid/ptdeal/ActionCellRenderer";
import FilterFloatingComponent from "@/components/grid/FilterFloatingComponent";
import SymbolFilterFloatingComponent from "@/components/grid/SymbolFilterFloatingComponent";
import { isCancellablePTDealStatus } from "@/utils/ValidationUtils";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils";
import { getBySymbolAndExchangeId } from "@/utils/SymbolUtils";
import CheckboxPTDealCellRenderer from "@/components/grid/CheckboxPTDealCellRenderer";

export default {
  components: {
    AgGridVue,
    /* eslint-disable */
    // it use for aggrid column define
    ActionCellRenderer,
    /* eslint-disable */
    // it use for aggrid column define
    FilterFloatingComponent,
    /* eslint-disable */
    // it use for aggrid column define
    SymbolFilterFloatingComponent,
    /* eslint-disable */
    // it use for aggrid column define
    CheckboxPTDealCellRenderer,
  },
  data() {
    return {
      columnDefsPTDealEntry,
      // Don't edit this value, The 100 follows by AG-Grid infinity scroll.
      recordCount: 100,
      context: {
        resetFilter: false,
      },
      gridApi: null,
      columnApi: null,
      gridParams: null,
      rowData: [],
      // rowSelected: [],
      stateCounter: 0,
      etsLastRecordId: 0,
      dtsLastRecordId: 0,
      selectedAccount: null,
      etsQueryRefId: null,
      dtsQueryRefId: null,
      tempEtsDealData: [],
      tempDtsDealData: [],
      tempEtsDealDataSize: 0,
      tempDtsDealDataSize: 0,
      firstLoad: true,
    };
  },
  created() {
    this.$EventBus.$on("ctinf/DS01", this.etsDealQueryCtinf);
    this.$EventBus.$on("ctinf/DS51", this.dtsDealQueryCtinf);

    // notify deal
    this.$EventBus.$on("ntf/ON07", this.etsDealInsertNtf);
    this.$EventBus.$on("ntf/ON08", this.etsDealUpdateNtf);
    this.$EventBus.$on("ntf/ON09", this.etsDealCancelNtf);
    this.$EventBus.$on("ntf/ON57", this.dtsDealInsertNtf);
    this.$EventBus.$on("ntf/ON58", this.dtsDealUpdateNtf);
    this.$EventBus.$on("ntf/ON59", this.dtsDealCancelNtf);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/DS01", this.etsDealQueryCtinf);
    this.$EventBus.$off("ctinf/DS51", this.dtsDealQueryCtinf);

    // notify deal
    this.$EventBus.$off("ntf/ON07", this.etsDealInsertNtf);
    this.$EventBus.$off("ntf/ON08", this.etsDealUpdateNtf);
    this.$EventBus.$off("ntf/ON09", this.etsDealCancelNtf);
    this.$EventBus.$off("ntf/ON57", this.dtsDealInsertNtf);
    this.$EventBus.$off("ntf/ON58", this.dtsDealUpdateNtf);
    this.$EventBus.$off("ntf/ON59", this.dtsDealCancelNtf);
  },
  methods: {
    reload() {
      this.rowData = [];
      this.etsLastRecordId = 0;
      this.dtsLastRecordId = 0;
      this.firstLoad = true;
      this.gridApi.deselectAll();
      this.gridApi.purgeInfiniteCache();
      this.$nextTick(() => {
        this.gridApi.setInfiniteRowCount(0, false);
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.gridApi.setFloatingFiltersHeight(0);
      this.gridApi.setFloatingFiltersHeight(0);
      this.gridApi.setDatasource({
        rowCount: null,
        getRows: (params) => {
          console.log("asking for " + params.startRow + " to " + params.endRow);
          this.gridApi.showLoadingOverlay();

          if (this.context.resetFilter) {
            this.rowData = [];
            this.etsLastRecordId = 0;
            this.dtsLastRecordId = 0;
          }

          const isETS = isEnableETS();
          const isDTS = isEnableDTS();

          this.selectedAccount = null;
          this.gridParams = params;

          if (!this.currentAccount || this.currentAccount === "all_accounts") {
            this.stateCounter = 0;
            this.displayState = "all";

            this.tempEtsDealData = [];
            this.tempDtsDealData = [];
            this.tempEtsDealDataSize = 0;
            this.tempDtsDealDataSize = 0;

            let etsRecordCount = this.recordCount / 2;
            let dtsRecordCount = this.recordCount / 2;

            // If ets has no data, let dts query with full record length
            if (this.etsLastRecordId === -1) {
              this.stateCounter++;
              dtsRecordCount = this.recordCount;
            }
            // If dts has no data, let ets query with full record length
            if (this.dtsLastRecordId === -1) {
              this.stateCounter++;
              etsRecordCount = this.recordCount;
            }

            if (this.firstLoad) {
              this.etsLastRecordId = -1;
              this.dtsLastRecordId = -1;

              if (isETS) {
                this.DR01(etsRecordCount);
              } else {
                this.stateCounter++;
              }

              if (isDTS) {
                this.DR51(dtsRecordCount);
              } else {
                this.stateCounter++;
              }
            } else {
              if (isETS) {
                this.DR01(etsRecordCount);
              } else {
                this.etsLastRecordId = -1;
                this.stateCounter++;
              }

              if (isDTS) {
                this.DR51(dtsRecordCount);
              } else {
                this.dtsLastRecordId = -1;
                this.stateCounter++;
              }
            }
          } else if (this.currentAccount === "all_equity_accounts" && isETS) {
            this.dtsLastRecordId = -1;
            this.DR01();
          } else if (this.currentAccount === "all_derivative_accounts" && isDTS) {
            this.etsLastRecordId = -1;
            this.DR51();
          } else {
            const exchangeId = this.currentSelectedAccount.exchangeId;
            this.selectedAccount = this.currentSelectedAccount.accountCode;
            // SET
            if (exchangeId == 1 && isETS) {
              this.dtsLastRecordId = -1;
              this.DR01();
            }
            // TFEX
            else if (exchangeId == 2 && isDTS) {
              this.etsLastRecordId = -1;
              this.DR51();
            }
          }

          this.manageDealColumns();
        },
      });
    },
    getRowNodeId(data) {
      return data.exchangeId + "_" + data.orderId;
    },
    onSelectionChanged(event, row) {
      let selectedDataRows = this.gridApi.getSelectedRows();

      const selectedDataRowsCancellable = this.$_.filter(selectedDataRows, (r) => {
        return isCancellablePTDealStatus(r);
      });

      this.$emit("input", {
        cancellable: selectedDataRowsCancellable,
      });
    },
    onRowSelected(params) {
      const rowsSelected = params.api.getSelectedRows();

      if (rowsSelected.length === 1) {
        if (!this.currentQuoteSelectedSymbol || (this.currentQuoteSelectedSymbol && this.currentQuoteSelectedSymbol.symbol !== params.data.symbol)) {
          if (params.node.selected && params.data) {
            const symbol = {
              symbol: params.data.symbol,
              value: {
                exchangeId: params.data.exchangeId,
                securityId: getBySymbolAndExchangeId(params.data.symbol, params.data.exchangeId).securityId,
                symbol: params.data.symbol,
              },
              fromUID: "outside",
            };
            this.$store.set("global/currentQuoteSelectedSymbol", symbol);
          }
        }
      }
    },
    manageDealColumns() {
      const equityColumns = ["commVat", "confirmNo", "ttf"];
      const derivativeColumns = ["positionType", "clearingDate", "status"];
      if (this.displayState === "all") {
        this.columnApi.setColumnsVisible([...equityColumns, ...derivativeColumns], true);
      } else if (this.displayState === "equity") {
        this.columnApi.setColumnsVisible(equityColumns, true);
        this.columnApi.setColumnsVisible(derivativeColumns, false);
      } else if (this.displayState === "derivative") {
        this.columnApi.setColumnsVisible(derivativeColumns, true);
        this.columnApi.setColumnsVisible(equityColumns, false);
      }
    },
    mergeDeal() {
      let mergeData = [];
      if (this.tempEtsDealData) {
        mergeData = mergeData.concat(this.tempEtsDealData);
      }
      if (this.tempDtsDealData) {
        mergeData = mergeData.concat(this.tempDtsDealData);
      }
      mergeData = this.$_.orderBy(
        mergeData,
        [
          (o) => {
            return this.$moment(o.time, "HH:mm:ss").valueOf();
          },
        ],
        ["desc"]
      );
      this.rowData = this.rowData.concat(mergeData);
      this.tempEtsDealData = [];
      this.tempDtsDealData = [];
      this.setRowData(this.gridParams.startRow, this.gridParams.endRow);
    },
    setRowData(startRow, endRow) {
      // take a slice of the total rows
      const rowsThisPage = this.rowData.slice(startRow, endRow);
      const rowCount = this.rowData.length;

      // if on or after the last page, work out the last row.
      // if (this.rowData.length === endRow) {
      //   lastRow = this.rowData.length + 1;
      // } else if (this.rowData.length < endRow) {
      //   lastRow = this.rowData.length;
      // }

      if (this.etsLastRecordId === -1 && this.dtsLastRecordId === -1) {
        this.gridApi.setInfiniteRowCount(rowCount || rowCount + 1, true);
      } else if (this.rowData.length === 0) {
        this.gridApi.setInfiniteRowCount(rowCount || rowCount + 1, false);
      }

      if (this.rowData.length === 0) {
        this.gridApi.showNoRowsOverlay();
      } else {
        if (this.gridApi) {
          this.gridApi.hideOverlay();
        }
      }
      // call the success callback

      this.gridParams.successCallback(rowsThisPage);
    },
    DR01(newRecordCount) {
      if (this.etsLastRecordId === -1 && !this.firstLoad) {
        return;
      }
      const filter = this.gridApi.getFilterModel();
      let account = null;
      if (this.selectedAccount) {
        account = this.selectedAccount;
      } else {
        if (filter.accountCode) {
          account = filter.accountCode.filter;
        }
      }
      let orderId = null;
      if (filter.orderId) {
        orderId = filter.orderId.filter;
      }
      let securityId = null;
      if (filter.symbol) {
        const symbolObj = JSON.parse(filter.symbol.filter);
        if (symbolObj.set) {
          securityId = symbolObj.set.securityId;
        } else {
          newRecordCount = 0;
        }
      }
      let dealId = null;
      if (filter.dealId) {
        dealId = filter.dealId.filter;
      }
      let tradeId = null;
      if (filter.tradeId) {
        tradeId = filter.tradeId.filter;
      }
      let confirmNo = null;
      if (filter.confirmNo) {
        confirmNo = filter.confirmNo.filter;
      }
      let sendDate = null;
      if (filter.sendDate) {
        sendDate = filter.sendDate.filter;
      }

      const msg = this.$messageFactory.createMessage("DR01");
      if (account) {
        msg.accountCode.set(account);
      }
      if (orderId) {
        msg.orderId.set(orderId);
      }
      if (securityId) {
        msg.securityId.set(securityId);
      }
      if (dealId) {
        msg.dealId.set(dealId);
      }
      if (tradeId) {
        msg.tradeId.set(tradeId);
      }
      if (confirmNo) {
        msg.status.set(confirmNo);
      }
      if (sendDate) {
        msg.sendDate.set(sendDate);
      }
      msg.lastRecordId.set(this.etsLastRecordId);
      if (newRecordCount != null && newRecordCount != undefined) {
        msg.recordCount.set(newRecordCount);
      } else {
        msg.recordCount.set(this.recordCount);
      }
      msg.origin.set("P");

      this.etsQueryRefId = this.$shortid.generate();
      this.$ws.send(msg, this.etsQueryRefId);
    },
    DR51(newRecordCount) {
      if (this.dtsLastRecordId === -1 && !this.firstLoad) {
        return;
      }
      const filter = this.gridApi.getFilterModel();
      let account = null;
      if (this.selectedAccount) {
        account = this.selectedAccount;
      } else {
        if (filter.accountCode) {
          account = filter.accountCode.filter;
        }
      }
      let orderId = null;
      if (filter.orderId) {
        orderId = filter.orderId.filter;
      }
      let securityId = null;
      if (filter.symbol) {
        const symbolObj = JSON.parse(filter.symbol.filter);
        if (symbolObj.set) {
          securityId = symbolObj.set.securityId;
        } else {
          newRecordCount = 0;
        }
      }
      let sendDate = null;
      if (filter.sendDate) {
        sendDate = filter.sendDate.filter;
      }
      let status = null;
      if (filter.status) {
        status = filter.status.filter;
      }
      let side = null;
      if (filter.side) {
        side = filter.side.filter;
      }
      let tfexOrdNo = null;
      if (filter.tfexOrdNo) {
        tfexOrdNo = filter.tfexOrdNo.filter;
      }

      const msg = this.$messageFactory.createMessage("DR51");
      if (account) {
        msg.accountCode.set(account);
      }
      if (orderId) {
        msg.orderId.set(orderId);
      }
      if (securityId) {
        msg.securityId.set(securityId);
      }
      if (sendDate) {
        msg.sendDate.set(sendDate);
      }
      if (status) {
        msg.status.set(status);
      }
      if (side) {
        msg.side.set(side);
      }
      if (tfexOrdNo) {
        msg.tfexOrdNo.set(tfexOrdNo);
      }
      msg.lastRecordId.set(this.dtsLastRecordId);
      if (newRecordCount != null && newRecordCount != undefined) {
        msg.recordCount.set(newRecordCount);
      } else {
        msg.recordCount.set(this.recordCount);
      }
      msg.origin.set("P");

      this.dtsQueryRefId = this.$shortid.generate();
      this.$ws.send(msg, this.dtsQueryRefId);
    },
    etsDealQueryCtinf(data) {
      if (this.etsQueryRefId !== data.refId) {
        return;
      }
      if (data.resultCode === 1001) {
        // set to -1 for does not call to backend if it has a next ticker.
        if (data.records.length === 0) {
          this.etsLastRecordId = -1;
        } else {
          this.etsLastRecordId = data.records[data.records.length - 1].recordId;
        }

        // Clone vuex array to prepare for modify data and push the exchangeId to validate nodeId
        const newData = data.records.map((o, idx) => {
          const newObject = Object.assign({ index: idx }, o);
          // For SET
          newObject.exchangeId = 1;
          return newObject;
        });

        this.tempEtsDealData = this.tempEtsDealData.concat(newData);
        this.tempEtsDealDataSize += newData.length;

        // combine exchange SET and TFEX
        if (!this.currentAccount || this.currentAccount === "all_accounts") {
          this.stateCounter++;
          if (this.stateCounter === 3) {
            if (this.tempEtsDealDataSize < this.recordCount / 2) {
              this.etsLastRecordId = -1;
            } else if (this.tempDtsDealDataSize < this.recordCount / 2) {
              this.dtsLastRecordId = -1;
            }
            return this.mergeDeal();
          } else if (this.stateCounter === 2) {
            if (
              (this.tempEtsDealDataSize === 0 && this.tempDtsDealDataSize === 0) ||
              this.tempEtsDealDataSize + this.tempDtsDealDataSize >= this.recordCount
            ) {
              return this.mergeDeal();
            } else {
              if (this.tempEtsDealDataSize < this.recordCount / 2) {
                this.etsLastRecordId = -1;
                if (this.tempDtsDealDataSize > 0) {
                  this.DR51(this.recordCount / 2 - this.tempEtsDealDataSize);
                  return this.mergeDeal();
                } else {
                  // end of data
                  this.mergeDeal();
                }
                return;
              } else if (this.tempDtsDealDataSize < this.recordCount / 2) {
                this.dtsLastRecordId = -1;
                if (this.tempEtsDealDataSize > 0) {
                  this.DR01(this.recordCount / 2 - this.tempDtsDealDataSize);
                }
                return;
              }
            }
          }
        }

        if (data.records.length < this.recordCount / 2) this.etsLastRecordId = -1;

        // Clone vuex array to prepare for modify data
        this.rowData = this.rowData.concat(newData);
        this.setRowData(this.gridParams.startRow, this.gridParams.endRow);
      } else {
        console.error(data.resultCode + ": " + data.resultMessage);
      }
    },
    dtsDealQueryCtinf(data) {
      if (this.dtsQueryRefId !== data.refId) {
        return;
      }
      if (data.resultCode === 1001) {
        // set to -1 for does not call to backend if it has a next ticker.
        if (data.records.length === 0) {
          this.dtsLastRecordId = -1;
        } else {
          this.dtsLastRecordId = data.records[data.records.length - 1].recordId;
        }

        // Clone vuex array to prepare for modify data and push the exchangeId to validate nodeId
        const newData = data.records.map((o, idx) => {
          const newObject = Object.assign({ index: idx }, o);
          // For SET
          newObject.exchangeId = 2;
          return newObject;
        });

        this.tempDtsDealData = this.tempDtsDealData.concat(newData);
        this.tempDtsDealDataSize += newData.length;

        // combine exchange SET and TFEX
        if (!this.currentAccount || this.currentAccount === "all_accounts") {
          this.stateCounter++;

          if (this.stateCounter === 3) {
            if (this.tempEtsDealDataSize < this.recordCount / 2) {
              this.etsLastRecordId = -1;
            } else if (this.tempDtsDealDataSize < this.recordCount / 2) {
              this.dtsLastRecordId = -1;
            }
            return this.mergeDeal();
          } else if (this.stateCounter === 2) {
            if (
              (this.tempEtsDealDataSize === 0 && this.tempDtsDealDataSize === 0) ||
              this.tempEtsDealDataSize + this.tempDtsDealDataSize >= this.recordCount
            ) {
              return this.mergeDeal();
            } else {
              if (this.tempEtsDealDataSize < this.recordCount / 2) {
                this.etsLastRecordId = -1;
                if (this.tempDtsDealDataSize > 0) {
                  this.DR51(this.recordCount / 2 - this.tempEtsDealDataSize);
                  return this.mergeDeal();
                } else {
                  // end of data
                  this.mergeDeal();
                }
                return;
              } else if (this.tempDtsDealDataSize < this.recordCount / 2) {
                this.dtsLastRecordId = -1;
                if (this.tempEtsDealDataSize > 0) {
                  this.DR01(this.recordCount / 2 - this.tempDtsDealDataSize);
                }
                return;
              }
            }
          }
        }

        if (data.records.length < this.recordCount / 2) this.dtsLastRecordId = -1;

        // Clone vuex array to prepare for modify data
        this.rowData = this.rowData.concat(newData);
        this.setRowData(this.gridParams.startRow, this.gridParams.endRow);
      } else {
        console.error(data.resultCode + ": " + data.resultMessage);
      }
    },
    etsDealInsertNtf(data) {
      const filter = this.gridApi.getFilterModel();
      if (filter.accountCode && filter.accountCode.filter !== data.accountCode) {
        return;
      }
      if (filter.side && filter.side.filter !== data.side) {
        return;
      }
      if (filter.symbol) {
        const json = JSON.parse(filter.symbol.filter);
        if (json.symbol !== data.symbol) {
          return;
        }
      }
      if (filter.status && filter.status.filter !== data.status) {
        return;
      }
      if (filter.confirmNo && filter.confirmNo.filter !== data.confirmNo) {
        return;
      }
      if (filter.tradeId && filter.tradeId.filter !== data.tradeId) {
        return;
      }

      let newData = Object.assign({}, data);
      newData.exchangeId = 1;
      this.rowData = [newData].concat(this.rowData);
      this.gridApi.applyTransaction({ addIndex: 0, add: [newData] });
      const rowNode = this.gridApi.getDisplayedRowAtIndex(0);
      this.gridApi.flashCells({ rowNodes: [rowNode] });
    },
    dtsDealInsertNtf(data) {
      const filter = this.gridApi.getFilterModel();
      if (filter.accountCode && filter.accountCode.filter !== data.accountCode) {
        return;
      }
      if (filter.side && filter.side.filter !== data.side) {
        return;
      }
      if (filter.symbol) {
        const json = JSON.parse(filter.symbol.filter);
        if (json.symbol !== data.symbol) {
          return;
        }
      }
      if (filter.status && filter.status.filter !== data.status) {
        return;
      }
      if (filter.confirmNo && filter.confirmNo.filter !== data.confirmNo) {
        return;
      }
      if (filter.tradeId && filter.tradeId.filter !== data.tradeId) {
        return;
      }

      let newData = Object.assign({}, data);
      newData.exchangeId = 2;
      this.rowData = [newData].concat(this.rowData);
      this.gridApi.applyTransaction({ addIndex: 0, add: [newData] });
      const rowNode = this.gridApi.getDisplayedRowAtIndex(0);
      this.gridApi.flashCells({ rowNodes: [rowNode] });
    },
    etsDealUpdateNtf(data) {
      const rowNode = this.gridApi.getRowNode("1_" + data.orderId);
      if (!rowNode) {
        return;
      }
      data.orderId = "1_" + data.orderId;

      this.rowData[rowNode.rowIndex].status = data.status;
      this.rowData[rowNode.rowIndex].accountCode = data.accountCode;
      this.rowData[rowNode.rowIndex].ttf = data.ttf;
      this.rowData[rowNode.rowIndex].confirmNo = data.confirmNo;
      this.rowData[rowNode.rowIndex].tradeId = data.tradeId;
      rowNode.data.status = data.status;
      rowNode.data.accountCode = data.accountCode;
      rowNode.data.ttf = data.ttf;
      rowNode.data.confirmNo = data.confirmNo;
      rowNode.data.tradeId = data.tradeId;

      this.gridApi.applyTransaction({ update: [rowNode] });
      this.gridApi.flashCells({ rowNodes: [rowNode] });
    },
    dtsDealUpdateNtf(data) {
      const rowNode = this.gridApi.getRowNode("2_" + data.orderId);
      if (!rowNode) {
        return;
      }
      data.orderId = "2_" + data.orderId;

      this.rowData[rowNode.rowIndex].status = data.status;
      this.rowData[rowNode.rowIndex].accountCode = data.accountCode;
      this.rowData[rowNode.rowIndex].ttf = data.ttf;
      this.rowData[rowNode.rowIndex].confirmNo = data.confirmNo;
      this.rowData[rowNode.rowIndex].tradeId = data.tradeId;
      rowNode.data.status = data.status;
      rowNode.data.accountCode = data.accountCode;
      rowNode.data.ttf = data.ttf;
      rowNode.data.confirmNo = data.confirmNo;
      rowNode.data.tradeId = data.tradeId;

      this.gridApi.applyTransaction({ update: [rowNode] });
      this.gridApi.flashCells({ rowNodes: [rowNode] });
    },
    etsDealCancelNtf(data) {
      const rowNode = this.gridApi.getRowNode("1_" + data.orderId);
      if (!rowNode) {
        return;
      }

      data.orderId = "1_" + data.orderId;

      this.rowData[rowNode.rowIndex].status = data.status;
      rowNode.data.status = data.status;

      this.gridApi.applyTransaction({ update: [rowNode] });
      this.gridApi.flashCells({ rowNodes: [rowNode] });
    },
    dtsDealCancelNtf(data) {
      const rowNode = this.gridApi.getRowNode("2_" + data.orderId);
      if (!rowNode) {
        return;
      }

      data.orderId = "2_" + data.orderId;

      this.rowData[rowNode.rowIndex].status = data.status;
      rowNode.data.status = data.status;

      this.gridApi.applyTransaction({ update: [rowNode] });
      this.gridApi.flashCells({ rowNodes: [rowNode] });
    },
  },
  computed: {
    theme: get("global/theme"),
    currentAccount: get("global/currentAccount"),
    nightMode: get("model/systemRefData@nightMode"),
    currentSelectedAccount: get("global/currentSelectedAccount"),
  },
};
</script>

<style scoped lang="scss">
.empty-table {
  &:deep(.ag-center-cols-viewport) {
    .ag-center-cols-container:first-child {
      .ag-row:first-child {
        display: none;
      }
    }
  }

  &:deep(.ag-body-viewport) {
    .ag-pinned-left-cols-container {
      .ag-row:first-child {
        display: none;
      }
    }

    .ag-pinned-right-cols-container {
      .ag-row:first-child {
        display: none;
      }
    }
  }
}
</style>
