/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class ON01 extends WsMsg {
	constructor() {
		super(3);
		this._orderId = new StringMessageBase();
		this._accountCode = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._side = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._price = new StringMessageBase();
		this._matchedVol = new StringMessageBase();
		this._publishedVol = new StringMessageBase();
		this._icebergVol = new StringMessageBase();
		this._condition = new StringMessageBase();
		this._ttf = new StringMessageBase();
		this._status = new StringMessageBase();
		this._time = new StringMessageBase();
		this._quote = new StringMessageBase();
		this._approved = new StringMessageBase();
		this._specialOrderType = new StringMessageBase();
		this._inActivate = new StringMessageBase();
		this._validity = new StringMessageBase();
		this._validityData = new StringMessageBase();
		this._sendDate = new StringMessageBase();
		this._serviceType = new StringMessageBase();
	}
	
	get orderId() {
		return this._orderId;
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get side() {
		return this._side;
	}
	
	get volume() {
		return this._volume;
	}
	
	get price() {
		return this._price;
	}
	
	get matchedVol() {
		return this._matchedVol;
	}
	
	get publishedVol() {
		return this._publishedVol;
	}
	
	get icebergVol() {
		return this._icebergVol;
	}
	
	get condition() {
		return this._condition;
	}
	
	get ttf() {
		return this._ttf;
	}
	
	get status() {
		return this._status;
	}
	
	get time() {
		return this._time;
	}
	
	get quote() {
		return this._quote;
	}
	
	get approved() {
		return this._approved;
	}
	
	get specialOrderType() {
		return this._specialOrderType;
	}
	
	get inActivate() {
		return this._inActivate;
	}
	
	get validity() {
		return this._validity;
	}
	
	get validityData() {
		return this._validityData;
	}
	
	get sendDate() {
		return this._sendDate;
	}
	
	get serviceType() {
		return this._serviceType;
	}
}

export default ON01;
