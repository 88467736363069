/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class OR09 extends WsReqMsg {
	constructor() {
		super(0);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._flag = new StringMessageBase();
		this._traderId = new StringMessageBase();
		this._orderId = new StringMessageBase();
		this._accountCode = new StringMessageBase();
		this._accType = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	set resultCode(resultCode) {
		this._resultCode = new NumberMessageBase(resultCode);
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	set resultMessage(resultMessage) {
		this._resultMessage = new StringMessageBase(resultMessage);
	}
	
	get flag() {
		return this._flag;
	}
	
	set flag(flag) {
		this._flag = new StringMessageBase(flag);
	}
	
	get traderId() {
		return this._traderId;
	}
	
	set traderId(traderId) {
		this._traderId = new StringMessageBase(traderId);
	}
	
	get orderId() {
		return this._orderId;
	}
	
	set orderId(orderId) {
		this._orderId = new StringMessageBase(orderId);
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get accType() {
		return this._accType;
	}
	
	set accType(accType) {
		this._accType = new StringMessageBase(accType);
	}
}

export default OR09;
