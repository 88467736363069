/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class UR04 extends WsReqMsg {
	constructor() {
		super(1);
		this._language = new StringMessageBase();
		this._keyboardOption = new StringMessageBase();
		this._pinSave = new StringMessageBase();
		this._bboBar = new StringMessageBase();
		this._fontSize = new StringMessageBase();
		this._theme = new StringMessageBase();
	}
	
	get language() {
		return this._language;
	}
	
	set language(language) {
		this._language = new StringMessageBase(language);
	}
	
	get keyboardOption() {
		return this._keyboardOption;
	}
	
	set keyboardOption(keyboardOption) {
		this._keyboardOption = new StringMessageBase(keyboardOption);
	}
	
	get pinSave() {
		return this._pinSave;
	}
	
	set pinSave(pinSave) {
		this._pinSave = new StringMessageBase(pinSave);
	}
	
	get bboBar() {
		return this._bboBar;
	}
	
	set bboBar(bboBar) {
		this._bboBar = new StringMessageBase(bboBar);
	}
	
	get fontSize() {
		return this._fontSize;
	}
	
	set fontSize(fontSize) {
		this._fontSize = new StringMessageBase(fontSize);
	}
	
	get theme() {
		return this._theme;
	}
	
	set theme(theme) {
		this._theme = new StringMessageBase(theme);
	}
}

export default UR04;
