/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class FR02 extends WsReqMsg {
	constructor() {
		super(1);
		this._notificationId = new StringMessageBase();
		this._enabled = new StringMessageBase();
	}
	
	get notificationId() {
		return this._notificationId;
	}
	
	set notificationId(notificationId) {
		this._notificationId = new StringMessageBase(notificationId);
	}
	
	get enabled() {
		return this._enabled;
	}
	
	set enabled(enabled) {
		this._enabled = new StringMessageBase(enabled);
	}
}

export default FR02;
