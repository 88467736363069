<script>
import { get } from "vuex-pathify";
import BaseDropdown from "./BaseDropdown";

let dropdownTimeout = null;

export default {
  extends: BaseDropdown,
  props: {
    value: {
      type: Number,
    },
    market: {
      type: String,
      default: "TFEX",
    },
    placeholder: {
      type: String,
      default: "Validity Session",
    },
    filterable: {
      type: String,
      default: "startsWith",
    },
    symbol: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      style: "width: 8rem; min-width: 8rem;",
      items: [
        {
          text: "-",
          value: 1,
        },
        {
          text: "Pre-Open1",
          value: 2,
        },
        {
          text: "Open1",
          value: 3,
        },
        {
          text: "Intermission1",
          value: 4,
        },
        {
          text: "Pre-Open2",
          value: 5,
        },
        {
          text: "Open2",
          value: 6,
        },
        {
          text: "Settlement",
          value: 7,
        },
        {
          text: "Intermission2",
          value: 8,
        },
        {
          text: "Intermission3",
          value: 9,
        },
        {
          text: "Pre-Night",
          value: 10,
        },
        {
          text: "Open0",
          value: 11,
        },
        {
          text: "Closed",
          value: 12,
        },
      ],
    };
  },
  watch: {
    symbol(newValue, oldValue) {
      if (!newValue) {
        this.init();
        return;
      }

      if (newValue?.securityId === oldValue?.securityId) {
        if (newValue?.mktStCode !== oldValue?.mktStCode) {
          return this.init();
        }
      }
    },
    marketStatus(newValue, oldValue) {
      if (this.symbol) return;

      if (newValue !== oldValue) {
        this.init();
      }
    },
    // markets(value) {
    //   if (this.market === "SET") {
    //     console.log("test 1");
    //     this.init().then(() => {
    //       const checker = this.options.find((o) => {
    //         return this.selectedValue === o.value;
    //       });
    //       if (!checker) {
    //         this.reset();
    //       }
    //     });
    //   }
    // },
    // dtsMarkets(value) {
    //   console.log("test 2");
    //   if (this.market === "TFEX") {
    //     this.init().then(() => {
    //       const checker = this.options.find((o) => {
    //         return this.selectedValue === o.value;
    //       });
    //       if (!checker) {
    //         this.reset();
    //       }
    //     });
    //   }
    // },
  },
  created() {
    if (this.market === "SET") {
      this.items = [
        {
          text: "Open1",
          value: "O1",
        },
        {
          text: "Pre-Open2",
          value: "PO2",
        },
        {
          text: "Open2",
          value: "O2",
        },
        {
          text: "Call Market",
          value: "CMK",
        },
        {
          text: "Close",
          value: "CL",
        },
      ];
    }
  },
  methods: {
    async init() {
      const options = await this.generateOptions();
      this.options = Object.assign([], options);
      if (options.length > 0 && this.defaultValue !== options[0].value) {
        this.reset();
      }
    },
    generateOptions() {
      return new Promise((resolve) => {
        if (this.market === "SET") {
          const marketCode = this.symbol?.mktStCode || this.marketStatus;

          let newItems = Object.assign([], this.items);
          if (marketCode == "Start" || marketCode == "Pre-Open1") {
            // show all
          } else if (marketCode == "Open1" || marketCode == "Break") {
            // show Pre-open2
            newItems = newItems.slice(1);
          } else if (marketCode == "Pre-Open2") {
            // show Open2
            newItems = newItems.slice(2);
          } else if (marketCode == "Open2") {
            // show Call market
            // PRECLOSE, OFFHOUR, CLOSE
            newItems = newItems.slice(3);
          } else {
            // show Close
            newItems = newItems.slice(4);
          }
          resolve(newItems);
        } else if (this.market === "TFEX") {
          resolve(this.items);
        } else {
          resolve(this.items);
        }
      });
    },
    reset() {
      if (this.options.length > 0) {
        this.defaultValue = this.options[0].value;
        this.displayValue = this.options[0].text;
        this.$emit("input", this.options[0].value);
        this.$emit("changed", this.options[0].value);
      }
    },
    onButtonClick() {
      if (!this.showMenu) {
        this.showMenu = true;
        this.$refs.input.select();

        setTimeout(() => {
          const rect = this.$refs.container.getBoundingClientRect();
          this.$EventBus.$emit("opendropdown", rect);
        }, 0);

        // this.init().then(() => {
        //   const rect = this.$refs.container.getBoundingClientRect();
        //   this.$EventBus.$emit("dropdown", {
        //     inputId: this._uid,
        //     items: this.options,
        //     placeholder: this.placeholder,
        //     freeText: this.freeText,
        //     keyText: this.keyText,
        //     keyValue: this.keyValue,
        //     rect: rect,
        //     callbackData: this.callbackData,
        //     isSymbol: false,
        //   });
        //   this.$EventBus.$emit("opendropdown", rect);
        //   this.showMenu = true;
        //   this.$refs.input.select();
        // });
      }
    },
    onInputFocus() {
      if (dropdownTimeout) {
        clearTimeout(dropdownTimeout);
      }
      this.init().then(() => {
        const rect = this.$refs.container.getBoundingClientRect();
        this.$EventBus.$emit("dropdown", {
          inputId: this._uid,
          items: this.options,
          placeholder: this.placeholder,
          freeText: this.freeText,
          keyText: this.keyText,
          keyValue: this.keyValue,
          rect: rect,
          callbackData: this.callbackData,
          isSymbol: false,
          forceWidth: false,
          forceUpdateOptions: true,
        });
        // dropdownTimeout = setTimeout(() => {
        //   this.$refs.input.select();
        // }, 10);
      });
    },
  },
  computed: {
    markets: get("model/systemRefData@markets"),
    dtsMarkets: get("model/systemRefData@dtsMarkets"),
    marketStatus: function () {
      const markets = this.$store.get("model/systemRefData@markets");
      return this.$_.find(markets, (m) => m.name === "SET")?.mktStCode || "";
    },
  },
};
</script>
