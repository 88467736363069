/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class AS01 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._accountName = new StringMessageBase();
		this._customerType = new StringMessageBase();
		this._accountType = new StringMessageBase();
		this._buyLimit = new StringMessageBase();
		this._creditLine = new StringMessageBase();
		this._totalBuy = new StringMessageBase();
		this._totalSell = new StringMessageBase();
		this._cashAmount = new StringMessageBase();
		this._totalCommVat = new StringMessageBase();
		this._serviceType = new StringMessageBase();
		this._commissionType = new StringMessageBase();
		this._highLimit = new StringMessageBase();
		this._purchasingPower = new StringMessageBase();
		this._exchangeId = new NumberMessageBase();
		this._ftt = new StringMessageBase();
		this._traderId = new StringMessageBase();
		this._traderName = new StringMessageBase();
		this._notOverCreditFlag = new StringMessageBase();
		this._custFlag = new StringMessageBase();
		this._creditType = new StringMessageBase();
		this._totalExposure = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get accountName() {
		return this._accountName;
	}
	
	get customerType() {
		return this._customerType;
	}
	
	get accountType() {
		return this._accountType;
	}
	
	get buyLimit() {
		return this._buyLimit;
	}
	
	get creditLine() {
		return this._creditLine;
	}
	
	get totalBuy() {
		return this._totalBuy;
	}
	
	get totalSell() {
		return this._totalSell;
	}
	
	get cashAmount() {
		return this._cashAmount;
	}
	
	get totalCommVat() {
		return this._totalCommVat;
	}
	
	get serviceType() {
		return this._serviceType;
	}
	
	get commissionType() {
		return this._commissionType;
	}
	
	get highLimit() {
		return this._highLimit;
	}
	
	get purchasingPower() {
		return this._purchasingPower;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	get ftt() {
		return this._ftt;
	}
	
	get traderId() {
		return this._traderId;
	}
	
	get traderName() {
		return this._traderName;
	}
	
	get notOverCreditFlag() {
		return this._notOverCreditFlag;
	}
	
	get custFlag() {
		return this._custFlag;
	}
	
	get creditType() {
		return this._creditType;
	}
	
	get totalExposure() {
		return this._totalExposure;
	}
}

export default AS01;
