/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class DR53 extends WsReqMsg {
	constructor() {
		super(0);
		this._traderId = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._side = new StringMessageBase();
		this._positionType = new StringMessageBase();
		this._vol = new StringMessageBase();
		this._price = new StringMessageBase();
		this._accountCode = new StringMessageBase();
		this._extOrdNo = new StringMessageBase();
		this._extOrdDate = new StringMessageBase();
	}
	
	get traderId() {
		return this._traderId;
	}
	
	set traderId(traderId) {
		this._traderId = new StringMessageBase(traderId);
	}
	
	get symbol() {
		return this._symbol;
	}
	
	set symbol(symbol) {
		this._symbol = new StringMessageBase(symbol);
	}
	
	get side() {
		return this._side;
	}
	
	set side(side) {
		this._side = new StringMessageBase(side);
	}
	
	get positionType() {
		return this._positionType;
	}
	
	set positionType(positionType) {
		this._positionType = new StringMessageBase(positionType);
	}
	
	get vol() {
		return this._vol;
	}
	
	set vol(vol) {
		this._vol = new StringMessageBase(vol);
	}
	
	get price() {
		return this._price;
	}
	
	set price(price) {
		this._price = new StringMessageBase(price);
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get extOrdNo() {
		return this._extOrdNo;
	}
	
	set extOrdNo(extOrdNo) {
		this._extOrdNo = new StringMessageBase(extOrdNo);
	}
	
	get extOrdDate() {
		return this._extOrdDate;
	}
	
	set extOrdDate(extOrdDate) {
		this._extOrdDate = new StringMessageBase(extOrdDate);
	}
}

export default DR53;
