<template>
  <div
    ref="container"
    :style="baseStyle ? baseStyle : style"
    :data-tooltip="displayErrorText"
    data-position="top center"
    data-inverted="inverted"
    :class="[{ error: displayErrorText, disabled: disabled }, 'ht-dropdown', 'base-dropdown']"
  >
    <div class="ht-wrapper">
      <div ref="inputContainer" class="ht-input" :class="{ disabled: disabled }">
        <div class="input-container" style="flex: 1">
          <input
            ref="input"
            v-model="displayValue"
            type="text"
            spellcheck="false"
            :style="inputStyle"
            :placeholder="placeholder"
            :disabled="disabled"
            :autofocus="autofocus"
            @focus="onInputFocus"
            @input="onInput"
            @click="onInputClick"
            @blur="onInputBlur"
            @keyup="onKeyUp"
            @keydown="onKeyDown"
          />
        </div>
        <button type="button" class="base-dropdown-button" :disabled="disabled" @click="onButtonClick">
          <div class="ht-button">
            <i class="angle icon" :class="{ down: !showMenu, up: showMenu }" :style="buttonStyle"></i>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as md5 from "md5";

let timeoutFocus = null;
let timeoutBlur = null;
export default {
  props: {
    value: String,
    default: {
      type: [Object, String],
    },
    baseStyle: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isValidate: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    keyText: {
      type: String,
      default: "text",
    },
    keyValue: {
      type: String,
      default: "value",
    },
    inputStyle: {
      type: String,
      default: null,
    },
    buttonStyle: {
      type: String,
      default: null,
    },
    inputUpperCase: {
      type: Boolean,
      default: false,
    },
    freeText: {
      type: Boolean,
      default: false,
    },
    scroller: {
      type: Boolean,
      default: false,
    },
    selectableCallback: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showMenu: false,
      style: "",
      defaultValue: null,
      displayValue: null,
      options: [],
      filterOptions: [],
      selectedValue: undefined,
      displayErrorText: null,
      tempSelectedObject: undefined,
      isFreeText: false,
      lastIndex: 0,
      forceWidth: null,
      forceUpdateOptions: false,
      isInputFocus: false,
    };
  },
  watch: {
    default(value) {
      this.defaultValue = this.default;
    },
    options(value) {
      this.filterOptions = value;
    },
    value(value) {
      if (value === null) {
        if (this.defaultValue != null && this.defaultValue != undefined) {
          const found = this.$_.find(this.options, (o) => {
            let newValue = null;
            if (typeof o[this.keyValue] === "object") {
              newValue = this.hash(o[this.keyValue]);
            } else {
              newValue = o[this.keyValue];
            }
            return newValue === this.defaultValue;
          });

          if (found) {
            this.selectedValue = found;
            let newValue = null;
            if (typeof found[this.keyValue] !== "object") {
              this.selectedValue = found[this.keyValue];
            }
            this.tempSelectedObject = found;
            this.displayValue = found[this.keyText];
            this.$emit("input", this.selectedValue);

            this.clearValidate();
          } else {
            this.displayValue = null;
            this.selectedValue = undefined;
            this.tempSelectedObject = undefined;
          }
        } else {
          this.displayValue = null;
          this.selectedValue = undefined;
          this.tempSelectedObject = undefined;
        }
      } else {
        if (value != undefined) {
          const found = this.$_.find(this.options, (o) => {
            let newValue = null;
            if (typeof o[this.keyValue] === "object") {
              newValue = this.hash(o[this.keyValue]);
            } else {
              newValue = o[this.keyValue];
            }
            return newValue === value;
          });
          if (found) {
            this.selectedValue = found;
            let newValue = null;
            if (typeof found[this.keyValue] !== "object") {
              this.selectedValue = found[this.keyValue];
            }
            this.tempSelectedObject = found;
            this.displayValue = found[this.keyText];
            this.$emit("input", this.selectedValue);

            this.clearValidate();
          }
        } else {
          this.displayValue = null;
          this.selectedValue = undefined;
          this.tempSelectedObject = undefined;
        }
      }
    },
  },
  created() {
    if (this.value) {
      this.displayValue = this.value[this.keyText];
    } else {
      this.$emit("input", this.displayValue);
    }

    if (this.default) {
      this.defaultValue = this.default;
    }

    this.filterOptions = this.options;

    this.$EventBus.$on("ondropdownchange", this.onDropdownChanged);
  },
  beforeDestroy() {
    this.$EventBus.$off("ondropdownchange", this.onDropdownChanged);
  },
  methods: {
    hash(obj) {
      return md5(JSON.stringify(obj));
    },
    clearValidate() {
      this.displayErrorText = null;
    },
    validate() {
      this.clearValidate();
      if (this.disabled) {
        return true;
      }

      if (this.required && !this.selectedValue) {
        this.displayErrorText = `${this.placeholder ? this.placeholder : "This field"} is requried.`;
        this.$emit("onErrorText", this.displayErrorText);
        this.displayValue = null;
        return false;
      }

      return true;
    },
    customError(text) {
      this.displayErrorText = text;
      this.$emit("onErrorText", this.displayErrorText);
    },
    onDropdownChanged(id) {
      if (this._uid !== id) {
        this.isDropdownChanged = true;

        this.showMenu = false;
        this.endDropdown();
      } else {
        this.isDropdownChanged = false;
      }
    },
    onInput() {
      if (this.displayValue) {
        if (this.inputUpperCase) {
          this.displayValue = this.displayValue.toUpperCase();
        }
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }

      this.$EventBus.$emit("inputdropdown", this.displayValue, this.filterable);
    },
    onInputFocus() {
      this.isInputFocus = true;

      if (timeoutFocus) {
        clearTimeout(timeoutFocus);
      }
      const rect = this.$refs.container.getBoundingClientRect();
      this.$EventBus.$emit("dropdown", {
        inputId: this._uid,
        items: this.options,
        placeholder: this.placeholder,
        freeText: this.freeText,
        keyText: this.keyText,
        keyValue: this.keyValue,
        rect: rect,
        callbackData: this.callbackData,
        isSymbol: false,
        forceWidth: this.forceWidth,
        forceUpdateOptions: this.forceUpdateOptions,
        scroller: this.scroller,
        selectableCallback: this.selectableCallback,
      });
      timeoutFocus = setTimeout(() => {
        this.$refs.input.select();
      }, 10);
    },
    onInputBlur() {
      this.endDropdown();
      this.validate();
      this.isInputFocus = false;

      if (this.showMenu) {
        if (timeoutBlur) {
          clearTimeout(timeoutBlur);
        }
        // wait for click
        timeoutBlur = setTimeout(() => {
          if (this.isDropdownChanged) {
            return;
          }
          this.$EventBus.$emit("closedropdown");
          this.showMenu = false;
        }, 200);
      }
    },
    endDropdown() {
      if (this.selectedValue != undefined) {
        if (this.freeText && this.isFreeText) {
          this.displayValue = this.tempSelectedObject;
        } else if (this.selectedValue && typeof this.selectedValue === "object") {
          this.displayValue = this.selectedValue[this.keyText];
        } else {
          if (this.tempSelectedObject) {
            this.displayValue = this.tempSelectedObject[this.keyText];
          }
        }
      } else {
        this.displayValue = null;
        this.selectedValue = undefined;
        this.tempSelectedObject = undefined;
        this.filterOptions = this.options;
      }
    },
    selectData(value) {
      if (value === null) {
        this.displayValue = null;
        this.selectedValue = undefined;
        this.tempSelectedObject = undefined;
        this.$emit("input", null);
        this.$emit("enter", this.tempSelectedObject);
        return;
      } else if (value === undefined) {
        this.endDropdown();
        this.$EventBus.$emit("closedropdown");
        this.showMenu = false;
        if (timeoutFocus) {
          clearTimeout(timeoutFocus);
        }
        timeoutFocus = setTimeout(() => {
          this.$refs.input.select();
        }, 10);
        return;
      }
      if (this.showMenu) {
        this.isFreeText = false;
        if (this.freeText) {
          this.isFreeText = true;
          this.selectedValue = this.displayValue;
          this.tempSelectedObject = this.displayValue;
          this.$emit("input", this.selectedValue);
        } else {
          let checker = this.selectedValue;
          if (this.selectedValue && typeof this.selectedValue === "object") {
            checker = this.hash(this.selectedValue[this.keyValue]);
          }
          // select no data
          if (!value) {
            return;
          }
          if (checker !== value) {
            const data = this.$_.find(this.options, (o) => {
              let newValue = null;
              if (typeof o[this.keyValue] === "object") {
                newValue = this.hash(o[this.keyValue]);
              } else {
                newValue = o[this.keyValue];
              }
              return newValue == value;
            });
            // select no data
            if (!data) {
              return;
            }
            this.selectedValue = data;
            if (typeof data[this.keyValue] !== "object") {
              this.selectedValue = data[this.keyValue];
            }
            this.displayValue = data[this.keyText];
            this.tempSelectedObject = data;
            this.$emit("input", this.selectedValue);
            this.$emit("enter", this.tempSelectedObject);
          } else {
            this.displayValue = this.tempSelectedObject[this.keyText];
          }
        }
        this.$EventBus.$emit("closedropdown");
        this.showMenu = false;
      } else {
        if (this.displayValue) {
          if (this.validate()) {
            this.$emit("enter", this.tempSelectedObject);
          }
        } else {
          this.selectedValue = undefined;
          this.tempSelectedObject = undefined;
          this.$emit("input", null);
          this.$nextTick(() => {
            if (this.validate()) {
              this.$emit("enter", this.tempSelectedObject);
            }
          });
        }
      }
    },
    onKeyDown(e) {
      if (e.key === "Enter") {
        e.preventDefault();
      }

      if (this.showMenu) {
        const keyCode = e.keyCode;
        if (keyCode === 38) {
          // Up
          this.$EventBus.$emit("keyupdropdown");
        } else if (keyCode === 40) {
          // Down
          this.$EventBus.$emit("keydowndropdown");
        } else if (keyCode === 9) {
          // TAB
          if (e.shiftKey || e.ctrlKey || e.altKey) {
            return true;
          }
          // this.$EventBus.$emit("selectdropdown", this.selectedValue);
          e.preventDefault();
        }
      }
    },
    onKeyUp(e) {
      const keyCode = e.keyCode;
      if (keyCode === 13) {
        // Enter
        this.$EventBus.$emit("selectdropdown", this.selectedValue);
        this.$refs.input?.blur();
      } else if (keyCode === 27) {
        // Esc
        if (this.tempSelectedObject) {
          this.displayValue = this.tempSelectedObject[this.keyText];
        }

        this.$EventBus.$emit("closedropdown");
        this.showMenu = false;
      }
    },
    onInputClick() {
      // if (!this.showMenu) {
      //   this.onInputFocus();
      // }
    },
    callbackData(data, isClick) {
      if (isClick) {
        this.onItemClick(data);
      } else {
        this.selectData(data);
      }
    },
    onItemClick(data) {
      this.isFreeText = false;
      const tempValue = this.selectedValue;
      this.selectedValue = data;
      let newValue1 = null;
      let newValue2 = null;
      if (typeof data[this.keyValue] === "object") {
        newValue1 = this.hash(data[this.keyValue]);
        newValue2 = tempValue ? this.hash(tempValue[this.keyValue]) : tempValue;
      } else {
        newValue1 = data[this.keyValue];
        newValue2 = tempValue;
        this.selectedValue = newValue1;
      }

      if (newValue1 !== newValue2) {
        this.displayValue = data[this.keyText];
        this.tempSelectedObject = data;
        this.$emit("input", this.selectedValue);
        this.$emit("enter", this.tempSelectedObject);
      }

      this.clearValidate();
    },
    onButtonClick() {
      if (!this.showMenu) {
        const rect = this.$refs.container.getBoundingClientRect();
        this.$EventBus.$emit("dropdown", {
          inputId: this._uid,
          items: this.options,
          placeholder: this.placeholder,
          freeText: this.freeText,
          keyText: this.keyText,
          keyValue: this.keyValue,
          rect: rect,
          callbackData: this.callbackData,
          isSymbol: false,
          forceWidth: this.forceWidth,
          forceUpdateOptions: this.forceUpdateOptions,
          scroller: this.scroller,
        });

        this.$EventBus.$emit("opendropdown", rect, this.forceWidth);
        this.showMenu = true;
        this.$refs.input.select();
      }
    },
    isDisabled() {
      return this.disabled;
    },
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    forceValue(value) {
      const found = this.$_.find(this.options, (o) => {
        let newValue = null;
        if (typeof o[this.keyValue] === "object") {
          newValue = this.hash(o[this.keyValue]);
        } else {
          newValue = o[this.keyValue];
        }
        return newValue === value;
      });
      if (found) {
        this.selectedValue = found;
        let newValue = null;
        if (typeof found[this.keyValue] !== "object") {
          this.selectedValue = found[this.keyValue];
        }
        this.tempSelectedObject = found;
        this.displayValue = found[this.keyText];
        this.$emit("input", this.selectedValue);
      }
    },
  },
};
</script>

<style scoped>
.ht-dropdown {
  position: relative;
  display: inline-flex;
  vertical-align: top;
  border-radius: 0.28571429rem;
  height: 2rem;
  line-height: 2rem;
  background: #2f383f;
}

.ht-dropdown.error {
  border: 1px solid #d91e18 !important;
}

.ht-input {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.ht-button {
  min-width: 2rem;
  width: 2rem;
  text-align: center;
  border-radius: 0.28571429rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
}

.ht-button i,
.ht-button i::before {
  margin: 0 auto;
}

.ht-button i {
  color: #dcddde;
}

.ht-dropdown.disabled .ht-button i {
  color: grey !important;
}

.ht-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
}

.ht-dropdown input {
  flex: 1;
  width: 100%;
  height: auto;
  border: none;
  border-radius: 0.28571429rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 0 0 0 0.75rem !important;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  z-index: 0;
  color: #dcddde !important;
  background: #2f383f !important;
  vertical-align: middle !important;
  margin-top: -0.25rem !important;
}

.ht-dropdown input:focus {
  outline: none;
}

.ht-dropdown.disabled,
.ht-dropdown input:disabled {
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}
.ht-dropdown.disabled .ht-button {
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}

.ht-items {
  position: relative;
  width: 100%;
}

ul {
  position: absolute;
  left: 0;
  top: 2px;
  z-index: 100000;
  min-width: 12rem;
  width: 100%;
  max-height: 200px;
  padding: 0;
  margin: 0;
  background: #262d33;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  overflow-y: auto;
}

li {
  display: block;
  transition-duration: 0.5s;
  padding: 5px 2px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #dcddde;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

li:hover {
  cursor: pointer;
  background: #1b2025;
}

ul li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: 0;
  display: none;
}

ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
  clear: both;
  width: 100%;
}

li.no-item,
li.no-item:hover {
  cursor: default;
  background: inherit;
}

.base-dropdown .base-dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid transparent;
  background-color: transparent;
}

.base-dropdown .input-container {
  display: flex;
  align-items: center;
}
</style>
