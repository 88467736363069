/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class SS04 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._prior = new StringMessageBase();
		this._high = new StringMessageBase();
		this._low = new StringMessageBase();
		this._closePrices = new ArrayMessageBase(ClosePrice);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get prior() {
		return this._prior;
	}
	
	get high() {
		return this._high;
	}
	
	get low() {
		return this._low;
	}
	
	get closePrices() {
		return this._closePrices;
	}
}

export class ClosePrice {
	constructor() {
		this._time = new StringMessageBase();
		this._price = new StringMessageBase();
	}
	
	get time() {
		return this._time;
	}
	
	get price() {
		return this._price;
	}
}

export default SS04;
