/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class OR03 extends WsReqMsg {
	constructor() {
		super(0);
		this._orderId = new StringMessageBase();
		this._accountCode = new StringMessageBase();
		this._pinCode = new StringMessageBase();
		this._sendDate = new StringMessageBase();
	}
	
	get orderId() {
		return this._orderId;
	}
	
	set orderId(orderId) {
		this._orderId = new StringMessageBase(orderId);
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get pinCode() {
		return this._pinCode;
	}
	
	set pinCode(pinCode) {
		this._pinCode = new StringMessageBase(pinCode);
	}
	
	get sendDate() {
		return this._sendDate;
	}
	
	set sendDate(sendDate) {
		this._sendDate = new StringMessageBase(sendDate);
	}
}

export default OR03;
