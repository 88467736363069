/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-11-27 10:04:13
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class VS01 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._advertisementList = new ArrayMessageBase(AdvertisementItem);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get advertisementList() {
		return this._advertisementList;
	}
}

export class AdvertisementItem {
	constructor() {
		this._time = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._side = new StringMessageBase();
		this._volume = new NumberMessageBase();
		this._price = new NumberMessageBase();
		this._status = new StringMessageBase();
		this._member = new StringMessageBase();
		this._contactInfo = new StringMessageBase();
	}
	
	get time() {
		return this._time;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get side() {
		return this._side;
	}
	
	get volume() {
		return this._volume;
	}
	
	get price() {
		return this._price;
	}
	
	get status() {
		return this._status;
	}
	
	get member() {
		return this._member;
	}
	
	get contactInfo() {
		return this._contactInfo;
	}
}

export default VS01;
